import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card } from '@mui/material';

import { PageLayout } from 'components/page-layout';
import { AuthContext } from 'contexts/auth';
import { IShop } from 'utils/auth-service.types';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';
import { ButtonDefault } from 'components/ButtonDefault';

import { ModalShop } from './ModalShop/ModalShop';
import { SettingsForm } from './ModalShop/ModalShop.types';
import { getInitialFormElementsState } from './ModalShop/constants';

import { AddPlus } from '../../../public/images/newIcon/AddPlus';

import styles from './ShopsPage.module.scss';

export const ShopsPage = (): JSX.Element => {
  const navigate = useNavigate();

  const { user, lang, getShops, getUser } = useContext(AuthContext);

  const [shops, setShops] = useState<IShop[] | undefined>([]);
  const [openModalShop, setOpenModalShop] = useState<boolean>(false);
  const [formElements, setFormElements] = useState<SettingsForm>(
    getInitialFormElementsState(lang)
  );

  useEffect(() => {
    getAllShops().then(() => {
      getUser();
    });
  }, []);

  useEffect(() => {
    setFormElements(getInitialFormElementsState(lang));
  }, [lang]);

  const getAllShops = async () => {
    const data = await getShops();
    setShops(data);
  };

  const closeModal = () => {
    setFormElements(getInitialFormElementsState(lang));
    setOpenModalShop(false);
    getAllShops();
  };

  const headingContainer = (
    <Box component="div" className={styles.headingContainer}>
      <Box component="p" className={styles.shopsName}>
        {lang?.lingo.app_shps_title}
      </Box>
      <Box component="div" className={styles.shopsLength}>
        {shops?.length ? shops?.length : 0}
      </Box>
    </Box>
  );

  return user ? (
    <>
      <SeoMetaTitles title={`${lang?.lingo.app_shps_title} | QCUP.ME`} />
      <PageLayout heading={headingContainer}>
        <Box component="div" className={styles.shopsContainer}>
          <Box
            component="div"
            className={shops && shops.length > 0 ? styles.shops : ''}
          >
            {shops && shops.length > 0 ? (
              shops.map(({ id, name, sale_meta }) => (
                <Card
                  key={id}
                  onClick={() => {
                    navigate(`/shops/${id}`);
                  }}
                  className={styles.shopCard}
                >
                  <Box component="div" className={styles.shopContainer}>
                    <Box component="div" className={styles.shopNameContainer}>
                      <Box component="p" className={styles.shopName}>
                        {name}
                      </Box>
                      <Box component="div" className={styles.saleMetaContainer}>
                        <img
                          src="/images/newIcon/promotionShop.svg"
                          alt="promotion_shop"
                        />
                        <Box component="div" className={styles.saleMeta}>
                          <Box component="span" className={styles.saleCount}>
                            {sale_meta ? Object.keys(sale_meta).length : 0}
                          </Box>
                          <Box component="span" className={styles.saleText}>
                            {lang?.lingo.app_shps_shop_of}{' '}
                            {user?.b2b?.limits?.shops}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <img
                      src="/images/newIcon/ArrowRight.svg"
                      alt="arrow_right"
                      className={styles.arrowRight}
                    />
                  </Box>
                </Card>
              ))
            ) : (
              <Box component="div" className={styles.emptyStateBox}>
                <img
                  src="/images/noShops.svg"
                  alt="no_shops"
                  className={styles.emptyStateImage}
                />
                <Box component="div" className={styles.emptyStateTitle}>
                  {lang?.lingo.app_shps_empty_list}
                </Box>
              </Box>
            )}
          </Box>

          <ButtonDefault
            color="green"
            type="button"
            variant="contained"
            disabled={!user.available_actions?.can_add_shop}
            onClick={() => setOpenModalShop(true)}
            className={styles.addShopBtn}
          >
            <Box component="div" className={styles.addShop}>
              <AddPlus
                fill={user.available_actions?.can_add_shop ? '#fff' : '#B8B8B8'}
              />
              <Box component="span">
                {lang?.lingo.app_shps_button_create_shop}
              </Box>
            </Box>
          </ButtonDefault>
          {!user.available_actions?.can_add_shop ? (
            <Box component="div" className={styles.toCreateMoreStores}>
              {lang?.lingo.app_shps_contact_us1}{' '}
              <a href="mailto:contact@qcup.me">{lang?.lingo.app_contact_us2}</a>
            </Box>
          ) : null}
        </Box>

        <ModalShop
          lang={lang}
          formElements={formElements}
          openModalShop={openModalShop}
          closeModal={closeModal}
          setFormElements={setFormElements}
        />
      </PageLayout>
    </>
  ) : (
    <></>
  );
};
