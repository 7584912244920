import dayjs from 'dayjs';
import 'dayjs/locale/en';

import { ILanguageProfile } from 'contexts/auth/auth.types';

export const changeTextStatus = (
  status: string,
  lang: ILanguageProfile | null
): string => {
  if (status === 'in_review') {
    return `${lang?.lingo.app_cmpgn_review}`;
  }
  if (status === 'accepted' || status === 'added') {
    return `${lang?.lingo.app_cmpgn_accepted}`;
  }
  if (status === 'clocked') {
    return `${lang?.lingo.app_cmpgn_clocked}`;
  }

  return `${lang?.lingo.app_cmpgn_done}`;
};

export const choseColorByStatus = (status: string): string => {
  if (status === 'in_review') {
    return 'rewiew';
  }
  if (status === 'accepted' || status === 'added') {
    return 'accepted';
  }
  if (status === 'clocked') {
    return 'clocked';
  }
  return 'done';
};

export const formatDate = (
  dateString: string,
  lang: ILanguageProfile | null
): string => {
  const serverDate = dayjs(dateString);
  const currentTime = dayjs();
  const timezoneOffset = currentTime.utcOffset() / 60;

  const localServerDate = serverDate.add(timezoneOffset, 'hour');
  const formatedDate = localServerDate.format('DD.MM.YYYY');
  const formatedTime = localServerDate.format('HH:mm');

  return `${formatedDate} ${lang?.lingo.app_cmpgn_at} ${formatedTime}`;
};
