import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Modal } from '@mui/material';

import { ButtonDefault } from 'components/ButtonDefault';

import { IModalDeleteEmployeeProps } from './ModalDeleteEmployee.types';

import styles from './ModalDeleteEmployee.module.scss';

export const ModalDeleteEmployee = ({
  shop,
  lang,
  idEmployee,
  handleDeleteEmployee,
  setIdEmployee
}: IModalDeleteEmployeeProps): JSX.Element => {
  const closeModal = () => {
    setIdEmployee(null);
  };

  const navigate = useNavigate();

  return shop && idEmployee ? (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="div" className={styles.modalContainer}>
        <Box component="div" className={styles.title}>
          {lang?.lingo.app_users_modal_dlt_title}
        </Box>

        <Box component="div" className={styles.textContainer}>
          <Box component="div" className={styles.text}>
            {lang?.lingo.app_users_modal_dlt_user1} «{idEmployee.name}»{' '}
            {lang?.lingo.app_users_modal_dlt_from_shop} «{shop.name}»?
          </Box>
          <Box component="div" className={styles.text}>
            {idEmployee.name} {lang?.lingo.app_users_modal_dlt_user2}
          </Box>
        </Box>
        <Box component="div" className={styles.buttonContainer}>
          <ButtonDefault
            color="green"
            type="button"
            variant="contained"
            onClick={() => {
              closeModal();
            }}
          >
            {lang?.lingo.app_prmo_delete_not_agree}
          </ButtonDefault>
          <ButtonDefault
            color="red"
            type="button"
            variant="contained"
            onClick={() => {
              handleDeleteEmployee(idEmployee.id as string).then(() => {
                navigate(`/shops/${shop.id}`);
                setIdEmployee(null);
              });
            }}
          >
            {lang?.lingo.app_prmo_delete_agree}
          </ButtonDefault>
        </Box>
      </Box>
    </Modal>
  ) : (
    <></>
  );
};
