import React, { useContext, useEffect, useState } from 'react';
import { Box, Card } from '@mui/material';

import { AuthContext } from 'contexts/auth';
import { ButtonDefault } from 'components/ButtonDefault';
import { IEmployees } from 'utils/auth-service.types';

import { IEmployeesInfoProps } from './EmployeesInfo.types';

import { ModalDeleteEmployee } from './ModalDeleteEmployees';
import { ModalEmployee } from './ModalEmployee/ModalEmployee';
import { SettingsEmployeeForm } from './ModalEmployee/ModalEmployee.types';
import { getInitialFormElementsState } from './ModalEmployee/constants';

import { AddPlus } from '../../../../../public/images/newIcon/AddPlus';
import { RemoveMinus } from '../../../../../public/images/newIcon/RemoveMinus';

import styles from './EmployeesInfo.module.scss';

export const EmployeesInfo = ({
  shop,
  lang,
  employees,
  getInfoEmployees
}: IEmployeesInfoProps) => {
  const { user, deleteEmployee } = useContext(AuthContext);

  const [openModalEmployees, setOpenModalEmployees] = useState<boolean>(false);
  const [idEmployee, setIdEmployee] = useState<IEmployees | null>(null);
  const [formElements, setFormElements] = useState<SettingsEmployeeForm>(
    getInitialFormElementsState(lang)
  );

  useEffect(() => {
    setFormElements(getInitialFormElementsState(lang));
  }, [lang, idEmployee]);

  const closeModal = () => {
    setFormElements(getInitialFormElementsState(lang));
    setOpenModalEmployees(false);
    getInfoEmployees();
  };

  const handleDeleteEmployee = async (user_id: string) => {
    await deleteEmployee(user_id as string).then(() => {
      getInfoEmployees();
    });
  };

  const disableButton =
    employees &&
    user &&
    Object.keys(employees).length === user?.b2b?.limits?.users;

  const hasMatchingShopId =
    shop &&
    employees &&
    employees.some(employee => employee.shop_id === shop?.id);

  return shop && employees ? (
    <>
      <Box component="div" className={styles.employeesContainer}>
        <Box
          component="div"
          className={
            employees && Object.keys(employees).length ? styles.employees : ''
          }
        >
          {hasMatchingShopId ? (
            Object.entries(employees).map(([key, value]) =>
              shop && shop.id === value.shop_id ? (
                <Card className={styles.employeeCard} key={key}>
                  <Box component="div" className={styles.employeeContainer}>
                    <Box
                      component="div"
                      className={styles.employeeNameContainer}
                    >
                      <Box component="p" className={styles.employeeName}>
                        {value.name}
                      </Box>
                      <Box component="span" className={styles.employeeCount}>
                        {value.email}
                      </Box>
                    </Box>
                    <Box
                      component="div"
                      className={styles.removeEmployee}
                      onClick={() => setIdEmployee(value)}
                    >
                      <RemoveMinus fill="#e50045" />
                    </Box>
                  </Box>
                </Card>
              ) : (
                <></>
              )
            )
          ) : (
            <Box component="div" className={styles.emptyStateBox}>
              <img
                src="/images/noUsers.svg"
                alt="no_users"
                className={styles.emptyStateImage}
              />
              <Box component="div" className={styles.emptyStateTitle}>
                {lang?.lingo.app_users_empty_list}
              </Box>
            </Box>
          )}
        </Box>
        <Box component="div" className={styles.addEmployeeContainer}>
          <ButtonDefault
            color="green"
            type="button"
            variant="contained"
            disabled={disableButton}
            onClick={() => setOpenModalEmployees(true)}
            className={styles.addEmployeeBtn}
          >
            <Box component="div" className={styles.addEmployee}>
              <AddPlus fill={disableButton ? '#B8B8B8' : '#fff'} />
              <Box component="span">{lang?.lingo.app_users_add_user}</Box>
            </Box>
          </ButtonDefault>
        </Box>

        {disableButton ? (
          <Box component="div" className={styles.toCreateMoreEmployees}>
            {lang?.lingo.app_users_contact_us1}
            <>
              {' '}
              <a href="mailto:contact@qcup.me">
                {lang?.lingo.app_prmo_contact_us2}
              </a>
            </>
          </Box>
        ) : null}

        {openModalEmployees ? (
          <ModalEmployee
            shop={shop}
            lang={lang}
            formElements={formElements}
            closeModal={closeModal}
            setFormElements={setFormElements}
          />
        ) : null}

        {idEmployee ? (
          <ModalDeleteEmployee
            shop={shop}
            lang={lang}
            handleDeleteEmployee={handleDeleteEmployee}
            idEmployee={idEmployee}
            setIdEmployee={setIdEmployee}
          />
        ) : null}
      </Box>
    </>
  ) : (
    <></>
  );
};
