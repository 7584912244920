import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Card } from '@mui/material';

import { PageLayout } from 'components/page-layout';
import { AuthContext } from 'contexts/auth';
import { ITransaction } from 'utils/auth-service.types';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';

import { TransactionStatusEnum } from './Transactions.types';
import { formatTime } from './Transactions.utils';
import { getEmptyStateTransactions, emptyStateImg } from './contants';

import { ArrowButton } from '../../../public/images/newIcon/ArrowButton';

import styles from './Transactions.module.scss';

export const Transactions = (): JSX.Element => {
  const [status, setStatus] = useState<number>(1);
  const [transactions, setTransactions] = useState<ITransaction | null>(null);
  const [page, setPage] = useState<number>(1);
  // const [selectedTransaction, setSelectedTransaction] =
  //   useState<ICreateTransaction | null>(null);

  const { user, lang, fetchTransactions } = useContext(AuthContext);

  useEffect(() => {
    getTransactions();
  }, [status, page]);

  const getTransactions = async () => {
    const transactions = await fetchTransactions({
      status: TransactionStatusEnum[status],
      limit: 10,
      page
    });
    setTransactions(transactions);
  };

  // const closeModal = () => {
  //   setSelectedTransaction(null);
  //   getTransactions();
  // };

  const disabledPrevButton = page === 1;
  const disabledNextButton = page === transactions?.pagination?.pages;

  function getRate(rate: string) {
    return '⭐ '.repeat(parseInt(rate));
  }

  return user ? (
    <>
      <SeoMetaTitles title={`${lang?.lingo.app_trns_title} | QCUP.ME`} />
      <PageLayout heading={lang?.lingo.app_trns_title}>
        {/* <BottomNavigationGroup
          status={status}
          setStatus={setStatus}
          setPage={setPage}
          lang={lang}
        /> */}

        <Box component="div" className={styles.mainTransactionContainer}>
          {transactions?.transactions.length ? (
            transactions.transactions.map(transaction => {
              let backgroundColorType = transaction.sale_info
                ? '#F0E7FF'
                : '#F7F9F9';
              return (
                <React.Fragment key={transaction.id}>
                  <Card
                    sx={{
                      width: '100%',
                      padding: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: backgroundColorType
                    }}
                  >
                    <Box
                      component="div"
                      className={styles.transactionContainer}
                    >
                      <Box component="div" className={styles.nameContainer}>
                        <Box component="div" className={styles.name}>
                          {transaction.b2c_name}
                        </Box>

                        <Box component="div" className={styles.time}>
                          {formatTime(transaction.created)}
                        </Box>
                      </Box>

                      <Box component="div" className={styles.acceptedContainer}>
                        <Box component="div" className={styles.acceptedText}>
                          {transaction.sale_info
                            ? `${lang?.lingo.app_trns_deducted_points} ${transaction.sale_info.sale_limit} ${lang?.lingo.app_prmo_points_sale1} ${lang?.lingo.app_trns_for_promo} «${transaction.sale_info.discount_message}»`
                            : null}
                          {
                          transaction.sale_info === null
                            ? `${lang?.lingo.app_trns_added_points} ${transaction.points} ${lang?.lingo.app_prmo_points_sale1} (${transaction?.user_name})`
                            : null}
                        </Box>
                        <Box component="div" className={styles.feedback}>
                          {transaction.feedback ? getRate(transaction.feedback.split("_")[1]) : null}
                        </Box>
                        {transaction.comment ? <Box component="div" className={styles.comment}>
                          {transaction.comment}
                        </Box> : null}

                      </Box>
                    </Box>
                  </Card>
                </React.Fragment>
              );
            })
          ) : (
            <Box component="div" className={styles.emptyStateBox}>
              <img
                src={emptyStateImg[1]}
                alt="transactions_list"
                className={styles.emptyStateImage}
              />
              <Box component="p" className={styles.emptyStateText}>
                {lang && getEmptyStateTransactions(lang)[1]}
              </Box>
            </Box>
          )}
          {transactions?.pagination && transactions?.pagination?.pages > 1 ? (
            <Box
              component="div"
              className={styles.containerButton}
              // sx={{ height: { xs: '130px', md: '50px' } }}
            >
              <Button
                disabled={disabledPrevButton}
                onClick={() => setPage(prev => prev - 1)}
                className={styles.arrowButtonLeft}
              >
                <ArrowButton
                  width="10px"
                  height="16px"
                  fill={disabledPrevButton ? '#b8b8b8' : '#022024'}
                />
              </Button>
              <Button
                disabled={disabledNextButton}
                onClick={() => setPage(prev => prev + 1)}
              >
                <ArrowButton
                  width="10px"
                  height="16px"
                  fill={disabledNextButton ? '#b8b8b8' : '#022024'}
                />
              </Button>
            </Box>
          ) : (
            <Box component="div" sx={{ height: { xs: '90px', md: '0px' } }} />
          )}
        </Box>
        {/* <ModalTransaction
          lang={lang}
          selectedTransaction={selectedTransaction}
          closeModal={closeModal}
        /> */}
      </PageLayout>
    </>
  ) : (
    <></>
  );
};
