import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  Container,
  BottomNavigation,
  BottomNavigationAction
} from '@mui/material';

import { AuthContext } from 'contexts/auth';

import { getNavigationUrls } from './constants';

import styles from './HeaderAuthorization.module.scss';

interface headerProps {
  mainContent?: JSX.Element;
}

export const HeaderAuthorization = ({
  mainContent
}: headerProps): JSX.Element => {
  const [value, setValue] = useState<number>(0);
  const location = useLocation();

  const { lang, user } = useContext(AuthContext);

  const navigationUrls = useMemo(() => {
    return user && getNavigationUrls(user?.paid, lang, user?.trial.days_left);
  }, [user?.paid, lang, user?.trial.days_left]);

  useEffect(() => {
    if (user) {
      const currentPathName = location.pathname.split('/')[1];

      const currentNavigationItemId = navigationUrls?.find(
        item => item.url === currentPathName
      )?.id;

      if (currentNavigationItemId) {
        const currentNavigationItemIndex =
          navigationUrls?.find(item => item.id === currentNavigationItemId)
            ?.id || 0;
        setValue(currentNavigationItemIndex);
      }
    }
  }, [location.pathname, user]);

  return (
    <>
      <AppBar position="static" className={styles.appBar}>
        <Container maxWidth="lg">
          <Toolbar
            className={styles.logoContainer}
            sx={{ marginBottom: { xs: 'none', md: '20px' } }}
          >
            <Link to="/" className={styles.logo}>
              <img
                src="/images/newLogo.svg"
                alt={'QCup Logo'}
                className={styles.logoImage}
              />
              <Box component="p" className={styles.logoText}>
                QCUP
              </Box>
            </Link>

            <BottomNavigation
              showLabels
              value={value}
              onChange={(_, newValue) => {
                setValue(newValue);
              }}
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex'
                }
              }}
              className={styles.headerBottomNavigation}
            >
              {navigationUrls?.map(({ url, text }) => (
                <BottomNavigationAction
                  key={url}
                  component={Link}
                  to={url}
                  label={text}
                  className={styles.bottomNavigationAction}
                />
              ))}
            </BottomNavigation>
          </Toolbar>
        </Container>
      </AppBar>
      {mainContent}
    </>
  );
};
