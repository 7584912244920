import { IShop } from 'utils/auth-service.types';
import { ILanguageProfile } from 'contexts/auth/auth.types';

import { SettingsShopEditForm } from './ModalEditShop.types';
import { getInitialFormEditElementsState } from './constants';

type TEditFormPayloadKeys = 'name' | 'address_line_1' | 'post_code';

export const mapEditFormShop = (
  shop: IShop,
  lang: ILanguageProfile | null
): SettingsShopEditForm => {
  const payload: SettingsShopEditForm = getInitialFormEditElementsState(lang);
  Object.entries(getInitialFormEditElementsState(lang)).forEach(([key, v]) => {
    payload[key as TEditFormPayloadKeys] = {
      ...v,
      value:
        key === 'name' ? shop?.name || '' : shop[key as TEditFormPayloadKeys]
    };
  });
  return payload;
};
