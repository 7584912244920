import { ILanguageProfile } from 'contexts/auth/auth.types';

export const getInitialFormEditElementsState = (
  lang: ILanguageProfile | null
) => {
  return {
    name: {
      label: `${lang?.lingo.app_shps_shop_title_field}`,
      helper: `${lang?.lingo.app_shps_shop_helper_field}`,
      value: ''
    },
    address_line_1: {
      label: `${lang?.lingo.app_shps_shop_address_field}`,
      helper: 'Please enter address №1 of your shop',
      value: ''
    },

    post_code: {
      label: `${lang?.lingo.app_shps_shop_postcode_field}`,
      helper: 'Please enter postcode of your shop',
      value: ''
    }
  };
};

export const initialFormEditErrorsState = {
  name: '',
  address_line_1: '',
  post_code: ''
};
