import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Card, Grid, Typography } from '@mui/material';

import { PageLayout } from 'components/page-layout';
import { AuthContext } from 'contexts/auth';
import { IAnalytics, ITransaction } from 'utils/auth-service.types';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';
import { Charts } from '../../components/Charts/Charts';
import { Transactions } from '../transactions';
import styles from './Analytics.module.scss';

function DahboardCard(props: {
  name: string;
  number: string;
  cardIcon: Element;
}) {
  return (
    <Grid item md={3} xs={12} lg={3}>
      <Card className={styles.card}>
        <Box display={'flex'}>
          <Box sx={{ p: 2 }}>{props?.cardIcon}</Box>
          <Box>
            <Typography className={styles.cardNumber}>
              {props?.number}
            </Typography>
            <Typography className={styles.cardTitle}>{props?.name}</Typography>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
}

export const Analytics = (): JSX.Element => {
  const [status, setStatus] = useState<number>(1);
  const [analytics, setAnalytics] = useState<IAnalytics | null>(null);
  const [page, setPage] = useState<number>(1);
  // const [selectedTransaction, setSelectedTransaction] =
  //   useState<ICreateTransaction | null>(null);

  const { user, lang, fetchAnalytics } = useContext(AuthContext);

  useEffect(() => {
    getAnalytics();
  }, [status, page]);

  const getAnalytics = async () => {
    const analytics = await fetchAnalytics({});
    console.log(analytics);
    setAnalytics(analytics);
  };

  // @ts-ignore
  return user ? (
    <>
      <SeoMetaTitles title={`${lang?.lingo.app_analytics} | QCUP.ME`} />
      <PageLayout heading={lang?.lingo.app_analytics}>
        <Grid container spacing={2}>
          <DahboardCard
            name={'Транзакции'}
            number={analytics?.total_transactions}
            cardIcon={<img src="/images/qr-code.svg" alt="transactions" />}
          />

          <DahboardCard
            name={'Подписчиков'}
            number={analytics?.users}
            cardIcon={<img src="/images/user-group.svg" alt="subscribers" />}
          />

          <DahboardCard
            name={'Начислено баллов'}
            number={analytics?.points_added}
            cardIcon={<img src="/images/increase.svg" alt="gifted" />}
          />

          <DahboardCard
            name={'Списано баллов'}
            number={analytics?.returned_points}
            cardIcon={<img src="/images/decrease.svg" alt="returned" />}
          />
        </Grid>

        <Box className={styles.title}>За последние 30 дней</Box>
        <Charts data={analytics?.analytics} />
      </PageLayout>
    </>
  ) : (
    <></>
  );
};
