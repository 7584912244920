import React, { useContext, useEffect, useState } from 'react';

import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link, Stack, useMediaQuery, Box } from '@mui/material';

import { FormElement } from 'common-types';
import { AuthContext } from 'contexts/auth';
import { AlertContext } from 'contexts/alert';
import { PageLayout } from 'components/page-layout';
import { InputPassword } from 'components/input-password';
import { InputDefault } from 'components/input-default';
import { Loader } from 'components/loader';
import { ButtonDefault } from 'components/ButtonDefault';
import { ButtonGoogle } from 'components/ButtonGoogle';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';

import { initialFormElementsState, initialFormErrorsState } from './constants';
import { LoginForm } from './login.types';

import styles from './login.module.scss';
import { useQuery } from 'hooks/useQuery';

export const Login = (): JSX.Element => {
  const [formElements, setFormElements] = useState<LoginForm<FormElement>>(
    initialFormElementsState
  );
  const [errors, setErrors] = useState<LoginForm<string>>(
    initialFormErrorsState
  );

  const { isLoading, onLogin } = useContext(AuthContext);
  const { setType, setMessage } = useContext(AlertContext);

  const query = useQuery();
  const feedback = query.get('feedback');

  useEffect(() => {
    if (feedback === 'error') {
      setType('error');
      setMessage('Oops! An error has occurred.');
    }
  }, [feedback]);

  const isXs = useMediaQuery('(max-width:600px)');

  const handleChange =
    (prop: keyof LoginForm<FormElement>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrors({ ...errors, [prop]: '' });
      setFormElements({
        ...formElements,
        [prop]: { ...formElements[prop], value: event.target.value }
      });
    };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let err = initialFormErrorsState;

    Object.values(formElements).map((input, index) => {
      if (!input.value) {
        err = {
          ...err,
          [Object.keys(formElements)[index]]: `${input.label} is required!`
        };
      }
    });

    const hasErrors = !Object.values(err).every(x => x === '');

    if (hasErrors) {
      setErrors({
        ...errors,
        ...err
      });
    } else {
      onLogin({
        username: formElements.email.value,
        password: formElements.password.value
      });
    }
  };

  return (
    <>
      <SeoMetaTitles title="Log In | QCUP.ME" />
      <PageLayout heading="Login" login>
        {isLoading && <Loader isOpened={isLoading} />}
        <Stack
          spacing={3}
          component="form"
          autoComplete="off"
          marginTop="16px"
          alignItems="end"
          width={isXs ? '100%' : '500px'}
          onSubmit={onSubmit}
          className={styles.formLogin}
        >
          <InputDefault
            label={formElements.email.label}
            type="email"
            helperText={errors.email ? errors.email : ''}
            fullWidth
            error={!!errors.email}
            onChange={handleChange('email')}
          />
          <InputPassword
            label={formElements.password.label}
            helperText={errors.password ? errors.password : ''}
            fullWidth
            error={!!errors.password}
            onChange={handleChange('password')}
          />

          <Link component={RouterLink} to="/change-password" className={styles.linkAuth}>
            Forgot password?
          </Link>

          <Box component="div" className={styles.buttonContainer}>
            <ButtonDefault variant="contained" size="large" type="submit">
              Sign in
            </ButtonDefault>
            <Box component="div" className={styles.lineContainer}>
              <Box component="div" className={styles.lineBox}></Box>
              <Box component="p" className={styles.lineText}>
                or
              </Box>
              <Box component="div" className={styles.lineBox}></Box>
            </Box>
            <ButtonGoogle link="/api/oauth/signin">
              Sign in with Google
            </ButtonGoogle>
          </Box>
        </Stack>
        <Box component="div" className={styles.linkCreateAccountBox}>
          Don't have an account yet?{' '}
          <Link
            component={RouterLink}
            to="/signup"
            className={styles.linkCreateAccount}
          >
            Create account
          </Link>
        </Box>
      </PageLayout>
    </>
  );
};
