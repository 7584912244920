import { ILanguageProfile } from 'contexts/auth/auth.types';

export const getNavigationUrls = (
  paid: boolean | null,
  lang: ILanguageProfile | null,
  daysLeft: number | null
) => {
  // const index = paid ? 1 : 0; - при наличии рассылок (при оплате платного тарифа)

  const navButtons = [
    {
      id: 0,
      url: '',
      text: lang ? `${lang?.lingo.app_scan}` : 'Scan'
    },
    {
      id: 1,
      url: 'transactions',
      text:'Транзакции'
    },
    {
      id: 2,
      url: 'analytics',
      text: lang ? `${lang?.lingo.app_analytics}` : 'Статистика'
    },
    {
      id: 3,
      url: 'campaigns',
      text: lang ? `${lang?.lingo.app_cmpgn_title}` : 'Campaigns'
    },
    {
      id: 4,
      url: 'profile',
      text: lang ? `${lang?.lingo.app_prfl_title}` : 'Profile'
    }
  ];

  return navButtons;
};
