import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Paper
} from '@mui/material';

import { AuthContext } from 'contexts/auth';

import { getNavigationUrls } from './constants';

import styles from './footer.module.scss';

export const Footer = (): JSX.Element | null => {
  const [value, setValue] = useState<number>(0);
  const location = useLocation();

  const { lang, user } = useContext(AuthContext);

  const navigationUrls = useMemo(() => {
    return (
      user && getNavigationUrls(user?.paid, lang, value, user?.trial.days_left)
    );
  }, [user?.paid, lang, value, user?.trial.days_left]);

  useEffect(() => {
    if (user) {
      const currentPathName = location.pathname.split('/')[1];

      const currentNavigationItemId = navigationUrls?.find(
        item => item.url === currentPathName
      )?.id;

      if (currentNavigationItemId) {
        const currentNavigationItemIndex =
          navigationUrls?.find(item => item.id === currentNavigationItemId)
            ?.id || 0;
        setValue(currentNavigationItemIndex);
      }
    }
  }, [location.pathname, user]);

  return user ? (
    <Box
      sx={{
        paddingBottom: '78px',
        display: {
          xs: 'flex',
          md: 'none'
        }
      }}
    >
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
        elevation={3}
        className={styles.footer}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(_, newValue) => {
            setValue(newValue);
          }}
          sx={{ justifyContent: 'space-evenly' }}
          className={styles.footerBottomNavigation}
        >
          {navigationUrls?.map(({ url, icon, text }) => (
            <BottomNavigationAction
              key={url}
              icon={icon}
              component={Link}
              to={url}
              label={text}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </Box>
  ) : null;
};
