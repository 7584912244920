import React, { FormEvent, useState, useContext, useEffect } from 'react';
import { Box, Modal, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import { AuthContext } from 'contexts/auth';

import {
  IModalEditShopProps,
  SettingsShopEditForm,
  SettingsShopEditFormErrors
} from './ModalEditShop.types';
import { initialFormEditErrorsState } from './constants';
import { mapEditFormShop } from './utils';

import { InputDefault } from 'components/input-default';
import { ButtonDefault } from 'components/ButtonDefault';

import styles from './ModalEditShop.module.scss';

export const ModalEditShop = ({
  shop,
  lang,
  openModalEdit,
  formEditElements,
  closeModalEdit,
  setFormEditElements
}: IModalEditShopProps): JSX.Element => {
  const { editShop } = useContext(AuthContext);

  const [errors, setErrors] = useState<SettingsShopEditFormErrors>(
    initialFormEditErrorsState
  );

  let { shop_id } = useParams();

  useEffect(() => {
    if (shop) {
      setFormEditElements(mapEditFormShop(shop, lang));
    }
  }, [shop, lang]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [event.target.name]: '' });
    setFormEditElements({
      ...formEditElements,
      [event.target.name]: {
        ...formEditElements[event.target.name as keyof SettingsShopEditForm],
        value: event.target.value
      }
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    let err = { ...initialFormEditErrorsState };

    Object.entries(formEditElements).map(([fieldKey, fieldValue]) => {
      if (!fieldValue.value) {
        err = {
          ...err,
          [fieldKey]: `${fieldValue.label} ${lang?.lingo.app_required_field}`
        };
      }
    });

    const hasErrors = !Object.values(err).every(error => !error);

    if (hasErrors) {
      setErrors({
        ...errors,
        ...err
      });
    } else {
      editShop(shop_id as string, {
        name: formEditElements.name.value as string,
        address_line_1: formEditElements.address_line_1.value as string,
        address_line_2: null,
        post_code: formEditElements.post_code.value as string
      }).then(() => {
        closeModalEdit();
        setErrors(initialFormEditErrorsState);
      });
    }
  };
  return (
    <Modal
      open={!!openModalEdit}
      onClose={closeModalEdit}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="div" className={styles.modalContainer}>
        <Box component="div" className={styles.createShopTitle}>
          {lang?.lingo.app_shps_edit_shop_title}
        </Box>
        <Stack
          spacing={3}
          component="form"
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <InputDefault
            label={formEditElements.name.label}
            type="text"
            helperText={
              errors.name ? errors.name : formEditElements.name.helper
            }
            error={!!errors.name}
            onChange={handleChange}
            name="name"
            value={formEditElements.name.value as string}
          />

          <InputDefault
            label={formEditElements.address_line_1.label}
            type="text"
            helperText={errors.address_line_1 ? errors.address_line_1 : ''}
            error={!!errors.address_line_1}
            onChange={handleChange}
            name="address_line_1"
            value={formEditElements.address_line_1.value as string}
          />

          <InputDefault
            label={formEditElements.post_code.label}
            type="text"
            helperText={errors.post_code ? errors.post_code : ''}
            error={!!errors.post_code}
            onChange={handleChange}
            name="post_code"
            value={formEditElements.post_code.value as string}
          />

          <Box component="div" className={styles.buttonContainer}>
            <ButtonDefault variant="contained" size="large" type="submit">
              {lang?.lingo.app_prmo_accept_button}
            </ButtonDefault>
            <ButtonDefault
              variant="contained"
              size="large"
              color="salad"
              onClick={() => {
                setErrors(initialFormEditErrorsState);
                closeModalEdit();
              }}
            >
              {lang?.lingo.app_button_cancel}
            </ButtonDefault>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};
