import { ILanguageProfile } from 'contexts/auth/auth.types';

export const getInitialFormElementsState = (lang: ILanguageProfile | null) => {
  return {
    sale_limit: {
      label: `${lang?.lingo.app_prmo_points}`,
      helper: `${lang?.lingo.app_prmo_points_desc}`,
      value: null
    },
    discount_message: {
      label: `${lang?.lingo.app_prmo_name}`,
      helper: `${lang?.lingo.app_prmo_name_desc}`,
      value: ''
    }
  };
};

export const initialFormErrorsState = {
  sale_limit: 0,
  discount_message: ''
};
