import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { PageLayout } from 'components/page-layout';
import { HeaderWithArrow } from 'components/HeaderWithArrow';
import { AuthContext } from 'contexts/auth';
import { IEmployees, IShop } from 'utils/auth-service.types';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';

import { ModalQR } from './components/ModalQR/ModalQR';
import { ModalSale } from './components/ModalSale/ModalSale';
import { SettingsSaleForm } from './components/ModalSale/ModalSale.types';
import { SettingsShopEditForm } from './components/ShopInfo/ModalEditShop/ModalEditShop.types';
import { getInitialFormElementsState } from './components/ModalSale/constants';
import { getInitialFormEditElementsState } from './components/ShopInfo/ModalEditShop/constants';
import { NavTabs } from './components/NavTabs';
import { ContentTypesEnum } from './components/NavTabs/NavTabs.types';
import { ShopInfo } from './components/ShopInfo';
import { SaleInfo } from './components/SaleInfo';
// import { EmployeesInfo } from './components/EmployeesInfo';
import { ModalEditShop } from './components/ShopInfo/ModalEditShop';

import styles from './ShopPage.module.scss';
import {EmployeesInfo} from "./components/EmployeesInfo";

export const ShopPage = (): JSX.Element => {
  const { lang, getOneShop, getEmployees } = useContext(AuthContext);

  const [shop, setShop] = useState<IShop | null>(null);
  const [employees, setEmployees] = useState<IEmployees[] | undefined>([]);
  const [openModalQR, setOpenModalQR] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [openModalSale, setOpenModalSale] = useState<boolean>(false);
  const [content, setContent] = useState<number>(0);

  const [formElements, setFormElements] = useState<SettingsSaleForm>(
    getInitialFormElementsState(lang)
  );
  const [formEditElements, setFormEditElements] =
    useState<SettingsShopEditForm>(getInitialFormEditElementsState(lang));

  const { shop_id } = useParams();

  const getInfoShop = async () => {
    const data = await getOneShop(shop_id as string);
    data && setShop(data);
  };

  const getInfoEmployees = async () => {
    const data = await getEmployees();
    data && setEmployees(data);
  };

  useEffect(() => {
    getInfoShop()
    .then(() => getInfoEmployees());
  }, [openModalSale, openModalEdit]);

  useEffect(() => {
    setFormElements(getInitialFormElementsState(lang));
  }, [lang]);

  const closeModal = () => {
    setFormElements(getInitialFormElementsState(lang));
    setOpenModalSale(false);
    getInfoShop();
  };

  const closeModalEdit = () => {
    setFormEditElements(getInitialFormEditElementsState(lang));
    setOpenModalEdit(false);
    getInfoShop();
  };

  return shop ? (
    <>
      <SeoMetaTitles title="Shop | QCUP.ME" />
      <PageLayout heading={<HeaderWithArrow name={shop?.name} />}>
        <NavTabs lang={lang} content={content} setContent={setContent} />
        <Box component="div" className={styles.containerShop}>
          {ContentTypesEnum[content] === ContentTypesEnum[0] ? (
            <ShopInfo
              lang={lang}
              shop={shop}
              setOpenModalQR={setOpenModalQR}
              setOpenModalEdit={setOpenModalEdit}
            />
          ) : null}

          {ContentTypesEnum[content] === ContentTypesEnum[1] ? (
            <EmployeesInfo
              shop={shop}
              lang={lang}
              employees={employees}
              getInfoEmployees={getInfoEmployees}
            />
          ) : null}

          {ContentTypesEnum[content] === ContentTypesEnum[2] ? (
            <SaleInfo
              lang={lang}
              shop={shop}
              setOpenModalSale={setOpenModalSale}
            />
          ) : null}

          {openModalSale ? (
            <ModalSale
              lang={lang}
              shop={shop}
              formElements={formElements}
              closeModal={closeModal}
              setFormElements={setFormElements}
            />
          ) : null}
          {openModalQR ? <ModalQR setOpenModalQR={setOpenModalQR} /> : null}
          {openModalEdit ? (
            <ModalEditShop
              openModalEdit={openModalEdit}
              lang={lang}
              shop={shop}
              formEditElements={formEditElements}
              closeModalEdit={closeModalEdit}
              setFormEditElements={setFormEditElements}
            />
          ) : null}
        </Box>
      </PageLayout>
    </>
  ) : (
    <></>
  );
};
