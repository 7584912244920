import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { App } from './app';
import { blueGrey, teal } from '@mui/material/colors';
import { AuthProvider } from './contexts/auth';
import { AlertProvider } from './contexts/alert';
import '@/styles/main.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(5, 75, 84, 1)'
    },
    secondary: {
      main: blueGrey[800]
    }
  }
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ThemeProvider theme={theme}>
    <AlertProvider>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </AlertProvider>
  </ThemeProvider>
);
