import React from 'react';

import styles from './loader.module.scss';
import { Backdrop } from '@mui/material';

interface LoaderProps {
  isOpened?: boolean;
}

export const Loader = ({ isOpened = true }: LoaderProps): JSX.Element => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={isOpened}
    >
      <div className={styles.loader}>
        <div className={styles.wrapper}>
          <img src="/images/cup.gif" alt="Loader" className={styles.image} />
        </div>
      </div>
    </Backdrop>
  );
};
