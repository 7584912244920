import React from 'react';

export const Trash = ({
  fill,
  width,
  height
}: {
  fill: string;
  width: string;
  height: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.85714 14.2222C2.85714 15.2 3.62857 16 4.57143 16H11.4286C12.3714 16 13.1429 15.2 13.1429 14.2222V5.33333C13.1429 4.35556 12.3714 3.55556 11.4286 3.55556H4.57143C3.62857 3.55556 2.85714 4.35556 2.85714 5.33333V14.2222ZM13.1429 0.888889H11L10.3914 0.257778C10.2371 0.0977777 10.0143 0 9.79143 0H6.20857C5.98571 0 5.76286 0.0977777 5.60857 0.257778L5 0.888889H2.85714C2.38571 0.888889 2 1.28889 2 1.77778C2 2.26667 2.38571 2.66667 2.85714 2.66667H13.1429C13.6143 2.66667 14 2.26667 14 1.77778C14 1.28889 13.6143 0.888889 13.1429 0.888889Z"
      fill={fill}
    />
  </svg>
);
