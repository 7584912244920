import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Modal } from '@mui/material';

import { ButtonDefault } from 'components/ButtonDefault';

import { IModalDeleteCategoryProps } from './ModalDeleteCategory.types';

import styles from './ModalDeleteCategory.module.scss';

export const ModalDeleteCategory = ({
  lang,
  currentItem,
  handleDeleteCategory,
  setOpenModalDelete
}: IModalDeleteCategoryProps): JSX.Element => {
  const closeModal = () => {
    setOpenModalDelete(false);
  };

  const navigate = useNavigate();

  return currentItem ? (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="div" className={styles.modalContainer}>
        <Box component="div" className={styles.title}>
          {lang?.lingo.app_ctgr_title_delete1}
        </Box>

        <Box component="div" className={styles.textContainer}>
          <Box component="div" className={styles.text}>
            {lang?.lingo.app_ctgr_btn_delete} «{currentItem.name}»?
          </Box>
          <Box component="div" className={styles.text}>
            {lang?.lingo.app_ctgr_title_delete2}
          </Box>
        </Box>
        <Box component="div" className={styles.buttonContainer}>
          <ButtonDefault
            color="green"
            type="button"
            variant="contained"
            onClick={() => {
              closeModal();
            }}
          >
            {lang?.lingo.app_prmo_delete_not_agree}
          </ButtonDefault>
          <ButtonDefault
            color="red"
            type="button"
            variant="contained"
            onClick={() => {
              handleDeleteCategory(currentItem.id as string).then(() => {
                navigate(`/profile`);
              });
            }}
          >
            {lang?.lingo.app_prmo_delete_agree}
          </ButtonDefault>
        </Box>
      </Box>
    </Modal>
  ) : (
    <></>
  );
};
