import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Box, Button, Typography} from '@mui/material';

import { QrReader } from 'components/qr-reader';

import { IStartPageQRScanProps } from './StartPageQRScan.types';

import styles from './StartPageQRScan.module.scss';
import {ButtonDefault} from "../../../../components/ButtonDefault";

import {ModalQR} from "../../../ShopPage/components/ModalQR/ModalQR";
import {QrCodeIcon} from "../../../../../public/images/newIcon/QrCodeIcon";

export const StartPageQRScan = ({
  lang,
  qrCodeScanningResult,
  handleError,
}: IStartPageQRScanProps): JSX.Element => {
  const navigate = useNavigate();
  const [openModalQR, setOpenModalQR] = useState<boolean>(false);
  return (
    <Box component="div" className={styles.scanContainer}>
      {openModalQR ? <ModalQR setOpenModalQR={setOpenModalQR} /> : null}
      <Box sx={{display: "flex", gap: 2}}>
        <ButtonDefault href={"https://qcup-me.notion.site/QCUP-ME-7e36b67a83654048a47cad27ebd662cb"}>Инструкция</ButtonDefault>
      <ButtonDefault
          color="green"
          type="button"
          variant="contained"
          onClick={() => {
            setOpenModalQR(true);
          }}
          className={styles.addShopBtn}
        >
          <Box component="div" className={styles.buttonQRCODE}>
            <QrCodeIcon fill="#fff" width="16px" height="16px" />
            <Box component="span">QR Код</Box>
          </Box>
        </ButtonDefault>
      </Box>
      <Typography className={styles.title}>📇 Пожалуйста, отсканируйте QR код вашего клиента, чтобы начислить или списать баллы.</Typography>
      <QrReader onResult={qrCodeScanningResult} onError={handleError} />
    </Box>
  );
};
