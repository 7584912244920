import { ILanguageProfile } from 'contexts/auth/auth.types';

export const getInitialFormElementsState = (lang: ILanguageProfile | null) => {
  return {
    name: {
      label: `${lang?.lingo.app_sgnp_name}`,
      helper: `${lang?.lingo.app_sgnp_name}`,
      value: null
    },
    email: {
      label: `${lang?.lingo.app_sgnp_email}`,
      helper: `${lang?.lingo.app_sgnp_email}`,
      value: ''
    },
    password: {
      label: `${lang?.lingo.app_sgnp_password}`,
      helper: `${lang?.lingo.app_sgnp_password}`,
      value: ''
    }
  };
};

export const initialFormErrorsState = {
  name: '',
  email: '',
  password: ''
};
