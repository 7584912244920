import { ICategory } from 'utils/auth-service.types';
import { ILanguageProfile } from 'contexts/auth/auth.types';

import { SettingsCategoryEditForm } from './ModalEditCategory.types';
import { getInitialFormEditElementsState } from './constants';

type TEditFormPayloadKeys = 'name' | 'description' | 'emodji';

export const mapEditFormCategory = (
  category: ICategory,
  lang: ILanguageProfile | null
): SettingsCategoryEditForm => {
  const payload: SettingsCategoryEditForm =
    getInitialFormEditElementsState(lang);
  Object.entries(getInitialFormEditElementsState(lang)).forEach(([key, v]) => {
    payload[key as TEditFormPayloadKeys] = {
      ...v,
      value:
        key === 'name'
          ? category?.name || ''
          : category[key as TEditFormPayloadKeys]
    };
  });
  return payload;
};
