import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/material';

import { IProfileTariffProps } from './ProfileTariff.types';
import { PaidButton } from './components/PaidButton';

import { Promotion } from '../../../../../public/images/newIcon/Promotion';
import { ShopIcon } from '../../../../../public/images/newIcon/ShopIcon';
import { PaperPlaneIcon } from '../../../../../public/images/newIcon/PaperPlaneIcon';
import { Profile } from '../../../../../public/images/newIcon/Profile';

import styles from './ProfileTariff.module.scss';

export const ProfileTariff = ({ user, lang, getUser }: IProfileTariffProps) => {
  const endDate = dayjs(user?.trial.started).add(user?.trial.days, 'day');

  const formattedEndDate = endDate.format('DD.MM.YYYY');
  const interval = useRef<NodeJS.Timeout | null>(null);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const payWaiting = localStorage.getItem('payWaiting') || null;

  useEffect(() => {
    if (payWaiting && !user?.paid) {
      setIsButtonDisabled(true);

      interval.current = setInterval(() => {
        getUser();
      }, 60000);
    } else {
      interval.current && clearInterval(interval.current);
      payWaiting && localStorage.removeItem('payWaiting');
    }
    return () =>
      interval.current ? clearInterval(interval.current) : undefined;
  }, [user?.paid]);

  return (
    <Box component="div" className={styles.tariffContainer}>
      <Box component="div" className={styles.containerTitle}>
        Подписка (Бета-версия)
        {/*{user?.paid*/}
        {/*  ? lang?.lingo.app_prfl_trff_name*/}
        {/*  : `${lang?.lingo.app_prfl_trial_name} (${user?.trial.days} ${lang?.lingo.app_prfl_trial_count})`}*/}
      </Box>
      <Box component="div" className={styles.tariffInfo}>
        <Box component="div" className={styles.tariffInfoContainerTop}>
          <Box component="div" className={styles.tariffInfoItem}>
            <Box component="div" className={styles.tariffInfoCount}>
              <ShopIcon width="14px" height="14px" fill="#9B65F2" />
              <Box component="div" className={styles.tariffInfoText}>
                {user.b2b?.limits?.shops}
              </Box>
            </Box>
            <Box component="div" className={styles.tariffInfoTextBottom}>
              {lang?.lingo.app_prfl_trff_shops}
            </Box>
          </Box>

          <Box component="div" className={styles.tariffContainerRight}>
            <Box component="div" className={styles.tariffInfoItem}>
              <Box component="div" className={styles.tariffInfoCount}>
                <Promotion width="14px" height="14px" fill="#9B65F2" />
                <Box component="div" className={styles.tariffInfoText}>
                  {user.b2b?.limits?.sales_in_shop}
                </Box>
              </Box>
              <Box component="div" className={styles.tariffInfoTextBottom}>
                {lang?.lingo.app_prfl_trff_sales}
              </Box>
            </Box>

            <Box component="div" className={styles.tariffInfoItem}>
              <Box component="div" className={styles.tariffInfoCount}>
                <Profile width="14px" height="14px" fill="#9B65F2" />
                <Box component="div" className={styles.tariffInfoText}>
                  {user.b2b?.limits?.users}
                </Box>
              </Box>
              <Box component="div" className={styles.tariffInfoTextBottom}>
                {lang?.lingo.app_prfl_trff_employees}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box component="div" className={styles.tariffInfoContainerBottom}>
          <Box component="div" className={styles.tariffInfoItem}>
            <Box component="div" className={styles.tariffInfoCount}>
              <PaperPlaneIcon width="14px" height="14px" fill="#9B65F2" />
              <Box component="div" className={styles.tariffInfoText}>
                {user.b2b?.limits?.campaigns_in_month}
              </Box>
            </Box>
            <Box component="div" className={styles.tariffInfoTextBottom}>
              {lang?.lingo.app_prfl_trff_campaign}
            </Box>
          </Box>

          <Box component="div" className={styles.tariffInfoItem}>
            <Box component="div" className={styles.tariffInfoText}>
              {/*{formattedEndDate}*/}
              ---
            </Box>
            <Box component="div" className={styles.tariffInfoTextBottom}>
              {user.paid
                ? lang?.lingo.app_prfl_trial_write_off
                : lang?.lingo.app_prfl_trial_end}
            </Box>
          </Box>
        </Box>

        {/*<PaidButton*/}
        {/*  lang={lang}*/}
        {/*  paid={user.paid}*/}
        {/*  id={user.id}*/}
        {/*  isButtonDisabled={isButtonDisabled}*/}
        {/*/>*/}
      </Box>
    </Box>
  );
};
