import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface AuthGuardProps {
  isAllowed: boolean;
  redirectPath?: string;
  children?: JSX.Element;
}

export const AuthGuard = ({
  isAllowed,
  children,
  redirectPath = '/login'
}: AuthGuardProps) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
