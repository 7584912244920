import { ChangePasswordFormData, LoginFormData } from 'pages/login/login.types';
import { SignUpFormData } from 'pages/sign-up/sign-type.types';
import { SettingsFormData } from 'pages/settings/settings';
import { TResponseData } from 'types/responses';
import {
  IShopInfo,
  ICampaigns,
  ICreateCampaign,
  ICreateSale,
  ICreateShop,
  ICreateTransaction,
  IShop,
  ITransaction,
  IEditProfile,
  IEmployees,
  ICreateEmployee,
  IAnalytics,
  IAIAssistant,
  ICategory,
  ICreateCategory
} from './auth-service.types';

const fetchUtil = async ({
  method,
  url,
  headers,
  queryParams,
  data
}: {
  url: string;
  method?: string;
  headers?: Record<string, string>;
  data?: unknown;
  queryParams?: Record<string, string>;
}) => {
  const query = new URLSearchParams(queryParams).toString();
  const response = await fetch(`/api/${url}?${query}`, {
    method: method,
    headers: { ...{ 'Content-Type': 'application/json' }, ...headers },
    body: JSON.stringify(data)
  });
  return response;
};

const fetchRequest = async (
  url: string,
  {
    method = 'GET',
    headers = {},
    queryParams = {},
    data
  }: {
    method?: string;
    headers?: Record<string, string>;
    data?: unknown;
    queryParams?: Record<string, string>;
  }
) => {
  let response = await fetchUtil({ method, url, headers, queryParams, data });

  if (
    response.status === 401 &&
    !response.url.includes('/api/v1/b2b/user/signin?')
  ) {
    const refreshResponse = await refreshTokens();

    const refreshJson = await refreshResponse.json();
    const token = refreshJson.data?.access_token;
    if (refreshResponse.status === 401) {
      localStorage.removeItem('token');
      throw new Error();
    }
    if (token) {
      localStorage.setItem('token', token);
      const newHeaders = {
        Authorization: 'Bearer ' + token
      };

      response = await fetchUtil({
        method,
        url,
        headers: newHeaders,
        queryParams,
        data
      });
    }
  }

  if (response.status === 403) {
    const responsJson = await response.json();
    const errorText =
      responsJson.message || responsJson.detail || 'Unknown error';
    throw new Error(errorText);
  }

  const contentType = response.headers.get('content-type');

  const responseData = !contentType?.startsWith('image')
    ? await response.json()
    : response;

  if (
    !response.ok &&
    responseData.message !== 'Invalid token or expired token' &&
    responseData.message !== 'Bad refresh_token'
  ) {
    const errorText =
      responseData.message || responseData.detail || 'Unknown error';

    throw new Error(errorText);
  }

  return responseData;
};

const refreshTokens = async () => {
  const response = await fetchUtil({ url: 'v1/b2b/user/token/refresh' });
  return response;
};
const handleLogin = async (data: LoginFormData) =>
  await fetchRequest('v1/b2b/user/signin', { method: 'POST', data });

const handleChangePassword = async (data: ChangePasswordFormData) =>
  await fetchRequest('v1/b2b/user/request_password_from_anonym', {
    method: 'POST',
    data
  });

const fetchTransactions = async (
  headers?: Record<string, string>,
  query?: Record<string, string>
): Promise<TResponseData<ITransaction>> =>
  await fetchRequest('v1/b2b/transaction/', {
    headers,
    queryParams: query
  });

const fetchAnalytics = async (
  headers?: Record<string, string>,
  query?: Record<string, string>
): Promise<TResponseData<IAnalytics>> =>
  await fetchRequest('v1/b2b/analytics/', {
    headers,
    queryParams: query
  });

const createTransactions = async (
  headers?: Record<string, string>,
  query?: Record<string, string>
): Promise<TResponseData<ICreateTransaction>> =>
  await fetchRequest('v1/b2b/transaction/', {
    headers: headers,
    queryParams: query,
    method: 'POST'
  });

// const acceptTransaction = async (
//   headers?: Record<string, string>,
//   query?: Record<string, string>
// ) =>
//   await fetchRequest('v1/b2b/transaction/', {
//     method: 'PUT',
//     headers: headers,
//     queryParams: query
//   });

const fetchPreTransaction = async (
  headers?: Record<string, string>,
  data?: Record<string, string>
): Promise<TResponseData<IShopInfo>> =>
  await fetchRequest('v1/b2b/transaction/pre', {
    headers,
    method: 'POST',
    data
  });

const handleSignUp = async (data: SignUpFormData) =>
  await fetchRequest('v1/b2b/user/signup', { method: 'POST', data });

const getUser = async (headers?: Record<string, string>) =>
  await fetchRequest('v1/b2b/user/', { headers });

const getLanguage = async (headers?: Record<string, string>) =>
  await fetchRequest('v1/b2b/user/lingo', { headers });

const editProfile = async (
  data: IEditProfile,
  headers?: Record<string, string>
) => await fetchRequest('v1/b2b/user/', { headers, data, method: 'PUT' });

const updateSettings = async (
  data: SettingsFormData,
  headers?: Record<string, string>
) => await fetchRequest('user/me', { method: 'PUT', headers, data });

const getShops = async (
  headers?: Record<string, string>
): Promise<TResponseData<IShop[]>> =>
  await fetchRequest('v1/b2b/shop/', {
    headers,
    method: 'GET'
  });
const getOneShop = async (
  shop_id: string,
  headers?: Record<string, string>
): Promise<TResponseData<IShop>> =>
  await fetchRequest(`v1/b2b/shop/${shop_id}`, {
    headers,
    method: 'GET'
  });

const createShop = async (
  data: ICreateShop,
  headers?: Record<string, string>
): Promise<TResponseData<IShop>> =>
  await fetchRequest('v1/b2b/shop/', {
    headers,
    data,
    method: 'POST'
  });
const editShop = async (
  shop_id: string,
  data: ICreateShop,
  headers?: Record<string, string>
) =>
  await fetchRequest(`v1/b2b/shop/${shop_id}`, {
    headers,
    data,
    method: 'PUT'
  });

const getCategories = async (
  headers?: Record<string, string>
): Promise<TResponseData<ICategory[]>> =>
  await fetchRequest('v1/b2b/category/', {
    headers,
    method: 'GET'
  });

const getOneCategory = async (
  category_id: string,
  headers?: Record<string, string>
): Promise<TResponseData<ICategory>> =>
  await fetchRequest(`v1/b2b/category/${category_id}`, {
    headers,
    method: 'GET'
  });

const createCategory = async (
  data: ICreateCategory,
  headers?: Record<string, string>
): Promise<TResponseData<ICategory>> =>
  await fetchRequest('v1/b2b/category/', {
    headers,
    data,
    method: 'POST'
  });

const editCategory = async (
  category_id: string,
  data: ICreateCategory,
  headers?: Record<string, string>
) =>
  await fetchRequest(`v1/b2b/category/${category_id}`, {
    headers,
    data,
    method: 'PUT'
  });

const deleteCategory = async (
  category_id: string,
  headers?: Record<string, string>
): Promise<TResponseData<null>> =>
  await fetchRequest(`v1/b2b/category/${category_id}`, {
    headers,
    method: 'DELETE'
  });

const getQRCode = async (shop_id: string, headers?: Record<string, string>) => {
  return await fetchRequest(`/v1/b2b/shop/qr-code/${shop_id}`, {
    headers,
    method: 'GET'
  });
};

const getEmployees = async (
  headers?: Record<string, string>
): Promise<TResponseData<IEmployees[]>> =>
  await fetchRequest('/v1/b2b/user/users/', {
    headers,
    method: 'GET'
  });

const createEmployee = async (
  data: ICreateEmployee,
  headers?: Record<string, string>
): Promise<TResponseData<IEmployees>> =>
  await fetchRequest('/v1/b2b/user/users/invite', {
    headers,
    data,
    method: 'POST'
  });

const deleteEmployee = async (
  user_id: string,
  headers?: Record<string, string>
): Promise<TResponseData<null>> =>
  await fetchRequest(`/v1/b2b/user/users/${user_id}`, {
    headers,
    method: 'DELETE'
  });

const createSale = async (
  shop_id: string,
  data: ICreateSale,
  headers?: Record<string, string>
): Promise<TResponseData<IShop>> =>
  await fetchRequest(`v1/b2b/shop/${shop_id}/sale`, {
    headers,
    data,
    method: 'POST'
  });

const deleteSale = async (
  shop_id: string,
  sale_code: string,
  headers?: Record<string, string>
): Promise<TResponseData<null>> =>
  await fetchRequest(`v1/b2b/shop/${shop_id}/sale/${sale_code}`, {
    headers,
    method: 'DELETE'
  });

const getCampaigns = async (
  headers?: Record<string, string>
): Promise<TResponseData<ICampaigns[]>> =>
  await fetchRequest('v1/b2b/campaign/', {
    headers,
    method: 'GET'
  });

const getOneCampaign = async (
  campaign_id: string,
  headers?: Record<string, string>
): Promise<TResponseData<ICampaigns>> =>
  await fetchRequest(`v1/b2b/campaign/${campaign_id}`, {
    headers,
    method: 'GET'
  });

const createCampaign = async (
  data: ICreateCampaign,
  headers?: Record<string, string>
): Promise<TResponseData<ICampaigns>> =>
  await fetchRequest('v1/b2b/campaign/', {
    headers,
    data,
    method: 'POST'
  });

const editCampaign = async (
  campaign_id: string,
  data: ICreateCampaign,
  headers?: Record<string, string>
): Promise<TResponseData<ICampaigns>> =>
  await fetchRequest(`v1/b2b/campaign/${campaign_id}`, {
    headers,
    data,
    method: 'PUT'
  });

const useAIAssistant = async (
  query: string,
  headers?: Record<string, string>
): Promise<TResponseData<IAIAssistant>> =>
  await fetchRequest(`v1/b2b/campaign/assist`, {
    headers,
    data: { query: query },
    method: 'POST'
  });

const deleteCampaign = async (
  campaign_id: string,
  headers?: Record<string, string>
): Promise<TResponseData<null>> =>
  await fetchRequest(`v1/b2b/campaign/${campaign_id}`, {
    headers,
    method: 'DELETE'
  });

export {
  handleLogin,
  handleChangePassword,
  fetchTransactions,
  fetchAnalytics,
  handleSignUp,
  getUser,
  updateSettings,
  fetchPreTransaction,
  createTransactions,
  getShops,
  createShop,
  getOneShop,
  editShop,
  getCategories,
  getOneCategory,
  createCategory,
  editCategory,
  deleteCategory,
  getQRCode,
  getEmployees,
  createEmployee,
  deleteEmployee,
  createSale,
  deleteSale,
  getCampaigns,
  getOneCampaign,
  createCampaign,
  editCampaign,
  deleteCampaign,
  editProfile,
  getLanguage,
  useAIAssistant
};
