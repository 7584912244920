import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card } from '@mui/material';
import dayjs from 'dayjs';

import 'dayjs/locale/ru';
import 'dayjs/locale/en';

import { PageLayout } from 'components/page-layout';
import { HeaderWithArrow } from 'components/HeaderWithArrow';
import { AuthContext } from 'contexts/auth';
import { ICategory } from 'utils/auth-service.types';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';
import { ButtonDefault } from 'components/ButtonDefault';

import { ModalDeleteCategory } from './ModalDeleteCategory';
import { ModalEditCategory } from './ModalEditCategory';
import { SettingsCategoryEditForm } from './ModalEditCategory/ModalEditCategory.types';
import { getInitialFormEditElementsState } from './ModalEditCategory/constants';

import { Trash } from '../../../public/images/newIcon/Trash';

import styles from './CategoryPage.module.scss';

export const CategoryPage = (): JSX.Element => {
  const { deleteCategory, lang, getOneCategory, getUser } =
    useContext(AuthContext);

  const [category, setCategory] = useState<ICategory | null>(null);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

  const [formEditElements, setFormEditElements] =
    useState<SettingsCategoryEditForm>(getInitialFormEditElementsState(lang));

  const { category_id } = useParams();

  const getInfoCategory = async () => {
    const data = await getOneCategory(category_id as string);
    data && setCategory(data);
  };

  useEffect(() => {
    getInfoCategory().then(() => getUser());
  }, [openModalEdit]);

  useEffect(() => {
    setFormEditElements(getInitialFormEditElementsState(lang));
  }, [lang]);

  const closeModalEdit = () => {
    setFormEditElements(getInitialFormEditElementsState(lang));
    setOpenModalEdit(false);
    getInfoCategory();
  };

  const convertDate = (date: string, lang: string) => {
    let language = () => (lang === 'ru' ? 'ru' : 'en');
    return dayjs(date).locale(language()).format('D MMMM YYYY');
  };

  const handleDeleteCategory = async (category_id: string) => {
    await deleteCategory(category_id as string);
    getInfoCategory();
  };

  return category ? (
    <>
      <SeoMetaTitles title={`${lang?.lingo.app_ctgr_title} | QCUP.ME`} />
      <PageLayout heading={<HeaderWithArrow name={category?.name} />}>
        <Box component="div" className={styles.containerCategory}>
          <Card className={styles.basicCardCategory}>
            <Box component="div" className={styles.basicContainer}>
              <Box component="span" className={styles.title}>
                {lang?.lingo.app_ctgr_title}
              </Box>
              <Box component="span" className={styles.infoCategory}>
                {category?.name}
              </Box>
            </Box>
            <Box component="div" className={styles.basicContainer}>
              <Box component="span" className={styles.title}>
                {lang?.lingo.app_ctgr_emoji_input}
              </Box>
              <Box component="span" className={styles.infoCategory}>
                {category?.emodji}
              </Box>
            </Box>
            <Box component="div" className={styles.basicContainer}>
              <Box component="span" className={styles.title}>
                {lang?.lingo.app_ctgr_desc_input}
              </Box>
              <Box component="span" className={styles.infoCategory}>
                {category?.description
                  ? category?.description
                  : lang?.lingo.app_ctgr_nodesc_input}
              </Box>
            </Box>
            <Box component="div" className={styles.basicContainer}>
              <Box component="span" className={styles.title}>
                {lang?.lingo.app_ctgr_date_input}
              </Box>
              <Box component="span" className={styles.infoCategory}>
                {lang && convertDate(category?.created, lang?.lang)}
              </Box>
            </Box>
          </Card>

          <ButtonDefault
            color="salad"
            type="button"
            variant="contained"
            onClick={() => setOpenModalEdit(true)}
          >
            {lang?.lingo.app_button_edit}
          </ButtonDefault>

          <ButtonDefault
            color="red"
            type="button"
            variant="contained"
            onClick={() => setOpenModalDelete(true)}
          >
            <Box component="div" className={styles.buttonDltContainer}>
              <Trash fill="#e50045" width="16px" height="16px" />
              <Box component="div">{lang?.lingo.app_ctgr_btn_delete}</Box>
            </Box>
          </ButtonDefault>

          {openModalEdit ? (
            <ModalEditCategory
              openModalEdit={openModalEdit}
              lang={lang}
              category={category}
              formEditElements={formEditElements}
              closeModalEdit={closeModalEdit}
              setFormEditElements={setFormEditElements}
            />
          ) : null}

          {openModalDelete ? (
            <ModalDeleteCategory
              lang={lang}
              currentItem={category}
              handleDeleteCategory={handleDeleteCategory}
              setOpenModalDelete={setOpenModalDelete}
            />
          ) : null}
        </Box>
      </PageLayout>
    </>
  ) : (
    <></>
  );
};
