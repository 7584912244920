import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {Box, Card, Checkbox, Grid} from '@mui/material';

import { PageLayout } from 'components/page-layout';
import { AuthContext } from 'contexts/auth';
import { ICampaigns } from 'utils/auth-service.types';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';

import { ButtonDefault } from 'components/ButtonDefault';
import { HeaderWithArrow } from 'components/HeaderWithArrow';

import { changeTextStatus, choseColorByStatus, formatDate } from '../utils';
import { ModalCampaign } from '../ModalCampaign/ModalCampaign';
import { SettingsForm } from '../ModalCampaign/ModalCampaign.types';
import { getInitialFormElementsState } from '../ModalCampaign/constants';
import { ModalDeleteCampaign } from '../ModalDeleteCampaign';

import { Trash } from '../../../../public/images/newIcon/Trash';

import styles from './OneCampaignPage.module.scss';

export const OneCampaignPage = (): JSX.Element => {
  const { lang, getOneCampaign, deleteCampaign } = useContext(AuthContext);

  const [campaign, setCampaign] = useState<ICampaigns | null>(null);
  const [openModalCampaign, setOpenModalCampaign] = useState<boolean>(false);
  const [formElements, setFormElements] = useState<SettingsForm>(
    getInitialFormElementsState(lang)
  );
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

  const { campaign_id } = useParams();

  useEffect(() => {
    getInfoCampaign();
  }, [openModalCampaign, openModalDelete]);

  const getInfoCampaign = async () => {
    const data = (await getOneCampaign(campaign_id as string)) || null;
    setCampaign(data);
  };

  const closeModal = async () => {
    setFormElements({
      name: { ...getInitialFormElementsState(lang).name, value: '' },
      text: { ...getInitialFormElementsState(lang).text, value: '' },
      scheduled: { ...getInitialFormElementsState(lang).scheduled, value: '' }
    });
    setOpenModalCampaign(false);
    await getInfoCampaign();
  };

  const handleDeleteCamapigh = async (campaign_id: string) => {
    deleteCampaign(campaign_id).then(() => {
      getInfoCampaign();
    });
  };

  // @ts-ignore
  return campaign ? (
    <>
      <SeoMetaTitles title={`${lang?.lingo.app_cmpgn_title} | QCUP.ME`} />
      <PageLayout heading={<HeaderWithArrow name={campaign?.name} />}>
        <Card className={styles.basicCardCampaign}>
          <Box component="div" className={styles.basicContainer}>
            <Box component="div" className={styles.title}>
              {lang?.lingo.app_cmpgn_one_title}
            </Box>
            <Box component="div" className={styles.infoCampaign}>
              {campaign?.name}
            </Box>
          </Box>
          <Box component="div" className={styles.basicContainer}>
            <Box component="div" className={styles.title}>
              {lang?.lingo.app_cmpgn_one_text}
            </Box>
            <Box component="div" className={styles.infoCampaign}>
              {campaign?.text}
            </Box>
          </Box>
          <Box component="div" className={styles.basicContainer}>
            <Box component="div" className={styles.title}>
              {lang?.lingo.app_cmpgn_one_date}
            </Box>
            <Box component="div" className={styles.infoCampaign}>
              {formatDate(campaign?.scheduled, lang)}
            </Box>
          </Box>
          <Box component="div" className={styles.basicContainer}>
            <Box component="div" className={styles.title}>
              {lang?.lingo.app_cmpgn_one_status}
            </Box>
            <Box
              component="p"
              className={`${styles.campaignTextDefault} ${
                styles[choseColorByStatus(campaign?.status)]
              }`}
            >
              {changeTextStatus(campaign?.status, lang)}
            </Box>
          </Box>
          <Grid container spacing={4}>
<Grid item md={4} xs={12}>
              Возраст
              <Box className={styles.title}>
                  <Checkbox
                    checked={campaign?.meta['age_category_1']}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                 {"меньше 20"}
              </Box>
              <Box className={styles.title}>
                  <Checkbox
                    checked={campaign?.meta['age_category_2']}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                {"20-45"}
              </Box>
              <Box className={styles.title}>
                  <Checkbox
                    checked={campaign?.meta['age_category_3']}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                {"больше 45"}
              </Box>

            </Grid>
            <Grid item md={4} xs={12}>
              Пол
              <Box className={styles.title}>
                  <Checkbox
                    checked={campaign?.meta['gender_1']}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                 {"👩 Женский"}
              </Box>
              <Box className={styles.title}>
                  <Checkbox
                    checked={campaign?.meta['gender_2']}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                {"👨 Мужской"}
              </Box>
            </Grid>
          </Grid>

        </Card>

        <Box component="div" className={styles.buttonContainer}>
          {/*<ButtonDefault*/}
          {/*  onClick={() => setOpenModalCampaign(true)}*/}
          {/*  color="green"*/}
          {/*  type="button"*/}
          {/*  variant="contained"*/}
          {/*>*/}
          {/*  {lang?.lingo.app_button_edit}*/}
          {/*</ButtonDefault>*/}
          <ButtonDefault
            color="red"
            type="button"
            variant="contained"
            disabled={campaign?.status === 'done'}
            onClick={() => setOpenModalDelete(true)}
          >
            <Box component="div" className={styles.buttonDeleteSale}>
              <Trash
                fill={campaign?.status !== 'done' ? '#e50045' : '#B8B8B8'}
                width="16px"
                height="16px"
              />
              <Box component="div">{lang?.lingo.app_cmpgn_delete}</Box>
            </Box>
          </ButtonDefault>
        </Box>

        <ModalCampaign
          lang={lang}
          formElements={formElements}
          openModalCampaign={openModalCampaign}
          currentItem={campaign}
          closeModal={closeModal}
          setFormElements={setFormElements}
        />

        {openModalDelete ? (
          <ModalDeleteCampaign
            lang={lang}
            currentItem={campaign}
            handleDeleteCamapigh={handleDeleteCamapigh}
            setOpenModalDelete={setOpenModalDelete}
          />
        ) : null}
      </PageLayout>
    </>
  ) : (
    <></>
  );
};
