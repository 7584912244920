import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card } from '@mui/material';

import { PageLayout } from 'components/page-layout';
import { AuthContext } from 'contexts/auth';
import { ICategory } from 'utils/auth-service.types';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';
import { ButtonDefault } from 'components/ButtonDefault';

import { ModalCategory } from './ModalCategory/ModalCategory';
import { SettingsForm } from './ModalCategory/ModalCategory.types';
import { getInitialFormElementsState } from './ModalCategory/constants';

import { AddPlus } from '../../../public/images/newIcon/AddPlus';

import styles from './CategoriesPage.module.scss';

export const CategoriesPage = (): JSX.Element => {
  const navigate = useNavigate();

  const { user, lang, getCategories, getUser } = useContext(AuthContext);

  const [categories, setCategories] = useState<ICategory[] | undefined>([]);
  const [openModalCategory, setOpenModalCategory] = useState<boolean>(false);
  const [formElements, setFormElements] = useState<SettingsForm>(
    getInitialFormElementsState(lang)
  );

  useEffect(() => {
    getAllCategories().then(() => {
      getUser();
    });
  }, []);

  useEffect(() => {
    setFormElements(getInitialFormElementsState(lang));
  }, [lang]);

  const getAllCategories = async () => {
    const data = await getCategories();
    setCategories(data);
  };

  const closeModal = () => {
    setFormElements(getInitialFormElementsState(lang));
    setOpenModalCategory(false);
    getAllCategories();
  };

  const headingContainer = (
    <Box component="div" className={styles.headingContainer}>
      <Box component="p" className={styles.categoriesName}>
        {lang?.lingo.app_ctgr_title}
      </Box>
      <Box component="div" className={styles.categoriesLength}>
        {categories?.length ? categories?.length : 0}
      </Box>
    </Box>
  );

  return user ? (
    <>
      <SeoMetaTitles title={`${lang?.lingo.app_ctgr_title} | QCUP.ME`} />
      <PageLayout heading={headingContainer}>
        <Box component="div" className={styles.categoriesContainer}>
          <Box
            component="div"
            className={
              categories && categories.length > 0 ? styles.categories : ''
            }
          >
            {categories && categories.length > 0 ? (
              categories.map(({ name, id, emodji }) => (
                <Card
                  key={id}
                  onClick={() => {
                    navigate(`/categories/${id}`);
                  }}
                  className={styles.categoryCard}
                >
                  <Box component="div" className={styles.categoryContainer}>
                    <Box
                      component="div"
                      className={styles.categoryNameContainer}
                    >
                      <Box component="p" className={styles.categoryName}>
                        {emodji}
                      </Box>
                      <Box component="p" className={styles.categoryName}>
                        {name}
                      </Box>
                    </Box>
                    <img
                      src="/images/newIcon/ArrowRight.svg"
                      alt="arrow_right"
                      className={styles.arrowRight}
                    />
                  </Box>
                </Card>
              ))
            ) : (
              <Box component="div" className={styles.emptyStateBox}>
                <Box component="div" className={styles.emptyStateTitle}>
                  {lang?.lingo.app_ctgr_empty_list}
                </Box>
              </Box>
            )}
          </Box>

          <ButtonDefault
            color="green"
            type="button"
            variant="contained"
            disabled={!user?.available_actions?.can_add_category}
            onClick={() => setOpenModalCategory(true)}
            className={styles.addCategoryBtn}
          >
            <Box component="div" className={styles.addCategory}>
              <AddPlus
                fill={
                  user?.available_actions?.can_add_category ? '#fff' : '#B8B8B8'
                }
              />
              <Box component="span">
                {lang?.lingo.app_ctgr_button_create_ctgr}
              </Box>
            </Box>
          </ButtonDefault>
          {!user.available_actions?.can_add_shop ? (
            <Box component="div" className={styles.toCreateMoreCategories}>
              {lang?.lingo.app_ctgr_contact_us1}{' '}
              <a href="mailto:contact@qcup.me">{lang?.lingo.app_contact_us2}</a>
            </Box>
          ) : null}
        </Box>

        <ModalCategory
          lang={lang}
          formElements={formElements}
          openModalCategory={openModalCategory}
          closeModal={closeModal}
          setFormElements={setFormElements}
        />
      </PageLayout>
    </>
  ) : (
    <></>
  );
};
