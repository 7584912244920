import React from 'react';
import { Box, Card } from '@mui/material';

import { ButtonDefault } from 'components/ButtonDefault';

import { IShopInfoProps } from './ShopInfo.types';

// import { QrCodeIcon } from '../../../../../public/images/newIcon/QrCodeIcon';

import styles from './ShopInfo.module.scss';
import {QrCodeIcon} from "../../../../../public/images/newIcon/QrCodeIcon";



export const ShopInfo = ({
  shop,
  lang,
  setOpenModalEdit,
  setOpenModalQR
}:
IShopInfoProps) => {
  return (
    <>
      <Card className={styles.basicCardShop}>
        <Box component="div" className={styles.basicContainer}>
          <Box component="span" className={styles.title}>
            {lang?.lingo.app_shps_shop_title_field}
          </Box>
          <Box component="span" className={styles.infoShop}>
            {shop?.name}
          </Box>
        </Box>
        <Box component="div" className={styles.basicContainer}>
          <Box component="span" className={styles.title}>
            {lang?.lingo.app_shps_shop_address_field}
          </Box>
          <Box component="span" className={styles.infoShop}>
            {shop?.address_line_1
              ? shop?.address_line_1
              : `${lang?.lingo.app_prmo_about_shop_no_adrss}`}
          </Box>
        </Box>
        <Box component="div" className={styles.basicContainer}>
          <Box component="span" className={styles.title}>
            {lang?.lingo.app_shps_shop_postcode_field}
          </Box>
          <Box component="span" className={styles.infoShop}>
            {shop?.post_code
              ? shop?.post_code
              : `${lang?.lingo.app_prmo_about_shop_no_pstcode}`}
          </Box>
        </Box>
      </Card>

      <Box component="div" className={styles.buttonContainer}>
        <ButtonDefault
          color="green"
          type="button"
          variant="contained"
          onClick={() => {
            setOpenModalQR(true);
          }}
          className={styles.addShopBtn}
        >
          <Box component="div" className={styles.buttonAddShop}>
            <QrCodeIcon fill="#fff" width="16px" height="16px" />
            <Box component="span">{lang?.lingo.app_prmo_about_shop_qr}</Box>
          </Box>
        </ButtonDefault>

        <ButtonDefault
          color="salad"
          type="button"
          variant="contained"
          onClick={() => setOpenModalEdit(true)}
        >
          {lang?.lingo.app_button_edit}
        </ButtonDefault>
      </Box>
    </>
  );
};
