import React from 'react';
import { Button } from '@mui/material';

interface ButtonDefaultProps {
  children: string | React.ReactNode;
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  type?: 'button' | 'submit' | 'reset';
  color?: 'green' | 'salad' | 'red' | 'purple' | 'telegram' | 'instagram';
  disabled?: boolean;
  qrScan?: boolean;
  id?: string;
  onClick?: () => void;
  className?: string;
  href?: string;
}

export const ButtonDefault = ({
  children,
  variant,
  size,
  type,
  disabled = false,
  color = 'green',
  qrScan = false,
  onClick,
  className,
  id,
  href
}: ButtonDefaultProps): JSX.Element => {
  let hoverBackgroundColor;
  let backgroundColor;
  let hoverTextColor;
  let backgroundInst;
  let textColor;
  let border;

  switch (color) {
    case 'green':
      textColor = '#fff';
      hoverTextColor = '#054B54';
      backgroundColor = '#054B54';
      hoverBackgroundColor = '#fff';
      border = disabled ? 'none' : '2px solid #054B54';
      break;
    case 'salad':
      textColor = '#054B54';
      hoverTextColor = '#0C7481';
      backgroundColor = '#E5EEEE';
      hoverBackgroundColor = '#F3F7F7';
      break;
    case 'red':
      textColor = '#e50045';
      hoverTextColor = '#FF3874';
      backgroundColor = '#ffe2e2';
      hoverBackgroundColor = '#FFEFEF';
      break;
    case 'purple':
      textColor = '#fff';
      hoverTextColor = '#fff';
      backgroundColor = '#9B65F2';
      hoverBackgroundColor = '#BA8FFF';
      break;
    case 'telegram':
      textColor = '#fff';
      hoverTextColor = '#fff';
      backgroundColor = '#28a7e8';
      hoverBackgroundColor = '#28a7e8';
      break;
    case 'instagram':
      textColor = '#fff';
      hoverTextColor = '#fff';
      backgroundColor = 'none';
      backgroundInst =
        'linear-gradient(267.78deg,#0042e3 -12.93%,#9736c2 17.59%, #f31da7 48.74%, #ff5720 78.01%,#ffc652 106.66%)';
      hoverBackgroundColor =
        'linear-gradient(267.78deg,#0042e3 -12.93%,#9736c2 17.59%, #f31da7 48.74%, #ff5720 78.01%,#ffc652 106.66%)';
      break;
    default:
      textColor = disabled ? '#b8b8b8' : '';
      backgroundColor = disabled ? '#e3e3e3' : '';
      break;
  }

  const qrButton = qrScan ? '24px !important' : '32px !important';

  return (
    <Button
      id={id}
      size={size}
      type={type}
      href={href}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      className={className}
      sx={{
        width: '100%',
        height: '45px',
        marginTop: qrButton,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        textTransform: 'none',
        fontFamily: 'NT Somic, sans-serif',
        border: border,
        color: textColor,
        backgroundColor: backgroundColor,
        '&:hover, &:focus, &:active': {
          color: hoverTextColor,
          backgroundColor: hoverBackgroundColor
        },
        background: backgroundInst
      }}
    >
      {children}
    </Button>
  );
};
