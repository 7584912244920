import React, { useState } from 'react';
import {
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import { BaseEmoji, EmojiPicker } from '../emoji-picker';

import styles from './input-emoji.module.scss';

interface InputEmojiProps {
  label: string;
  emoji?: string | null;
  value?: string | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEmojiSelect: (emoji: BaseEmoji) => void;
  helperText?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const InputEmoji = ({
  label,
  helperText,
  value,
  emoji,
  required = false,
  fullWidth = false,
  error = false,
  disabled = false,
  onChange,
  onEmojiSelect
}: InputEmojiProps): JSX.Element => {
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] =
    useState<boolean>(false);

  return (
    <>
      <FormControl
        variant="outlined"
        required={required}
        fullWidth={fullWidth}
        error={error}
        disabled={disabled}
        className={styles.formEmodji}
      >
        <InputLabel>{label}</InputLabel>
        <OutlinedInput
          type="text"
          value={value}
          readOnly
          onChange={onChange}
          startAdornment={
            <InputAdornment position="start" disablePointerEvents={disabled}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setIsEmojiPickerVisible(true)}
                edge="end"
                style={{ marginRight: '-8px' }}
              >
                {emoji ?? '😊'}
              </IconButton>
            </InputAdornment>
          }
          label={label}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
      <Dialog
        open={isEmojiPickerVisible}
        onClose={() => setIsEmojiPickerVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '& .MuiDialog-container .MuiDialog-paper': {
            margin: '0'
          }
        }}
      >
        <EmojiPicker
          onEmojiSelect={emoji => {
            setIsEmojiPickerVisible(false);
            onEmojiSelect(emoji);
          }}
        />
      </Dialog>
    </>
  );
};
