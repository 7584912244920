import React from 'react';
import { Box, Modal } from '@mui/material';

import { ButtonDefault } from 'components/ButtonDefault';

import { IModalContactsProps } from './ModalContacts.types';

import { Mail } from '../../../../../../public/images/newIcon/mail';
import { Instagram } from '../../../../../../public/images/newIcon/instagram';
import { Telegram } from '../../../../../../public/images/newIcon/telegram';

import styles from './ModalContacts.module.scss';

export const ModalContacts = ({
  lang,
  setOpenModalContacts
}: IModalContactsProps): JSX.Element => {
  const closeModal = () => {
    setOpenModalContacts(false);
  };

  return lang ? (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="div" className={styles.modalContainer}>
        <Box component="div" className={styles.headingContainer}>
          <img
            src="/images/newIcon/ArrowLeft.svg"
            alt="arrow_left"
            className={styles.arrowLeft}
            onClick={() => {
              closeModal();
            }}
          />
          <Box component="p" className={styles.name}>
            {lang?.lingo.app_prfl_title}
          </Box>
        </Box>

        <Box component="div" className={styles.title}>
          {lang?.lingo.app_contact_us1}
        </Box>

        <Box component="div" className={styles.textContainer}>
          <Box component="div" className={styles.text}>
            {lang?.lingo.app_prfl_contact_desc1}
          </Box>
          <Box component="div" className={styles.text}>
            {lang?.lingo.app_prfl_contact_desc2}
          </Box>
        </Box>
        <Box component="div" className={styles.buttonContainer}>
          <ButtonDefault
            href="https://ig.me/m/qcup.me"
            color="instagram"
            type="button"
            variant="contained"
            className={styles.contactContainerBtn}
          >
            <Box component="div" className={styles.contactBtn}>
              <Instagram fill="#fff" />
              <Box component="span">
                {lang?.lingo.app_prfl_contact_instagram}
              </Box>
            </Box>
          </ButtonDefault>
          <ButtonDefault
            href="https://t.me/qcup_me_contact_bot"
            color="telegram"
            type="button"
            variant="contained"
            className={styles.contactContainerBtn}
          >
            <Box component="div" className={styles.contactBtn}>
              <Telegram fill="#fff" />
              <Box component="span">
                {lang?.lingo.app_prfl_contact_telegram}{' '}
              </Box>
            </Box>
          </ButtonDefault>
          <ButtonDefault
            href="mailto:contact@qcup.me"
            color="salad"
            type="button"
            variant="contained"
            className={styles.contactContainerBtn}
          >
            <Box component="div" className={styles.contactBtn}>
              <Mail fill="#054B54" />
              <Box component="span">{lang?.lingo.app_lgin_email_field}</Box>
            </Box>
          </ButtonDefault>
        </Box>
      </Box>
    </Modal>
  ) : (
    <></>
  );
};
