import React from 'react';

export const ShopIcon = ({
  fill,
  width,
  height
}: {
  fill: string;
  width: string;
  height: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2.85359e-08C1.90492 -3.20869e-05 1.81181 0.0270443 1.73157 0.0780542C1.65134 0.129064 1.58731 0.201893 1.547 0.288L0.047 3.497C0.0102149 3.57597 -0.00529744 3.66319 0.002 3.75H0V4.695C0 5.219 0.226 5.721 0.63 6.091C1.032 6.461 1.579 6.669 2.149 6.669H2.449C3.01001 6.67257 3.55211 6.46643 3.969 6.091C4.133 5.941 4.267 5.769 4.369 5.583C4.496 5.413 4.702 5.413 4.821 5.569C4.924 5.761 5.061 5.937 5.228 6.091C5.631 6.461 6.178 6.669 6.748 6.669H7.285C7.84601 6.67257 8.38811 6.46643 8.805 6.091C8.961 5.947 9.091 5.784 9.191 5.607C9.322 5.402 9.551 5.407 9.671 5.597C9.772 5.778 9.904 5.945 10.063 6.091C10.466 6.461 11.013 6.669 11.583 6.669H11.851C12.412 6.67257 12.9541 6.46643 13.371 6.091C13.774 5.721 14 5.219 14 4.695V3.75H13.998C14.0053 3.66319 13.9898 3.57597 13.953 3.497L12.453 0.288C12.4127 0.201893 12.3487 0.129064 12.2684 0.0780542C12.1882 0.0270443 12.0951 -3.20869e-05 12 2.85359e-08H2ZM1 13V7.729C2.188 8.121 3.605 7.946 4.578 7.193C5.876 8.197 8.127 8.197 9.424 7.193C10.402 7.949 11.816 8.121 13 7.719V13C13 13.2652 12.8946 13.5196 12.7071 13.7071C12.5196 13.8946 12.2652 14 12 14H10.745V10.21C10.7448 10.1807 10.7384 10.1518 10.7264 10.1251C10.7143 10.0984 10.6968 10.0745 10.675 10.055C10.6284 10.0131 10.5677 9.99026 10.505 9.991H8.513C8.45031 9.99026 8.38964 10.0131 8.343 10.055C8.32117 10.0745 8.30366 10.0984 8.29161 10.1251C8.27955 10.1518 8.27321 10.1807 8.273 10.21V14H2C1.73478 14 1.48043 13.8946 1.29289 13.7071C1.10536 13.5196 1 13.2652 1 13ZM2.502 10.76V9.5C2.502 9.36739 2.55468 9.24021 2.64845 9.14645C2.74221 9.05268 2.86939 9 3.002 9H6.016C6.14861 9 6.27579 9.05268 6.36955 9.14645C6.46332 9.24021 6.516 9.36739 6.516 9.5V10.76C6.516 10.8926 6.46332 11.0198 6.36955 11.1136C6.27579 11.2073 6.14861 11.26 6.016 11.26H3.002C2.86939 11.26 2.74221 11.2073 2.64845 11.1136C2.55468 11.0198 2.502 10.8926 2.502 10.76Z"
      fill={fill}
    />
  </svg>
);
