import { ILanguageProfile } from 'contexts/auth/auth.types';

export const getInitialFormElementsState = (lang: ILanguageProfile | null) => {
  return {
    name: {
      label: `${lang?.lingo.app_cmpgn_one_title}`,
      helper: `${lang?.lingo.app_cmpgn_hlpr_title}`,
      value: ''
    },
    text: {
      label: `${lang?.lingo.app_cmpgn_one_text}`,
      helper: `${lang?.lingo.app_cmpgn_hlpr_text}`,
      value: ''
    },
    scheduled: {
      label: `${lang?.lingo.app_cmpgn_one_date}`,
      helper: 'Please enter date of your campaign',
      value: ''
    }
  };
};

export const initialFormErrorsState = {
  name: '',
  text: '',
  scheduled: ''
};
