import { ICampaigns } from 'utils/auth-service.types';
import { ILanguageProfile } from 'contexts/auth/auth.types';

import { getInitialFormElementsState } from './constants';
import { SettingsForm } from './ModalCampaign.types';
import dayjs from 'dayjs';

type TEditFormPayloadKeys = 'name' | 'text' | 'scheduled';

export const mapEditFormData = (
  item: ICampaigns,
  lang: ILanguageProfile | null
): SettingsForm => {
  const payload: SettingsForm = getInitialFormElementsState(lang);
  Object.entries(getInitialFormElementsState(lang)).forEach(([key, v]) => {
    payload[key as TEditFormPayloadKeys] = {
      ...v,
      value: item[key as TEditFormPayloadKeys]
    };
  });
  return payload;
};

export const addTimezoneOffset = (dateString: string): string => {
  const serverDate = dayjs(dateString);
  const currentTime = dayjs();
  const timezoneOffset = currentTime.utcOffset() / 60;

  const localServerDate = serverDate.add(timezoneOffset, 'hour');
  const formatedDate = localServerDate.format('YYYY-MM-DDTHH:mm:ss');

  return formatedDate;
};

export const subtractTimezoneOffset = (
  scheduledDate: dayjs.Dayjs | null
): string => {
  const updatedDate = dayjs(scheduledDate);
  const currentTime = dayjs();
  const timezoneOffset = currentTime.utcOffset() / 60;

  const date = dayjs(updatedDate).subtract(timezoneOffset, 'hour');
  const subtractedDate = date.format('YYYY-MM-DDTHH:mm:ss.SSS000');

  return subtractedDate;
};
