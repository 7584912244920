import React, { FormEvent, useState, useContext, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Modal, Stack} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  LocalizationProvider,
  MobileDateTimePicker
} from '@mui/x-date-pickers';

import { AuthContext } from 'contexts/auth';

import {
  IModalCampaignProps,
  SettingsForm,
  SettingsFormErrors
} from './ModalCampaign.types';
import { initialFormErrorsState } from './constants';
import {
  addTimezoneOffset,
  mapEditFormData,
  subtractTimezoneOffset
} from './utils';

import { InputDefault } from 'components/input-default';
import { ButtonDefault } from 'components/ButtonDefault';

import styles from './ModalCampaign.module.scss';

export const ModalCampaign = ({
  lang,
  formElements,
  openModalCampaign,
  currentItem,
  closeModal,
  setFormElements
}: IModalCampaignProps): JSX.Element => {
  const { createCampaign, editCampaign, useAIAssistant } = useContext(AuthContext);

  const [errors, setErrors] = useState<SettingsFormErrors>(
    initialFormErrorsState
  );
  const [scheduledDate, setScheduledDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (currentItem) {
      setFormElements(mapEditFormData(currentItem, lang));
      setScheduledDate(dayjs(addTimezoneOffset(currentItem?.scheduled)));
    }
  }, [currentItem]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [event.target.name]: '' });
    setFormElements({
      ...formElements,
      [event.target.name]: {
        ...formElements[event.target.name as keyof SettingsForm],
        value: event.target.value
      }
    });
  };

  const handlePromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(event.target.value)
  }

  const minDateTime = dayjs().add(3, 'day');
  const maxDateTime = dayjs().add(1, 'month');

  const handleDateChange = (value: Dayjs | null) => {
    setScheduledDate(value);
    setErrors({
      ...errors
    });
    setFormElements({
      ...formElements,
      scheduled: {
        ...formElements.scheduled,
        value: value ? value.format() : ''
      }
    });
  };


  const [AILoading, setAILoading] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("Скидка 20% процентов после 4х вечера");
  const handleUseAIAssistant = async () => {
    let value = "123";
    // SEND REQUEST
    setAILoading(true);
    let aiResponse = await useAIAssistant(prompt)
    setAILoading(false);
    setFormElements({
      ...formElements,
      text: {
        ...formElements.text,
        value: aiResponse?.result || "No result"
      }
    });
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    let err = initialFormErrorsState;
    const { ...requiredFormElements } = formElements;

    Object.entries(requiredFormElements).map(([fieldKey, fieldValue]) => {
      if (!fieldValue.value) {
        err = {
          ...err,
          [fieldKey]: `${fieldValue.label} ${lang?.lingo.app_required_field}`
        };
      }
    });

    const hasErrors = !Object.values(err).every(error => error === '');

    if (hasErrors) {
      setErrors({
        ...errors,
        ...err
      });
    } else {
      if (currentItem) {
        await editCampaign(currentItem?.id as string, {
          name: formElements.name.value,
          text: formElements.text.value,
          meta: {
            age_category_1: age1,
            age_category_2: age2,
            age_category_3: age3,
            gender_1: gender1,
            gender_2: gender2
          },
          scheduled: subtractTimezoneOffset(scheduledDate) as string
        }).then(() => {
          setScheduledDate(null);
          errors.scheduled = '';
          closeModal();
        });
      } else {
        await createCampaign({
          name: formElements.name.value,
          text: formElements.text.value,
          meta: {
            age_category_1: age1,
            age_category_2: age2,
            age_category_3: age3,
            gender_1: gender1,
            gender_2: gender2
          },
          scheduled: scheduledDate?.format() as string
        }).then(() => {
          setScheduledDate(null);
          errors.scheduled = '';
          closeModal();
        });
      }
    }
  };


  const [age1, setAge1] = React.useState(true);
  const handleAge1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (age3 || age2 || !age1) {
      // @ts-ignore
      setAge1(event.target.checked);
    }
  };
  const [age2, setAge2] = React.useState(true);
  const handleAge2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (age1 || age3 || !age2) {
      // @ts-ignore
      setAge2(event.target.checked);
    }
  };
  const [age3, setAge3] = React.useState(true);
  const handleAge3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (age1 || age2 || !age3) {
      // @ts-ignore
      setAge3(event.target.checked);
    }
  };
  const [gender1, setGender1] = React.useState(true);
  const handleGender1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (gender2 || !gender1) {
      // @ts-ignore
      setGender1(event.target.checked);
    }
  };
  const [gender2, setGender2] = React.useState(true);
  const handleGender2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (gender1 || !gender2) {
      // @ts-ignore
      setGender2(event.target.checked);
    }
  };


  // @ts-ignore
  return (
    <Modal
      open={!!openModalCampaign}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{px: {lg: 20, md: 10, xs: 2}}} component="div" className={styles.modalContainer}>
        <Box component="div" className={styles.createShopTitle}>
          {currentItem
            ? 'Редактирование рассылки'
            : 'Создание Telegram рассылки'}
        </Box>

        <Grid container spacing={5}>
            <Grid item md={8} xs={12}>
              <InputDefault
                label={formElements.name.label}
                type="text"
                helperText={errors.name ? errors.name : formElements.name.helper}
                error={!!errors.name}
                onChange={handleChange}
                name="name"
                value={formElements.name.value}
              />
            </Grid>
            <Grid item md={4} xs={12}>
            <Box component="div" className={styles.dateContainer}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                label={formElements.scheduled.label}
                value={scheduledDate}
                onChange={handleDateChange}
                format="L HH:mm"
                ampm={false}
                className={styles.datePicker}
                minDate={minDateTime}
                maxDate={maxDateTime}
              />
              {errors.scheduled && scheduledDate == null ? (
                <Box component="div" className={styles.textRequired}>
                  {lang?.lingo.app_cmpgn_one_date}{' '}
                  {lang?.lingo.app_required_field}
                </Box>
              ) : null}
            </LocalizationProvider>
          </Box>
            </Grid>
            <Grid item md={8} xs={12}>
              <InputDefault
                label={formElements.text.label}
                type="text"
                rows={2}
                helperText={errors.text ? errors.text : formElements.text.helper}
                error={!!errors.text}
                onChange={handleChange}
                name="text"
                multiline={true}
                value={formElements.text.value}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Box className={styles.cardAI}>
                <InputDefault
                label={"Команда"}
                type="text"
                helperText={"Это ИИ помощник, который сгенерирует текст для вас. Введите команду и нажмите кнопку 'Сгенерировать текст'. "}
                onChange={handlePromptChange}
                name="aiprompt"
                value={prompt}
              />
              <ButtonDefault className={styles.aiButton} variant="contained" size="large" onClick={handleUseAIAssistant}>
                {AILoading ? <CircularProgress size={20}/> : 'Сгенерировать текст'}
              </ButtonDefault>
              </Box>

            </Grid>
            <Grid item md={4} xs={12}>
              Возраст
              <Box className={styles.title}>
                  <Checkbox
                    checked={age1}
                    onChange={handleAge1}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                 {"меньше 20"}
              </Box>
              <Box className={styles.title}>
                  <Checkbox
                    checked={age2}
                    onChange={handleAge2}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                {"20-45"}
              </Box>
              <Box className={styles.title}>
                  <Checkbox
                    checked={age3}
                    onChange={handleAge3}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                {"больше 45"}
              </Box>

            </Grid>
            <Grid item md={4} xs={12}>
              Пол
              <Box className={styles.title}>
                  <Checkbox
                    checked={gender1}
                    onChange={handleGender1}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                 {"👩 Женский"}
              </Box>
              <Box className={styles.title}>
                  <Checkbox
                    checked={gender2}
                    onChange={handleGender2}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                {"👨 Мужской"}
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
          <Box component="span" className={styles.textWarning}>
            {lang?.lingo.app_cmpgn_warning}
          </Box>

            <Box component="div" className={styles.buttonContainer}>
              <ButtonDefault variant="contained" size="large" type="submit" onClick={onSubmit}>
                {currentItem
                  ? `${lang?.lingo.app_button_edit}`
                  : `${lang?.lingo.app_cmpgn_button_create_cmpgn}`}
              </ButtonDefault>
              <ButtonDefault
                variant="contained"
                size="large"
                color="salad"
                onClick={() => {
                  errors.scheduled = '';
                  errors.name = '';
                  errors.text = '';
                  setScheduledDate(null);
                  closeModal();
                }}
              >
                {lang?.lingo.app_button_cancel}
              </ButtonDefault>
            </Box>
            </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
