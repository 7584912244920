import { ILanguageProfile } from 'contexts/auth/auth.types';

export const getEmptyStateTransactions = (lang: ILanguageProfile) => {
  return {
    0: lang?.lingo.app_trns_waiting_empty_sub as string,
    1: lang?.lingo.app_trns_approved_empty_sub as string
  };
};

export const emptyStateImg = {
  0: 'images/transactionList.svg' as string,
  1: 'images/transactionListApp.svg' as string
};
