import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card } from '@mui/material';

import { PageLayout } from 'components/page-layout';
import { AuthContext } from 'contexts/auth';
import { IShop } from 'utils/auth-service.types';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';
import { ButtonDefault } from 'components/ButtonDefault';
import { HeaderWithArrow } from 'components/HeaderWithArrow';

import { ModalDeleteSale } from './ModalDeleteSale';

import { Trash } from '../../../../../public/images/newIcon/Trash';

import styles from './OneSale.module.scss';

export const OneSale = (): JSX.Element => {
  const [shop, setShop] = useState<IShop | null>(null);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

  const { sales, lang, getOneShop, deleteSale } = useContext(AuthContext);

  const { shop_id, key } = useParams();

  useEffect(() => {
    getInfoShop();
  }, []);

  const getInfoShop = async () => {
    const data = await getOneShop(shop_id as string);
    setShop(data);
  };

  const handleDeleteSale = async (key: string) => {
    await deleteSale(shop_id as string, key);
    getInfoShop();
  };

  return sales ? (
    <>
      <SeoMetaTitles title="Shop | QCUP.ME" />
      <PageLayout
        heading={
          <HeaderWithArrow name={sales?.[key as string]?.discount_message} />
        }
      >
        <Card className={styles.basicCardSale}>
          <Box component="div" className={styles.basicContainer}>
            <Box component="span" className={styles.title}>
              {lang?.lingo.app_prmo_name}
            </Box>
            <Box component="span" className={styles.infoSale}>
              {sales?.[key as string]?.discount_message}
            </Box>
          </Box>
          <Box component="div" className={styles.basicContainer}>
            <Box component="span" className={styles.title}>
              {lang?.lingo.app_prmo_points}
            </Box>
            <Box component="span" className={styles.infoSale}>
              {sales?.[key as string]?.sale_limit}
            </Box>
          </Box>
        </Card>

        <Box component="div" className={styles.buttonContainer}>
          <ButtonDefault
            color="salad"
            type="button"
            variant="contained"
            disabled
          >
            {lang?.lingo.app_prmo_edit_title}
          </ButtonDefault>
          <ButtonDefault
            color="red"
            type="button"
            variant="contained"
            onClick={() => setOpenModalDelete(true)}
          >
            <Box component="div" className={styles.buttonDeleteSale}>
              <Trash fill="#e50045" width="16px" height="16px" />
              <Box component="span">{lang?.lingo.app_prmo_delete_one}</Box>
            </Box>
          </ButtonDefault>
        </Box>

        {openModalDelete ? (
          <ModalDeleteSale
            lang={lang}
            shop={shop}
            sales={sales}
            handleDeleteSale={handleDeleteSale}
            setOpenModalDelete={setOpenModalDelete}
          />
        ) : null}
      </PageLayout>
    </>
  ) : (
    <></>
  );
};
