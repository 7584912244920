export const initialFormElementsState = {
  name: {
    label: 'Name',
    helper: 'Please enter your name',
    value: ''
  },
  email: {
    label: 'Email',
    helper: 'Please enter your email',
    value: ''
  },
  password: {
    label: 'Password',
    helper: 'Please enter your password',
    value: ''
  },
  rePassword: {
    label: 'Confirm Password',
    helper: 'Please confirm your password',
    value: ''
  },
  b2b_name: {
    label: 'B2B Name',
    helper: 'Please enter your B2B Name',
    value: ''
  }
};

export const initialFormErrorsState = {
  name: '',
  email: '',
  password: '',
  rePassword: '',
  b2b_name: ''
};
