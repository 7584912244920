import React, { useContext, useEffect, useState } from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper
} from '@mui/material';

import { PageLayout } from 'components/page-layout';
import { AuthContext } from 'contexts/auth';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';
import { CategoriesPage } from 'pages/CategoriesPage';

import { ProfileInfo } from './components/ProfileInfo';
import { ProfileTariff } from './components/ProfileTariff';
import { ProfileStatusEnum } from './ProfilePage.types';
import { SettingsProfileForm } from './components/ProfileInfo/ModalProfile/ModalProfile.types';
import { getInitialFormElementsState } from './components/ProfileInfo/ModalProfile/constants';

import { ShopsPage } from '../ShopsPage';
import { Logout } from '../../../public/images/newIcon/logout';

import styles from './ProfilePage.module.scss';

export const ProfilePage = (): JSX.Element => {
  const { user, lang, onLogout, getUser, getLanguage } =
    useContext(AuthContext);

  const [statusButton, setStatusButton] = useState<number>(0);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [formElements, setFormElements] = useState<SettingsProfileForm>(
    getInitialFormElementsState(lang)
  );

  useEffect(() => {
    getUser().then(() => {
      getLanguage();
    });
  }, []);

  const closeModal = async () => {
    setFormElements({
      name: { ...getInitialFormElementsState(lang)?.name, value: '' },
      b2b_name: { ...getInitialFormElementsState(lang)?.b2b_name, value: '' },
      email: { ...getInitialFormElementsState(lang)?.email, value: '' },
      lang: { ...getInitialFormElementsState(lang)?.lang, value: '' }
    });

    setOpenModalEdit(false);
    await getUser();
  };

  const headingContainer = (
    <Box component="div" className={styles.headingContainer}>
      <Box component="p" className={styles.profileTitle}>
        {lang?.lingo.app_prfl_title}
      </Box>
      <Box
        component="div"
        className={styles.logoutContainer}
        onClick={() => onLogout()}
      >
        <Logout width="14px" height="14px" fill="#e50045" />
        <Box component="div" className={styles.logoutText}>
          {lang?.lingo.app_button_quit}
        </Box>
      </Box>
    </Box>
  );

  return user ? (
    <>
      <SeoMetaTitles title={`${lang?.lingo.app_prfl_title} | QCUP.ME`} />
      <PageLayout heading={headingContainer}>
        <Box sx={{ width: '100%' }}>
          <Paper elevation={3} sx={{ width: '100%', boxShadow: 'none' }}>
            <BottomNavigation
              showLabels
              value={statusButton}
              onChange={(_, newValue) => {
                setStatusButton(newValue);
              }}
              sx={{ height: '40px', width: '100%' }}
            >
              <BottomNavigationAction
                key={ProfileStatusEnum[0]}
                label={lang?.lingo.app_prfl_personal_info_title}
                className={styles.statusButton}
              />
              {user.role != 'owner' ? (
                <></>
              ) : (
                <BottomNavigationAction
                  key={ProfileStatusEnum[1]}
                  label="Магазины"
                  className={styles.statusButton}
                />
              )}
              {user.role != 'owner' ? (
                <></>
              ) : (
                <BottomNavigationAction
                  key={ProfileStatusEnum[2]}
                  label="Категории"
                  className={styles.statusButton}
                />
              )}
              {/* {user.role != "owner" ? <></>: <BottomNavigationAction*/}
              {/*  key={ProfileStatusEnum[1]}*/}
              {/*  label={lang?.lingo.app_prfl_tariff}*/}
              {/*  className={styles.statusButton}*/}
              {/*/>} */}
            </BottomNavigation>
          </Paper>
        </Box>
        <Box component="div" className={styles.profileContainer}>
          {statusButton === 0 ? (
            <ProfileInfo
              lang={lang}
              user={user}
              getUser={getUser}
              onLogout={onLogout}
              closeModal={closeModal}
              openModalEdit={openModalEdit}
              setOpenModalEdit={setOpenModalEdit}
              setFormElements={setFormElements}
              formElements={formElements}
            />
          ) : null}

          {/*{statusButton === 2 ? (*/}
          {/*  <ProfileTariff getUser={getUser} lang={lang} user={user} />*/}
          {/*) : null}*/}

          {statusButton === 1 ? <ShopsPage /> : null}
          {statusButton === 2 ? <CategoriesPage /> : null}
        </Box>
      </PageLayout>
    </>
  ) : (
    <></>
  );
};
