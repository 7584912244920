import React, {
  FormEvent,
  useState,
  useContext,
  useEffect,
  useRef
} from 'react';
import { Box, MenuItem, Modal, Stack, TextField } from '@mui/material';

import { AuthContext } from 'contexts/auth';

import {
  IModalProfileProps,
  SettingsProfileForm,
  SettingsProfileFormErrors
} from './ModalProfile.types';
import { initialFormErrorsState, language } from './constants';
import { mapEditFormProfile } from './utils';

import { InputDefault } from 'components/input-default';
import { ButtonDefault } from 'components/ButtonDefault';

import styles from './ModalProfile.module.scss';

export const ModalProfile = ({
  user,
  lang,
  openModalEdit,
  formElements,
  closeModal,
  setFormElements
}: IModalProfileProps): JSX.Element => {
  const { editProfile, getLanguage } = useContext(AuthContext);

  const [errors, setErrors] = useState<SettingsProfileFormErrors>(
    initialFormErrorsState
  );

  const prevLang = useRef(user.lang);

  useEffect(() => {
    if (user) {
      setFormElements(mapEditFormProfile(user, lang));
    }
  }, [user, lang]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [event.target.name]: '' });
    setFormElements({
      ...formElements,
      [event.target.name]: {
        ...formElements[event.target.name as keyof SettingsProfileForm],
        value: event.target.value
      }
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    let err = { ...initialFormErrorsState };

    Object.entries(formElements).map(([fieldKey, fieldValue]) => {
      if (!fieldValue.value) {
        err = {
          ...err,
          [fieldKey]: `${fieldValue.label} ${lang?.lingo.app_required_field}`
        };
      }
    });

    const hasErrors = !Object.values(err).every(error => !error);

    if (hasErrors) {
      setErrors({
        ...errors,
        ...err
      });
    } else {
      editProfile({
        name: formElements.name.value as string,
        b2b_name: formElements.b2b_name.value as string,
        lang: formElements.lang.value as string
      }).then(() => {
        closeModal();
        setErrors(initialFormErrorsState);

        if (prevLang.current !== formElements.lang.value) {
          getLanguage();
          prevLang.current = formElements.lang.value as string;
        }
      });
    }
  };

  return (
    <Modal
      open={!!openModalEdit}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="div" className={styles.modalContainer}>
        <Box component="div" className={styles.createSaleTitle}>
          {lang?.lingo.app_edit_personal_info_title}
        </Box>
        {user ? (
          <Stack
            spacing={3}
            component="form"
            autoComplete="off"
            onSubmit={onSubmit}
          >
            <InputDefault
              label={formElements.name.label}
              type="text"
              helperText={errors.name ? errors.name : ''}
              error={!!errors.name}
              onChange={handleChange}
              name="name"
              value={formElements.name.value}
            />

            <InputDefault
              label={formElements.email.label}
              type="string"
              // helperText={errors.email ? errors.email : ''}
              // error={!!errors.email}
              onChange={handleChange}
              name="email"
              disabled
              value={user?.email}
            />

            <InputDefault
              label={formElements.b2b_name.label}
              type="text"
              helperText={errors.b2b_name ? errors.b2b_name : ''}
              error={!!errors.b2b_name}
              onChange={handleChange}
              name="b2b_name"
              value={formElements?.b2b_name.value}
            />

            <TextField
              select
              variant="standard"
              label={formElements.lang.label}
              defaultValue={formElements.lang.value}
              helperText={errors.lang ? errors.lang : ''}
              name="lang"
              onChange={handleChange}
            >
              {language.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <Box component="div" className={styles.buttonContainer}>
              <ButtonDefault type="submit" variant="contained" size="large">
                {lang?.lingo.app_button_save}
              </ButtonDefault>
              <ButtonDefault
                variant="contained"
                size="large"
                color="salad"
                onClick={() => {
                  closeModal();
                  setErrors(initialFormErrorsState);
                }}
              >
                {lang?.lingo.app_button_cancel}
              </ButtonDefault>
            </Box>
          </Stack>
        ) : null}
      </Box>
    </Modal>
  );
};
