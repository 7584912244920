import dayjs from 'dayjs';

export const formatTime = (created: string) => {
  const serverDate = dayjs(created);
  const currentTime = dayjs();
  const timezoneOffset = currentTime.utcOffset() / 60;

  const localServerDate = serverDate.add(timezoneOffset, 'hour');
  const diffInMinutes = currentTime.diff(localServerDate, 'minutes');
  const diffInHours = currentTime.diff(localServerDate, 'hours');

  if (diffInMinutes < 1) {
    return 'сейчас';
  }
  if (diffInMinutes < 2) {
    return 'минуту назад';
  }

  if (diffInMinutes < 60) {
    return `${diffInMinutes} минут назад`;
  }

  if (diffInHours < 24) {
    return `${diffInHours} часа назад`;
  }

  if (localServerDate.isSame(currentTime, 'день')) {
    return localServerDate.format('HH:mm');
  }

  if (localServerDate.isSame(currentTime.subtract(1, 'день'), 'день')) {
    return `Вчера, ${localServerDate.format('HH:mm')}`;
  }

  return localServerDate.format('DD.MM HH:mm');
};
