import React from 'react';

export const Mail = ({ fill }: { fill: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.75C0 2.35218 0.158035 1.97064 0.43934 1.68934C0.720644 1.40804 1.10218 1.25 1.5 1.25H12.5C12.8978 1.25 13.2794 1.40804 13.5607 1.68934C13.842 1.97064 14 2.35218 14 2.75V3.092L7.383 7.504C7.26749 7.57351 7.13479 7.6092 7 7.607C6.86521 7.6092 6.73251 7.57351 6.617 7.504L0 3.092V2.75ZM0 4.594V11.25C0 11.6478 0.158035 12.0294 0.43934 12.3107C0.720644 12.592 1.10218 12.75 1.5 12.75H12.5C12.8978 12.75 13.2794 12.592 13.5607 12.3107C13.842 12.0294 14 11.6478 14 11.25V4.594L8.073 8.546L8.068 8.55C7.74893 8.75291 7.37812 8.8595 7 8.857C6.625 8.857 6.247 8.755 5.932 8.55L5.927 8.546L0 4.594Z"
      fill={fill}
    />
  </svg>
);
