import React, { useMemo, useState } from 'react';

import { AlertContext } from './alert-context';
import { Alert, Snackbar } from '@mui/material';

export type AlertType = 'error' | 'success' | 'info' | 'warning';

export const AlertProvider = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const [message, setMessage] = useState<string | null>(null);
  const [type, setType] = useState<AlertType>('error');

  const value = useMemo(
    () => ({
      message,
      type,
      setMessage,
      setType
    }),
    [message, type]
  );

  return (
    <AlertContext.Provider value={value}>
      {props.children}
      <Snackbar
        open={!!message}
        onClose={() => setMessage(null)}
        autoHideDuration={5000}
        sx={{ bottom: { xs: '85px', md: '5px' } }}
      >
        <Alert
          severity={type}
          sx={{ width: '100%' }}
          onClose={() => setMessage(null)}
        >
          {message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};
