import React, { FormEvent, useState, useContext } from 'react';
import { Box, Modal, Stack } from '@mui/material';

import { AuthContext } from 'contexts/auth';
import { InputDefault } from 'components/input-default';
import { ButtonDefault } from 'components/ButtonDefault';

import {
  IModalShopProps,
  SettingsForm,
  SettingsFormErrors
} from './ModalShop.types';
import { initialFormErrorsState } from './constants';

import styles from './ModalShop.module.scss';

export const ModalShop = ({
  lang,
  formElements,
  openModalShop,
  closeModal,
  setFormElements
}: IModalShopProps): JSX.Element => {
  const { createShop, getUser } = useContext(AuthContext);

  const [errors, setErrors] = useState<SettingsFormErrors>(
    initialFormErrorsState
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [event.target.name]: '' });
    setFormElements({
      ...formElements,
      [event.target.name]: {
        ...formElements[event.target.name as keyof SettingsForm],
        value: event.target.value
      }
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    let err = initialFormErrorsState;
    const { address_line_2, ...requiredFormElements } = formElements;

    Object.entries(requiredFormElements).map(([fieldKey, fieldValue]) => {
      if (!fieldValue.value) {
        err = {
          ...err,
          [fieldKey]: `${fieldValue.label} ${lang?.lingo.app_required_field}`
        };
      }
    });

    const hasErrors = !Object.values(err).every(error => error === '');

    if (hasErrors) {
      setErrors({
        ...errors,
        ...err
      });
    } else {
      createShop({
        name: formElements.name.value,
        address_line_1: formElements.address_line_1.value,
        address_line_2: formElements.address_line_2.value,
        post_code: formElements.post_code.value
      }).then(() => {
        closeModal();
        getUser();
        setErrors(initialFormErrorsState);
      });
    }
  };

  return (
    <Modal
      open={!!openModalShop}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="div" className={styles.modalContainer}>
        <Box component="div" className={styles.createShopTitle}>
          {lang?.lingo.app_shps_create_shop_title}
        </Box>
        <Stack
          spacing={3}
          component="form"
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <InputDefault
            label={formElements.name.label}
            type="text"
            helperText={errors.name ? errors.name : formElements.name.helper}
            error={!!errors.name}
            onChange={handleChange}
            name="name"
            value={formElements.name.value}
          />

          <InputDefault
            label={formElements.address_line_1.label}
            type="text"
            helperText={errors.address_line_1 ? errors.address_line_1 : ''}
            error={!!errors.address_line_1}
            onChange={handleChange}
            name="address_line_1"
            value={formElements.address_line_1.value}
          />

          <InputDefault
            label={formElements.post_code.label}
            type="text"
            helperText={errors.post_code ? errors.post_code : ''}
            error={!!errors.post_code}
            onChange={handleChange}
            name="post_code"
            value={formElements.post_code.value}
          />

          <Box component="div" className={styles.buttonContainer}>
            <ButtonDefault variant="contained" size="large" type="submit">
              {lang?.lingo.app_shps_button_add_shop}
            </ButtonDefault>
            <ButtonDefault
              variant="contained"
              size="large"
              color="salad"
              onClick={() => {
                setErrors(initialFormErrorsState);
                closeModal();
              }}
            >
              {lang?.lingo.app_button_cancel}
            </ButtonDefault>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};
