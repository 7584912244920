import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from 'contexts/auth';
import QrScanner from 'qr-scanner';
import {
  Alert,
  AlertTitle,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material';
import CameraswitchSharpIcon from '@mui/icons-material/CameraswitchSharp';

interface QrReaderProps {
  onResult: (result: QrScanner.ScanResult | string) => void;
  onError?: (error: string) => void;
}

export const QrReader = ({ onResult, onError }: QrReaderProps) => {
  const { lang } = useContext(AuthContext);

  const qrScannerRef = useRef<QrScanner | null>(null);
  const ref = useRef<HTMLVideoElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const executedRef = useRef(false);

  const [isReady, setIsReady] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isCameraErrorVisible, setIsCameraErrorVisible] =
    useState<boolean>(false);

  useEffect(() => {
    return () => {
      qrScannerRef.current?.destroy();
      qrScannerRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (executedRef.current) return;
    if (ref.current && overlayRef.current) {
      qrScannerRef.current = new QrScanner(
        ref.current,
        result => {
          qrScannerRef.current?.stop();
          onResult(result);
        },
        {
          highlightScanRegion: true,
          overlay: overlayRef.current
        }
      );

      qrScannerRef.current
        .start()
        .then(() => {
          setIsReady(true);
        })
        .catch(() => {
          setHasError(true);
          setIsCameraErrorVisible(true);
          console.error(`${lang?.lingo.app_qrreader_invld_camera}`);
        });

      executedRef.current = true;
    }
  }, []);

  const scanImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files) {
      QrScanner.scanImage(event.target.files[0])
        .then(result => {
          onResult(result);
          qrScannerRef.current?.stop();
        })
        .catch(error =>
          onError
            ? onError(`${lang?.lingo.app_qrreader_invld_code}`)
            : console.error(error || `${lang?.lingo.app_qrreader_invld_code}`)
        );
    }
  };

  return (
    <>
      {!hasError ? (
        <div
          style={{
            position: 'relative',
            borderRadius: '4px',
            maxHeight: '60vh',
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {!isReady && (
            <div
              style={{
                position: 'absolute',
                width: '100%',
                maxHeight: '60vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px'
              }}
            >
              <CircularProgress />
            </div>
          )}
          <video
            style={{ maxHeight: '60vh', borderRadius: '4px' }}
            ref={ref}
          ></video>
          <div style={{ display: isReady ? 'block' : 'none' }}>
            <div
              style={{
                outline: 'rgba(255, 255, 255, 0.7) solid 50vmax',
                borderRadius: '4px'
              }}
              ref={overlayRef}
            />
          </div>
        </div>
      ) : (
        <Stack spacing={2} direction={'column'}>
          {isCameraErrorVisible && (
            <Alert
              severity={'error'}
              onClose={() => setIsCameraErrorVisible(false)}
            >
              <AlertTitle>{lang?.lingo.app_qrreader_invld_code}</AlertTitle>
              {lang?.lingo.app_qrreader_invld_permit}
            </Alert>
          )}

          <Alert severity={'info'}>{lang?.lingo.app_qrreader_scan}</Alert>
        </Stack>
      )}
    </>
  );
};
