import React from 'react';
import { Button } from '@mui/material';

interface ButtonGoogleProps {
  children: string;
  link: string;
  disabled?: boolean;
}

export const ButtonGoogle = ({
  children,
  link,
  disabled = false
}: ButtonGoogleProps): JSX.Element => {
  return (
    <Button
      variant="outlined"
      disabled={disabled}
      href={link}
      startIcon={
        <img src="/images/newIcon/logoGoogle.svg" alt={'Google Logo'} />
      }
      sx={{
        gap: '14px',
        width: '100%',
        height: '45px',
        fontWeight: 500,
        display: 'flex',
        color: '#022024',
        fontSize: '16px',
        marginTop: '12px',
        lineHeight: '24px',
        textAlign: 'center',
        alignItems: 'center',
        textTransform: 'none',
        borderColor: '#ced7d4',
        fontFamily: 'NT Somic, sans-serif'
      }}
    >
      {children}
    </Button>
  );
};
