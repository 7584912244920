import { ILanguageProfile } from 'contexts/auth/auth.types';

export enum ContentTypesEnum {
  'Sales',
  'Users',
  'About shop'
}

export interface INavTabProps {
  content: number;
  lang: ILanguageProfile | null;
  setContent: (v: number) => void;
}
