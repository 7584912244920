import React, { useContext, useEffect } from 'react';
import { Box, Modal } from '@mui/material';

import { AuthContext } from 'contexts/auth';
import { useParams } from 'react-router-dom';
import { ButtonDefault } from 'components/ButtonDefault';

import { IModalQRProps } from './ModalQR.types';

import styles from './ModalQRStyles.module.scss';

export const ModalQR = ({ setOpenModalQR }: IModalQRProps): JSX.Element => {
  const closeModal = () => {
    setOpenModalQR(false);
  };
  const { lang, getQRCode } = useContext(AuthContext);

  const { shop_id } = useParams();

  useEffect(() => {
    getQRCode(shop_id as string);
  }, []);

  return (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.modalContainer}>
        <Box component="div" className={styles.title}>
          {lang?.lingo.app_prmo_about_shop_qr}
        </Box>
        <div id="qrImage" className={styles.qrImage}></div>

        <ButtonDefault
          color="green"
          type="button"
          variant="contained"
          id="download"
          className={styles.qrButton}
        >
          {lang?.lingo.app_prmo_about_shop_qr_dwnld}
        </ButtonDefault>
        <ButtonDefault
          color="salad"
          type="button"
          variant="contained"
          onClick={() => {
            closeModal();
            setOpenModalQR(false);
          }}
        >
          {lang?.lingo.app_button_close}
        </ButtonDefault>
      </Box>
    </Modal>
  );
};
