import { createContext } from 'react';
import { AlertType } from './alert-provider';

interface AlertContext {
  message: string | null;
  type: AlertType;
  hasError: boolean;
  setMessage: (message: string | null) => void;
  setType: (type: AlertType) => void;
  setHasError: (hasError: boolean) => void;
}

const contextDefaultValue: AlertContext = {
  message: null,
  type: 'error',
  hasError: false,
  setMessage: () => null,
  setType: () => null,
  setHasError: () => null
};

export const AlertContext = createContext(contextDefaultValue);
