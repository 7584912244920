import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Card } from '@mui/material';

import { PageLayout } from 'components/page-layout';
import { AuthContext } from 'contexts/auth';
import { ICampaigns } from 'utils/auth-service.types';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';
import { ButtonDefault } from 'components/ButtonDefault';

import { ModalCampaign } from './ModalCampaign/ModalCampaign';
import { SettingsForm } from './ModalCampaign/ModalCampaign.types';
import { getInitialFormElementsState } from './ModalCampaign/constants';
import { changeTextStatus, choseColorByStatus, formatDate } from './utils';

import { AddPlus } from '../../../public/images/newIcon/AddPlus';

import styles from './CampaignsPage.module.scss';

export const CampaignsPage = (): JSX.Element => {
  const { user, lang, getUser, getCampaigns } = useContext(AuthContext);

  const [campaigns, setCampaigns] = useState<ICampaigns[] | undefined>([]);
  const [openModalCampaign, setOpenModalCampaign] = useState<boolean>(false);
  const [formElements, setFormElements] = useState<SettingsForm>(
    getInitialFormElementsState(lang)
  );
  const campaignsLength = campaigns?.filter(item => !item.deleted).length;

  const navigate = useNavigate();

  useEffect(() => {
    getAllCampaigns();
  }, [openModalCampaign]);

  const getAllCampaigns = async () => {
    const data = await getCampaigns();
    setCampaigns(data);
    await getUser();
  };

  const closeModal = () => {
    setFormElements({
      name: { ...getInitialFormElementsState(lang).name, value: '' },
      text: { ...getInitialFormElementsState(lang).text, value: '' },
      scheduled: { ...getInitialFormElementsState(lang).scheduled, value: '' }
    });
    setOpenModalCampaign(false);
    getAllCampaigns();
  };

  const headingContainer = (
    <Box component="div" className={styles.headingContainer}>
      <Box component="div" className={styles.campaignsName}>
        {lang?.lingo.app_cmpgn_title}
      </Box>
      <Box component="div" className={styles.campaignsLength}>
        {campaignsLength && campaignsLength > 0 ? campaignsLength : 0}
      </Box>
    </Box>
  );

  return user ? (
    <>
      <SeoMetaTitles title={`${lang?.lingo.app_cmpgn_title} | QCUP.ME`} />
      <PageLayout heading={headingContainer}>
        <Box component="div" className={styles.campaignsContainer}>
          <Box
            component="div"
            className={
              campaignsLength && campaignsLength > 0 ? styles.campaigns : ''
            }
          >
            {campaignsLength && campaignsLength > 0 ? (
              campaigns.map(
                ({ id, name, text, scheduled, status, deleted }) =>
                  !deleted && (
                    <Card
                      key={id}
                      onClick={() => {
                        navigate(`/campaigns/${id}`);
                      }}
                      className={styles.campaignCard}
                    >
                      <Box component="div" className={styles.campaignContainer}>
                        <Box
                          component="div"
                          className={styles.campaignTextContainer}
                        >
                          <Box
                            component="div"
                            className={styles.campaignTextDefault}
                          >
                            {formatDate(scheduled, lang)}
                          </Box>
                          <Box component="div" className={styles.campaignName}>
                            {name}
                          </Box>
                          <Box component="div" className={styles.campaignText}>
                            {text}
                          </Box>
                          <Box
                            component="div"
                            className={`${styles.campaignTextDefault} ${
                              styles[choseColorByStatus(status)]
                            }`}
                          >
                            {changeTextStatus(status, lang)}
                          </Box>
                        </Box>
                        <img
                          src="/images/newIcon/ArrowRight.svg"
                          alt="arrow_right"
                          className={styles.arrowRight}
                        />
                      </Box>
                    </Card>
                  )
              )
            ) : (
              <Box component="div" className={styles.emptyStateBox}>
                <img
                  src="/images/noCampaign.svg"
                  alt="no_campaigns"
                  className={styles.emptyStateImage}
                />
                <Box component="div" className={styles.emptyStateTitle}>
                  {lang?.lingo.app_cmpgn_empty_list}
                </Box>
              </Box>
            )}
          </Box>

          <ButtonDefault
            color="green"
            type="button"
            variant="contained"
            disabled={!user.available_actions?.can_add_campaign}
            onClick={() => setOpenModalCampaign(true)}
            className={styles.addCampaignBtn}
          >
            <Box component="div" className={styles.addCampaign}>
              <AddPlus
                fill={
                  user.available_actions?.can_add_campaign ? '#fff' : '#B8B8B8'
                }
              />
              <Box component="span">
                {lang?.lingo.app_cmpgn_button_create_cmpgn}
              </Box>
            </Box>
          </ButtonDefault>
          {!user.available_actions?.can_add_campaign ? (
            <Box component="div" className={styles.toCreateMoreCampaigns}>
              {lang?.lingo.app_cmpgn_contact_us1}{' '}
              <a href="mailto:contact@qcup.me">{lang?.lingo.app_contact_us2}</a>
            </Box>
          ) : null}
        </Box>

        <ModalCampaign
          lang={lang}
          formElements={formElements}
          openModalCampaign={openModalCampaign}
          closeModal={closeModal}
          setFormElements={setFormElements}
        />
      </PageLayout>
    </>
  ) : (
    <></>
  );
};
