import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Link,
  Button,
  Stack,
  useMediaQuery,
  FormControlLabel,
  Checkbox
} from '@mui/material';

import { PageLayout } from 'components/page-layout';
import { InputPassword } from 'components/input-password';
import { InputDefault } from 'components/input-default';
import { FormElement } from 'common-types';
import { AuthContext } from 'contexts/auth';
import { ButtonDefault } from 'components/ButtonDefault';
import { ButtonGoogle } from 'components/ButtonGoogle';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';

import { initialFormElementsState, initialFormErrorsState } from './constants';
import { SignUpForm } from './sign-type.types';

import styles from './sign-up.module.scss';

export const SignUp = (): JSX.Element => {
  const [formElements, setFormElements] = useState<SignUpForm<FormElement>>(
    initialFormElementsState
  );
  const [errors, setErrors] = useState<SignUpForm<string>>(
    initialFormErrorsState
  );

  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleChangeTerms = () => {
    setTermsAccepted(!termsAccepted);
  };

  const { onSignUp } = useContext(AuthContext);

  const isXs = useMediaQuery('(max-width:600px)');

  const handleChange =
    (prop: keyof SignUpForm<FormElement>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrors({ ...errors, [prop]: '' });
      setFormElements({
        ...formElements,
        [prop]: { ...formElements[prop], value: event.target.value }
      });
    };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let err = initialFormErrorsState;

    Object.values(formElements).map((input, index) => {
      if (!input.value) {
        err = {
          ...err,
          [Object.keys(formElements)[index]]: `${input.label} is required!`
        };
      }
    });

    if (
      !err.password &&
      !err.rePassword &&
      formElements.password.value !== formElements.rePassword.value
    ) {
      err = {
        ...err,
        rePassword: `Passwords must be similar!`
      };
    }

    const hasErrors = !Object.values(err).every(x => x === '');

    if (hasErrors) {
      setErrors({
        ...errors,
        ...err
      });
    } else {
      onSignUp({
        name: formElements.name.value,
        email: formElements.email.value,
        password: formElements.password.value,
        b2b_name: formElements.b2b_name.value
      });
    }
  };

  return (
    <>
      <SeoMetaTitles title="Sign Up | QCUP.ME" />
      <PageLayout heading="Create Account" login>
        <Stack
          spacing={3}
          marginTop="16px"
          component="form"
          autoComplete="off"
          alignItems="center"
          width={isXs ? '100%' : '500px'}
          onSubmit={onSubmit}
          className={styles.formSignUp}
        >
          <InputDefault
            label={formElements.name.label}
            type="text"
            helperText={errors.name ? errors.name : ''}
            fullWidth
            error={!!errors.name}
            onChange={handleChange('name')}
          />
          <InputDefault
            label={formElements.email.label}
            type="email"
            helperText={errors.email ? errors.email : ''}
            fullWidth
            error={!!errors.email}
            onChange={handleChange('email')}
          />
          <InputDefault
            label={formElements.b2b_name.label}
            type="companyName"
            helperText={errors.b2b_name ? errors.b2b_name : ''}
            fullWidth
            error={!!errors.b2b_name}
            onChange={handleChange('b2b_name')}
          />
          <InputPassword
            label={formElements.password.label}
            helperText={errors.password ? errors.password : ''}
            fullWidth
            error={!!errors.password}
            onChange={handleChange('password')}
          />
          <InputPassword
            label={formElements.rePassword.label}
            helperText={errors.rePassword ? errors.rePassword : ''}
            fullWidth
            error={!!errors.rePassword}
            onChange={handleChange('rePassword')}
          />
          <FormControlLabel
            className={styles.formControlLabel}
            control={
              <Checkbox
                required
                checked={termsAccepted}
                onChange={handleChangeTerms}
                className={styles.checkboxSignUp}
              />
            }
            label={
              <p className={styles.textCheckbox}>
                I agree to the{' '}
                <a
                  href="https://qcup.me/privacy-policy"
                  rel="noreferrer noopener"
                  target="_blank"
                  style={{ textDecoration: 'none', color: '#054B54' }}
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  href="https://qcup.me/gdpr"
                  rel="noreferrer noopener"
                  target="_blank"
                  style={{ textDecoration: 'none', color: '#054B54' }}
                >
                  Terms of Service
                </a>
              </p>
            }
          />
          <Box component="div" className={styles.buttonContainer}>
            <ButtonDefault
              size="large"
              type="submit"
              color="green"
              variant="contained"
              disabled={!termsAccepted}
            >
              Create Account
            </ButtonDefault>
            <Box component="div" className={styles.lineContainer}>
              <Box component="div" className={styles.lineBox}></Box>
              <Box component="p" className={styles.lineText}>
                or
              </Box>
              <Box component="div" className={styles.lineBox}></Box>
            </Box>
            <ButtonGoogle link="/api/oauth/signup" disabled={!termsAccepted}>
              Sign up with Google
            </ButtonGoogle>
          </Box>
        </Stack>
        <Box component="div" className={styles.linkCreateAccountBox}>
          Already have an account?{' '}
          <Link
            component={RouterLink}
            to="/login"
            className={styles.linkCreateAccount}
          >
            Log in
          </Link>
        </Box>
      </PageLayout>
    </>
  );
};
