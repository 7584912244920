import { ILanguageProfile } from 'contexts/auth/auth.types';

export const getInitialFormEditElementsState = (
  lang: ILanguageProfile | null
) => {
  return {
    name: {
      label: `${lang?.lingo.app_ctgr_title_input}`,
      helper: `${lang?.lingo.app_ctgr_title_input_help}`,
      value: ''
    },
    description: {
      label: `${lang?.lingo.app_ctgr_desc_input}`,
      helper: '',
      value: ''
    },
    emodji: {
      label: `${lang?.lingo.app_ctgr_emoji_input}`,
      helper: `${lang?.lingo.app_ctgr_emoji_input_help}`,
      value: null
    }
  };
};

export const initialFormEditErrorsState = {
  name: '',
  description: '',
  emodji: ''
};
