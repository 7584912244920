import React from 'react';

export const AddPlus = ({ fill }: { fill: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.88889 0.888889C8.88889 0.397969 8.49092 0 8 0C7.50908 0 7.11111 0.397969 7.11111 0.888889V7.11111L0.888889 7.11111C0.397969 7.11111 0 7.50908 0 8C0 8.49092 0.397969 8.88889 0.888889 8.88889L7.11111 8.88889V15.1111C7.11111 15.602 7.50908 16 8 16C8.49092 16 8.88889 15.602 8.88889 15.1111V8.88889L15.1111 8.88889C15.602 8.88889 16 8.49092 16 8C16 7.50908 15.602 7.11111 15.1111 7.11111L8.88889 7.11111V0.888889Z"
      fill={fill}
    />
  </svg>
);
