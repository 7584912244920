import React from 'react';

import { SeoMetaTitles } from 'utils/SeoMetaTitles';
import { PageLayout } from 'components/page-layout';

export const NotFound = (): JSX.Element => {
  return (
    <>
      <SeoMetaTitles title="QCUP.ME" />
      <PageLayout heading="Not Found">Ooops...</PageLayout>
    </>
  );
};
