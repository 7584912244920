import React, { FormEvent, useState, useContext } from 'react';
import { Box, Modal, Stack } from '@mui/material';

import { AuthContext } from 'contexts/auth';
import { InputDefault } from 'components/input-default';
import { ButtonDefault } from 'components/ButtonDefault';

import {
  IModalEmployeeProps,
  SettingsEmployeeForm,
  SettingsEmployeeFormErrors
} from './ModalEmployee.types';
import { initialFormErrorsState } from './constants';

import styles from './ModalEmployee.module.scss';

export const ModalEmployee = ({
  shop,
  lang,
  formElements,
  closeModal,
  setFormElements
}: IModalEmployeeProps): JSX.Element => {
  const { createEmployee } = useContext(AuthContext);

  const [errors, setErrors] = useState<SettingsEmployeeFormErrors>(
    initialFormErrorsState
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [event.target.name]: '' });
    setFormElements({
      ...formElements,
      [event.target.name]: {
        ...formElements[event.target.name as keyof SettingsEmployeeForm],
        value: event.target.value
      }
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    let err = { ...initialFormErrorsState };

    Object.entries(formElements).map(([fieldKey, fieldValue]) => {
      if (!fieldValue.value) {
        err = {
          ...err,
          [fieldKey]: `${fieldValue.label} ${lang?.lingo.app_required_field}`
        };
      }
    });

    const hasErrors = !Object.values(err).every(error => !error);

    if (hasErrors) {
      setErrors({
        ...errors,
        ...err
      });
    } else {
      createEmployee({
        name: formElements.name.value as string,
        email: formElements.email.value as string,
        password: formElements.password.value as string,
        shop_id: shop?.id as string
      }).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="div" className={styles.modalContainer}>
        <Box component="div" className={styles.createSaleTitle}>
          {lang?.lingo.app_users_modal_title}
        </Box>
        {shop ? (
          <Box component="div" className={styles.nameContainer}>
            {shop.name}
          </Box>
        ) : null}
        <Stack
          spacing={3}
          component="form"
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <InputDefault
            label={formElements.name.label}
            type="text"
            helperText={errors.name ? errors.name : ''}
            name="name"
            error={!!errors.name}
            onChange={handleChange}
            value={formElements.name.value as string}
          />
          <InputDefault
            label={formElements.email.label}
            type="email"
            helperText={errors.email ? errors.email : ''}
            name="email"
            error={!!errors.email}
            onChange={handleChange}
            value={formElements.email.value as string}
          />
          <InputDefault
            label={formElements.password.label}
            type="text"
            helperText={errors.password ? errors.password : ''}
            name="password"
            error={!!errors.password}
            onChange={handleChange}
            value={formElements.password.value as string}
          />

          <Box component="div" className={styles.buttonContainer}>
            <ButtonDefault variant="contained" size="large" type="submit">
              {lang?.lingo.app_users_add_user}
            </ButtonDefault>
            <ButtonDefault
              variant="contained"
              size="large"
              color="salad"
              onClick={() => {
                closeModal();
              }}
            >
              {lang?.lingo.app_button_cancel}
            </ButtonDefault>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};
