import React from 'react';

export const PaperPlaneIcon = ({
  fill,
  width,
  height
}: {
  fill: string;
  width: string;
  height: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.821 0.0979035C12.1108 -0.00768648 12.4247 -0.0283666 12.7258 0.0382937C13.0269 0.104954 13.3027 0.256185 13.5209 0.474212C13.739 0.692238 13.8904 0.968001 13.9572 1.26909C14.024 1.57018 14.0034 1.88408 13.898 2.1739L10.324 12.8859C10.237 13.1491 10.084 13.3857 9.87949 13.5728C9.67498 13.76 9.42587 13.8915 9.15599 13.9549C8.88674 14.0205 8.60507 14.015 8.33861 13.9389C8.07214 13.8628 7.83002 13.7188 7.63599 13.5209L5.71799 11.6119L3.70399 12.6539C3.62679 12.6939 3.54048 12.7131 3.45358 12.7096C3.36669 12.7061 3.28222 12.68 3.2085 12.6338C3.13479 12.5877 3.07439 12.5231 3.03326 12.4465C2.99214 12.3699 2.97171 12.2838 2.97399 12.1969L3.05699 9.0129L10.102 3.8959C10.1684 3.84764 10.2247 3.78677 10.2677 3.71675C10.3106 3.64673 10.3393 3.56895 10.3521 3.48784C10.365 3.40673 10.3618 3.32387 10.3426 3.24402C10.3234 3.16416 10.2887 3.08885 10.2405 3.0224C10.1922 2.95595 10.1314 2.89966 10.0613 2.85674C9.99132 2.81382 9.91354 2.78511 9.83242 2.77225C9.75131 2.75938 9.66846 2.76263 9.5886 2.78178C9.50874 2.80094 9.43344 2.83564 9.36699 2.8839L2.20299 8.0879L0.472989 6.3579C0.286542 6.1714 0.149083 5.94171 0.0728421 5.68926C-0.00339916 5.43681 -0.016059 5.16943 0.035989 4.9109C0.0883211 4.62827 0.214912 4.36464 0.402772 4.14708C0.590632 3.92952 0.832994 3.76587 1.10499 3.6729H1.10799L11.82 0.0969036L11.821 0.0979035Z"
      fill={fill}
    />
  </svg>
);
