import React from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper
} from '@mui/material';

import { ContentTypesEnum, INavTabProps } from './NavTabs.types';

import styles from './NavTabs.module.scss';

export const NavTabs = ({ lang, content, setContent }: INavTabProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={3} sx={{ width: '100%', boxShadow: 'none' }}>
        <BottomNavigation
          showLabels
          value={content}
          onChange={(_, newValue) => {
            setContent(newValue);
          }}
          sx={{ height: '40px', width: '100%' }}
        >
          <BottomNavigationAction
            key={ContentTypesEnum[0]}
            label={lang?.lingo.app_prmo_about_shop}
            className={styles.statusButton}
          />
          <BottomNavigationAction
            key={ContentTypesEnum[1]}
            label={lang?.lingo.app_users}
            className={styles.statusButton}
          />
          <BottomNavigationAction
            key={ContentTypesEnum[2]}
            label={lang?.lingo.app_prmo_title}
            className={styles.statusButton}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};
