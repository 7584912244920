import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Layout } from 'components/layout';
import { Login } from 'pages/login';
import { NotFound } from 'pages/not-found';
import { SignUp } from 'pages/sign-up';
import { QRScanB2B } from 'pages/QRScanB2B';
import { AuthContext } from 'contexts/auth';
import { AuthGuard } from 'components/auth-guard';
import { Transactions } from 'pages/transactions';
import { ShopsPage } from 'pages/ShopsPage';
import { ShopPage } from 'pages/ShopPage';
import { ProfilePage } from 'pages/ProfilePage';
import { CampaignsPage } from 'pages/CampaignsPage';
import { OneSale } from 'pages/ShopPage/components/OneSale';
import { ProcessPage } from 'pages/ProccessPage';
import { OneCampaignPage } from 'pages/CampaignsPage/OneCampaignPage';
import { CategoryPage } from 'pages/CategoryPage';
import { Analytics } from './pages/analytics';
import { ChangePassword } from './pages/ChangePassword/changePassword';

export const App = (): JSX.Element => {
  const { user, isAuthenticated, getUser, getLanguage } =
    useContext(AuthContext);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      getUser().then(() => getLanguage());
    }
  }, [localStorage.getItem('token')]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* <Route path="qr-scan" element={<QrScan />} /> */}
        <Route
          path="/"
          element={
            <AuthGuard
              isAllowed={!localStorage.getItem('token')}
              redirectPath="/"
            />
          }
        >
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
        <Route
          path="/"
          element={<AuthGuard isAllowed={!!localStorage.getItem('token')} />}
        >
          <Route index element={<QRScanB2B />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="shops" element={<ShopsPage />} />
          <Route path="shops/:shop_id" element={<ShopPage />} />
          <Route path="shops/:shop_id/sales/:key" element={<OneSale />} />
          <Route path="categories/:category_id" element={<CategoryPage />} />

          {/*{user?.paid && <Route path="campaigns" element={<CampaignsPage />} />}*/}
          {/*{user?.paid && (*/}
          {/*  <Route*/}
          {/*    path="campaigns/:campaign_id"*/}
          {/*    element={<OneCampaignPage />}*/}
          {/*  />*/}
          {/*)}*/}
          <Route path="campaigns" element={<CampaignsPage />} />
          <Route path="campaigns/:campaign_id" element={<OneCampaignPage />} />
          <Route path="processing" element={<ProcessPage />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>

      {/* {user && user?.trial.days_left < 40 ? (
        <Route path="/" element={<AuthGuard isAllowed={localStorage.getItem('token')} />}>
          <Route path="expired-trial-period" element={<TariffStab />} />
        </Route>
      ) : ( */}
      <Route path="/" element={<Layout />}>
        <Route
          path="/"
          element={<AuthGuard isAllowed={!!localStorage.getItem('token')} />}
        >
          <Route path="profile" element={<ProfilePage />} />
        </Route>
      </Route>
      {/* )} */}
    </Routes>
  );
};
