import React from 'react';

export const Instagram = ({ fill }: { fill: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.39001 0.787109C2.69956 0.787109 2.03737 1.06132 1.54906 1.54945C1.06074 2.03758 0.786276 2.69966 0.786011 3.39011V10.3341C0.786011 11.0247 1.06036 11.6871 1.5487 12.1754C2.03705 12.6638 2.69939 12.9381 3.39001 12.9381H10.334C11.0245 12.9378 11.6865 12.6634 12.1747 12.1751C12.6628 11.6867 12.937 11.0246 12.937 10.3341V3.39011C12.9367 2.69983 12.6624 2.0379 12.1743 1.5498C11.6862 1.0617 11.0243 0.787374 10.334 0.787109H3.39001ZM11.083 3.39411C11.083 3.59302 11.004 3.78379 10.8633 3.92444C10.7227 4.06509 10.5319 4.14411 10.333 4.14411C10.1341 4.14411 9.94333 4.06509 9.80268 3.92444C9.66203 3.78379 9.58301 3.59302 9.58301 3.39411C9.58301 3.1952 9.66203 3.00443 9.80268 2.86378C9.94333 2.72313 10.1341 2.64411 10.333 2.64411C10.5319 2.64411 10.7227 2.72313 10.8633 2.86378C11.004 3.00443 11.083 3.1952 11.083 3.39411ZM6.86301 4.78211C6.31136 4.78211 5.7823 5.00125 5.39223 5.39133C5.00215 5.7814 4.78301 6.31046 4.78301 6.86211C4.78301 7.41376 5.00215 7.94282 5.39223 8.33289C5.7823 8.72297 6.31136 8.94211 6.86301 8.94211C7.41466 8.94211 7.94372 8.72297 8.33379 8.33289C8.72387 7.94282 8.94301 7.41376 8.94301 6.86211C8.94301 6.31046 8.72387 5.7814 8.33379 5.39133C7.94372 5.00125 7.41466 4.78211 6.86301 4.78211ZM3.78201 6.86211C3.78201 6.04524 4.10651 5.26183 4.68412 4.68422C5.26173 4.10661 6.04514 3.78211 6.86201 3.78211C7.67888 3.78211 8.46229 4.10661 9.0399 4.68422C9.61751 5.26183 9.94201 6.04524 9.94201 6.86211C9.94201 7.67898 9.61751 8.46239 9.0399 9.04C8.46229 9.61761 7.67888 9.94211 6.86201 9.94211C6.04514 9.94211 5.26173 9.61761 4.68412 9.04C4.10651 8.46239 3.78201 7.67898 3.78201 6.86211Z"
      fill={fill}
    />
  </svg>
);
