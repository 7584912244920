import React from 'react';

export const Profile = ({
  fill,
  width,
  height
}: {
  fill: string;
  width: string;
  height: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_104_839)">
      <path
        d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 4.8C14.316 4.8 16.2 6.684 16.2 9C16.2 11.316 14.316 13.2 12 13.2C9.684 13.2 7.8 11.316 7.8 9C7.8 6.684 9.684 4.8 12 4.8ZM12 21.6C9.564 21.6 6.684 20.616 4.632 18.144C6.73393 16.4949 9.32834 15.5986 12 15.5986C14.6717 15.5986 17.2661 16.4949 19.368 18.144C17.316 20.616 14.436 21.6 12 21.6Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_104_839">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
