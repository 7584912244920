import React, {useContext, useState} from 'react';
import {Box, Divider} from '@mui/material';

import { ButtonDefault } from 'components/ButtonDefault';

import { IProfileInfoProps } from './ProfileInfo.types';
import { ModalProfile } from './ModalProfile';
import { ModalContacts } from './ModalContacts';

import { languageOption } from '../../ProfilePage.utils';

import styles from './ProfileInfo.module.scss';
import {ProfileTariff} from "../ProfileTariff";
import Button from "@mui/material/Button";
import {AuthContext} from "../../../../contexts/auth";

export const ProfileInfo = ({
  user,
  lang,
  formElements,
  openModalEdit,
  closeModal,
  setFormElements,
  setOpenModalEdit,
  getUser
}: IProfileInfoProps) => {
  const [openModalContacts, setOpenModalContacts] = useState<boolean>(false);
  const { changePassword } = useContext(AuthContext);
  return (
    <Box component="div" className={styles.infoContainer}>
      <ProfileTariff getUser={getUser} lang={lang} user={user} />
      <Box component="div" className={styles.containerTitle}>
        Настройки профиля
        {/*{user?.paid*/}
        {/*  ? lang?.lingo.app_prfl_trff_name*/}
        {/*  : `${lang?.lingo.app_prfl_trial_name} (${user?.trial.days} ${lang?.lingo.app_prfl_trial_count})`}*/}
      </Box>
      <Box component="div" className={styles.basicContainer}>
        <Box component="span" className={styles.title}>
          {lang?.lingo.app_prfl_name_title}
        </Box>
        <Box component="span" className={styles.infoProfile}>
          {user.name}
        </Box>
      </Box>
      <Box component="div" className={styles.basicContainer}>
        <Box component="span" className={styles.title}>
          {lang?.lingo.app_prfl_email}
        </Box>
        <Box component="span" className={styles.infoProfile}>
          {user.email}
        </Box>
      </Box>
      <Box component="div" className={styles.basicContainer}>
        <Box component="span" className={styles.title}>
          {lang?.lingo.app_prfl_company_name_title}
        </Box>
        <Box component="span" className={styles.infoProfile}>
          {user.b2b?.name}
        </Box>
      </Box>
      <Box component="div" className={styles.basicContainer}>
        <Box component="span" className={styles.title}>
          {lang?.lingo.app_prfl_language}
        </Box>

        <Box component="span" className={styles.infoProfile}>
          {languageOption(user.lang)}
        </Box>
      </Box>

      <Box component="div" className={styles.basicContainer}>
        <Box component="span" className={styles.title}>
          {lang?.lingo.app_prfl_role}
        </Box>

        <Box component="span" className={styles.infoProfile}>
          {user?.role == "owner" ? "Владелец" : "Сотрудник"}
          {/*{user.role}*/}
        </Box>
      </Box>

      <Box component="div" className={styles.buttonContainer}>
        <ButtonDefault
          type="button"
          onClick={() => setOpenModalEdit(true)}
          color="green"
          variant="contained"
        >
          {lang?.lingo.app_button_edit}
        </ButtonDefault>

        <ButtonDefault
          type="button"
          onClick={()=> {
            changePassword({email: user?.email})
            alert("Проверьте почту!")
          }}
          color="green"
          variant="contained"
        >
          Сменить пароль
        </ButtonDefault>
        <Box component="div" className={styles.buttonContactContainer}>
          <ButtonDefault
            type="button"
            color="salad"
            variant="contained"
            className={styles.contact}
            onClick={() => setOpenModalContacts(true)}
          >
            {lang?.lingo.app_contact_us1}
          </ButtonDefault>
        </Box>
      </Box>

      <ModalProfile
        lang={lang}
        user={user}
        openModalEdit={openModalEdit}
        formElements={formElements}
        closeModal={closeModal}
        setFormElements={setFormElements}
      />

      {openModalContacts && (
        <ModalContacts
          lang={lang}
          setOpenModalContacts={setOpenModalContacts}
        />
      )}
    </Box>
  );
};
