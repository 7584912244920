import React from 'react';

export const AnalyticsIcon = ({
  fill,
  width,
  height
}: {
  fill: string;
  width: string;
  height: string;
}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_1329_2429)">
  <path fillRule="evenodd" clipRule="evenodd" d="M1.57397 0.897949C1.57397 0.699037 1.49496 0.508271 1.3543 0.367619C1.21365 0.226967 1.02289 0.147949 0.823975 0.147949C0.625062 0.147949 0.434297 0.226967 0.293645 0.367619C0.152992 0.508271 0.0739746 0.699037 0.0739746 0.897949V13.1019C0.0739746 13.5159 0.409975 13.8519 0.823975 13.8519H13.028C13.2269 13.8519 13.4177 13.7729 13.5583 13.6323C13.699 13.4916 13.778 13.3009 13.778 13.1019C13.778 12.903 13.699 12.7123 13.5583 12.5716C13.4177 12.431 13.2269 12.3519 13.028 12.3519H1.57297V0.897949H1.57397ZM10.221 2.43095C10.1119 2.35313 9.98821 2.29805 9.85738 2.26896C9.72655 2.23988 9.59121 2.23739 9.4594 2.26164C9.32759 2.2859 9.202 2.3364 9.09009 2.41015C8.97819 2.4839 8.88225 2.57939 8.80797 2.69095L5.72997 7.30695L4.24597 5.82295C4.15306 5.72997 4.04275 5.65621 3.92133 5.60586C3.79991 5.55552 3.66977 5.52959 3.53833 5.52954C3.40689 5.52949 3.27672 5.55534 3.15527 5.60559C3.03382 5.65585 2.92345 5.72954 2.83047 5.82245C2.7375 5.91536 2.66373 6.02567 2.61339 6.14709C2.56305 6.26851 2.53711 6.39865 2.53707 6.5301C2.53702 6.66154 2.56286 6.7917 2.61312 6.91315C2.66338 7.03461 2.73706 7.14497 2.82997 7.23795L5.17697 9.58395C5.28157 9.68874 5.40813 9.769 5.54751 9.81893C5.6869 9.86886 5.83563 9.88722 5.98297 9.87268C6.13032 9.85813 6.27259 9.81105 6.39953 9.73484C6.52646 9.65862 6.63489 9.55517 6.71697 9.43195L9.89797 4.65895L12.344 6.40595C12.5599 6.56004 12.8281 6.62206 13.0897 6.57836C13.3513 6.53467 13.5849 6.38884 13.739 6.17295C13.8931 5.95706 13.9551 5.68881 13.9114 5.42719C13.8677 5.16558 13.7219 4.93204 13.506 4.77795L10.221 2.43095Z" fill={fill}/>
  </g>
  <defs>
  <clipPath id="clip0_1329_2429">
  <rect width={width} height={height} fill="white"/>
  </clipPath>
  </defs>
  </svg>
);

