import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { SeoMetaTitles } from 'utils/SeoMetaTitles';
import { AuthContext } from 'contexts/auth';
import { ButtonDefault } from 'components/ButtonDefault';

import { Box } from '@mui/material';

import styles from './ProcessPage.module.scss';

export const ProcessPage = (): JSX.Element => {
  const { user, lang } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('payWaiting', 'true');
  }, []);

  return user ? (
    <>
      <SeoMetaTitles title="Processing | QCUP.ME" />
      <Box component="div" className={styles.processPage}>
        <Box component="div" className={styles.titleProcessPage}>
          <Box component="div" className={styles.title}>
            {lang?.lingo.app_prcss_thanks}
          </Box>
          <Box component="div" className={styles.title}>
            {lang?.lingo.app_prfl_trff_prcss}
          </Box>
        </Box>
        <img
          src="/images/paymentProcessed.svg"
          alt="processing"
          className={styles.emptyStateImage}
        />

        <Box component="div" className={styles.textProcessPage}>
          <Box component="div">{lang?.lingo.app_prcss_time}</Box>
          <Box component="div">{lang?.lingo.app_prcss_waiting}</Box>
        </Box>

        <ButtonDefault color="green" onClick={() => navigate('/profile')}>
          {lang?.lingo.app_prcss_profile}
        </ButtonDefault>
      </Box>
    </>
  ) : (
    <></>
  );
};
