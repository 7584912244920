import React from 'react';
import { Box } from '@mui/material';
import { ILanguageProfile } from 'contexts/auth/auth.types';

import styles from '../../../ProfilePage.module.scss';

export const getInitialFormElementsState = (lang: ILanguageProfile | null) => {
  return {
    name: {
      label: `${lang?.lingo.app_prfl_name_title}`,
      helper: 'Please enter your name',
      value: ''
    },
    email: {
      label: `${lang?.lingo.app_prfl_email}`,
      helper: 'Please enter your email',
      value: ''
    },
    b2b_name: {
      label: `${lang?.lingo.app_prfl_company_name_title}`,
      helper: 'Please enter your B2B Name',
      value: ''
    },
    lang: {
      label: `${lang?.lingo.app_prfl_language}`,
      helper: 'Please enter language of your profile',
      value: ''
    }
  };
};

export const initialFormErrorsState = {
  name: '',
  email: '',
  b2b_name: '',
  lang: ''
};

export const language = [
  {
    value: 'ru',
    label: (
      <Box component="div" className={styles.languageOption}>
        <Box component="div">&#127479;&#127482;</Box>
        Русский
      </Box>
    )
  },
  {
    value: 'en',
    label: (
      <Box component="div" className={styles.languageOption}>
        <Box component="div">&#127468;&#127463;</Box>
        English
      </Box>
    )
  },
  {
    value: 'am',
    label: (
      <Box component="div" className={styles.languageOption}>
        <Box component="div">&#127462;&#127474;</Box>
        Հայկական
      </Box>
    )
  }
];
