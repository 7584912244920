export const initialFormElementsState = {
  email: {
    label: 'Email',
    helper: 'Please enter your email',
    value: ''
  },
  password: {
    label: 'Password',
    helper: 'Please enter your password',
    value: ''
  }
};

export const initialFormErrorsState = {
  email: '',
  password: ''
};
