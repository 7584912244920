import React, { useState, useContext, useEffect } from 'react';
import { Box, IconButton, Modal, Stack, TextField } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

import { AuthContext } from 'contexts/auth';
import { ButtonDefault } from 'components/ButtonDefault';
import { AlertContext } from 'contexts/alert/alert-context';

import { IModalTransactionProps } from './ModalTransaction.types';

import styles from './ModalTransaction.module.scss';

export const ModalTransaction = ({
  lang,
  shopId,
  b2cId,
  shopInfo,
  categories,
  selectedTransaction,
  isCreateTransactions,
  closeModal,
  setIsCreateTransactions
}: IModalTransactionProps): JSX.Element => {
  const [points, setPoints] = useState<number | string | null>(null);
  const [error, setError] = useState<boolean | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  const { setMessage, setType } = useContext(AlertContext);
  const { createTransactions } = useContext(AuthContext);

  useEffect(() => {
    setPoints(1);
  }, [selectedTransaction]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(e.target.value);
    setError(false);

    if (Number(inputValue) <= 0 || inputValue == null) {
      setError(true);
      return;
    }

    if (!isNaN(inputValue) && inputValue >= 0) {
      setPoints(inputValue || points);
    } else {
      setPoints('');
    }
  };

  const askPoints = async (points: number) => {
    try {
      if (typeof points === 'number' || typeof points === 'string') {
        if (Number(points) <= 0 || points == null) {
          setError(true);
          return;
        }

        await createTransactions({
          shop_id: shopId as string,
          b2c_id: b2cId as string,
          points: points as number,
          category_id: selectedCategory as string
        }).then(() => {
          setSelectedCategory('');
        });

        setIsCreateTransactions(prevState =>
          prevState && isCreateTransactions ? Number(prevState) + points : null
        );
      }

      closeModal();
    } catch (error) {
      setType('error');
      setMessage(`Your transaction hasn't been created`);
    }
  };

  const handleCategoryClick = (categoryId: string) => {
    if (selectedCategory === categoryId) {
      setSelectedCategory('');
    } else {
      setSelectedCategory(categoryId);
    }
  };

  return (
    <Modal
      open={!!selectedTransaction}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="div" className={styles.modalContainer}>
        <>
          <>
            <Box component="div" className={styles.nameUser}>
              {lang?.lingo.app_trns_add_points_to} {shopInfo?.b2c.name}
            </Box>

            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              className={styles.pointsStack}
            >
              <IconButton
                sx={{ color: '#054B54' }}
                onClick={() => setPoints(prev => Number(prev) - 1)}
                disabled={Number(points) - 1 < 1}
              >
                <RemoveCircleRoundedIcon />
              </IconButton>
              <TextField
                type="number"
                label="Number"
                variant="outlined"
                value={points}
                onChange={handleChange}
                style={{ backgroundColor: 'aliceblue' }}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  inputProps: {
                    min: 1,
                    style: { textAlign: 'center', fontSize: 'large' }
                  }
                }}
                sx={{ minWidth: '150px', width: '100%' }}
                required
              />
              <IconButton
                onClick={() => setPoints(prev => Number(prev) + 1)}
                sx={{ color: '#054B54' }}
              >
                <AddCircleRoundedIcon />
              </IconButton>
            </Stack>
            {categories && (
              <Box component="div" className={styles.categories}>
                <Box component="div" className={styles.categoriesTitle}>
                  {lang?.lingo.app_ctgr_title_purchase}
                </Box>
                <Box component="div" className={styles.categoriesList}>
                  {categories &&
                    categories.map(category => (
                      <Box
                        component="div"
                        className={styles.categoryItem}
                        key={category.id}
                        onClick={() => handleCategoryClick(category.id)}
                        style={{
                          border:
                            selectedCategory === category.id
                              ? '4px solid rgba(5, 75, 84, 0.6)'
                              : '4px solid rgba(247, 249, 249, 1)',
                          color:
                            selectedCategory === category.id
                              ? 'rgba(5, 75, 84, 1)'
                              : 'rgba(2, 32, 36, 1)'
                        }}
                      >
                        <Box component="div" className={styles.categoryEmoji}>
                          {category.emodji}
                        </Box>
                        <Box component="div" className={styles.categoryName}>
                          {category.name}
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            )}
          </>
        </>
        <Box component="div" className={styles.descriptionError}>
          {error ? `${lang?.lingo.app_trns_below_zero}` : null}
        </Box>
        <ButtonDefault
          size="large"
          type="submit"
          color={'green'}
          variant="contained"
          onClick={() => askPoints(points as number)}
        >
          {lang?.lingo.app_trns_add_points}
        </ButtonDefault>

        <ButtonDefault
          size="large"
          type="button"
          color="salad"
          variant="contained"
          onClick={() => {
            closeModal();
            setError(false);
          }}
        >
          {lang?.lingo.app_button_cancel}
        </ButtonDefault>
      </Box>
    </Modal>
  );
};
