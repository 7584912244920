import React, { useContext, useEffect, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Link, Stack, useMediaQuery, Box } from '@mui/material';

import { AuthContext } from 'contexts/auth';
import { AlertContext } from 'contexts/alert';
import { PageLayout } from 'components/page-layout';
import { InputDefault } from 'components/input-default';
import { Loader } from 'components/loader';
import { ButtonDefault } from 'components/ButtonDefault';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';
import styles from "./cp.module.scss"

import { useQuery } from 'hooks/useQuery';

export const ChangePassword = (): JSX.Element => {
  const { isLoading, changePassword } = useContext(AuthContext);
  const { setType, setMessage } = useContext(AlertContext);
  const [ email, setEmail] = useState()
  const [ view, setView] = useState(false)
  const query = useQuery();
  const feedback = query.get('feedback');

  useEffect(() => {
    if (feedback === 'error') {
      setType('error');
      setMessage('Oops! An error has occurred.');
    }
  }, [feedback]);

  const isXs = useMediaQuery('(max-width:600px)');

   const handleChange = (event: any) => {
        setEmail(event.target.value)
    };

  const onSubmit = (e: React.FormEvent) => {
      // @ts-ignore
    if (email) {
      changePassword({email: email});
      setView(true);
    }
    else
      return
  };

  return (
    <>
      <PageLayout heading="Забыли пароль?" login>
        {isLoading && <Loader isOpened={isLoading} />}

        {
          view ?<>
            <Box sx={{mt: 5}}>
              Проверьте свою почту пожалуйста чтобы изменить пароль
            </Box>
      </> :<Stack
          spacing={3}
          component="form"
          autoComplete="off"
          marginTop="16px"
          alignItems="end"
          width={isXs ? '100%' : '500px'}
          onSubmit={onSubmit}
          className={styles.formLogin}
        >
          <InputDefault
            label={"Введите email"}
            type="email"
            fullWidth
            onChange={handleChange}
          />
          <Box component="div" className={styles.buttonContainer}>
            <ButtonDefault variant="contained" size="large" type="submit" >
              Запросить пароль
            </ButtonDefault>
          </Box>
        </Stack>
        }


      </PageLayout>
    </>
  );
};
