import { ILanguageProfile, IUserState } from 'contexts/auth/auth.types';
import { SettingsProfileForm } from './ModalProfile.types';
import { getInitialFormElementsState } from './constants';

type TEditFormPayloadKeys = 'name' | 'email' | 'lang';

export const mapEditFormProfile = (
  user: IUserState,
  lang: ILanguageProfile | null
): SettingsProfileForm => {
  const payload: SettingsProfileForm = getInitialFormElementsState(lang);
  Object.entries(getInitialFormElementsState(lang)).forEach(([key, v]) => {
    payload[key as TEditFormPayloadKeys] = {
      ...v,
      value:
        key === 'b2b_name'
          ? user?.b2b?.name || ''
          : user[key as TEditFormPayloadKeys]
    };
  });
  return payload;
};
