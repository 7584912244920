import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import styles from './HeaderWithArrow.module.scss';

// TODO: useNavigate instead current solution
export const HeaderWithArrow = ({
  name,
  click
}: {
  name: string;
  click?: () => void;
}): JSX.Element => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box component="div" className={styles.headingContainer}>
      <img
        src="/images/newIcon/ArrowLeft.svg"
        alt="arrow_left"
        className={styles.arrowLeft}
        onClick={click || handleGoBack}
      />
      <Box component="p" className={styles.name}>
        {name}
      </Box>
    </Box>
  );
};
