import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Modal } from '@mui/material';

import { ButtonDefault } from 'components/ButtonDefault';

import { IModalDeleteSaleProps } from './ModalDeleteSale.types';

import styles from './ModalDeleteSale.module.scss';

export const ModalDeleteSale = ({
  shop,
  lang,
  sales,
  handleDeleteSale,
  setOpenModalDelete
}: IModalDeleteSaleProps): JSX.Element => {
  const closeModal = () => {
    setOpenModalDelete(false);
  };

  const { key } = useParams();
  const navigate = useNavigate();

  return shop && sales ? (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="div" className={styles.modalContainer}>
        <Box component="div" className={styles.title}>
          {lang?.lingo.app_prmo_delete}
        </Box>

        <Box component="div" className={styles.textContainer}>
          <Box component="div" className={styles.text}>
            {lang?.lingo.app_prmo_delete_question} «
            {sales[key as string]?.discount_message}»{' '}
            {lang?.lingo.app_prmo_in_shop} «{shop.name}»?
          </Box>
          <Box component="div" className={styles.text}>
            {lang?.lingo.app_prmo_delete_warning}
          </Box>
        </Box>
        <Box component="div" className={styles.buttonContainer}>
          <ButtonDefault
            color="green"
            type="button"
            variant="contained"
            onClick={() => {
              closeModal();
            }}
          >
            {lang?.lingo.app_prmo_delete_not_agree}
          </ButtonDefault>
          <ButtonDefault
            color="red"
            type="button"
            variant="contained"
            onClick={() => {
              handleDeleteSale(key as string).then(() => {
                navigate(`/shops/${shop.id}`);
              });
            }}
          >
            {lang?.lingo.app_prmo_delete_agree}
          </ButtonDefault>
        </Box>
      </Box>
    </Modal>
  ) : (
    <></>
  );
};
