import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { Container } from '@mui/material';

import { SeoMetaTitles } from 'utils/SeoMetaTitles';
import { HeaderAuthorization } from 'components/header/HeaderAuthorization';
import { HeaderDefault } from 'components/header/HeaderDefault';

import { Footer } from '../footer';
import { AuthContext } from '../../contexts/auth';
import { Loader } from '../loader';

import styles from './layout.module.scss';

export const Layout = (): JSX.Element => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  const location = useLocation();
  const adresBar = location.pathname.split('/');
  const isAuthPage = adresBar.includes('login') || adresBar.includes('signup');

  const mainContent = (
    <main
      className={classNames(styles.main, {
        [styles['main_padded']]: !localStorage.getItem('token')
      })}
    >
      <Container maxWidth={'lg'} className={styles.container}>
        <div style={{ width: '100%' }}>
          <Outlet />
        </div>
      </Container>
    </main>
  );

  return (
    <>
      <SeoMetaTitles title="QCUP.ME" />
      {!isAuthPage ? (
        <HeaderAuthorization mainContent={mainContent} />
      ) : (
        <>
          <HeaderDefault />
          {mainContent}
        </>
      )}

      {localStorage.getItem('token') && <Footer />}
      <Loader isOpened={isLoading} />
    </>
  );
};
