import React, { FormEvent, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Modal, Stack } from '@mui/material';

import { AuthContext } from 'contexts/auth';

import {
  IModalSaleProps,
  SettingsSaleForm,
  SettingsSaleFormErrors
} from './ModalSale.types';
import { initialFormErrorsState } from './constants';

import { InputDefault } from 'components/input-default';
import { ButtonDefault } from 'components/ButtonDefault';

import styles from './ModalSale.module.scss';

export const ModalSale = ({
  shop,
  lang,
  formElements,
  closeModal,
  setFormElements
}: IModalSaleProps): JSX.Element => {
  const { createSale } = useContext(AuthContext);

  let { shop_id } = useParams();

  const [errors, setErrors] = useState<SettingsSaleFormErrors>(
    initialFormErrorsState
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [event.target.name]: '' });
    setFormElements({
      ...formElements,
      [event.target.name]: {
        ...formElements[event.target.name as keyof SettingsSaleForm],
        value:
          event.target.name === 'sale_limit'
            ? event.target.value
              ? parseInt(event.target.value)
              : ''
            : event.target.value
      }
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    let err = { ...initialFormErrorsState };

    Object.entries(formElements).map(([fieldKey, fieldValue]) => {
      if (!fieldValue.value || fieldValue.value < 0) {
        err = {
          ...err,
          [fieldKey]: `${fieldValue.label} ${lang?.lingo.app_required_field}`
        };
      }
      if (fieldKey === 'sale_limit') {
        if (fieldValue.value < 0) {
          err = {
            ...err,
            sale_limit: `${fieldValue.label} ${lang?.lingo.app_more_than_0}`
          };
        }
      }
    });

    const hasErrors = !Object.values(err).every(error => !error);

    if (hasErrors) {
      setErrors({
        ...errors,
        ...err
      });
    } else {
      createSale(shop_id as string, {
        sale_limit: formElements.sale_limit.value as number,
        discount_message: formElements.discount_message.value as string,
        is_active: true
      }).then(() => {
        closeModal();
      });
    }
  };

  return (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="div" className={styles.modalContainer}>
        <Box component="div" className={styles.createSaleTitle}>
          {lang?.lingo.app_prmo_create_title}
        </Box>
        {shop ? (
          <Box component="div" className={styles.nameContainer}>
            {shop.name}
          </Box>
        ) : null}
        <Stack
          spacing={3}
          component="form"
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <InputDefault
            label={formElements.sale_limit.label}
            type="number"
            helperText={
              errors.sale_limit
                ? errors.sale_limit
                : formElements.sale_limit.helper
            }
            error={!!errors.sale_limit}
            onChange={handleChange}
            name="sale_limit"
            value={formElements.sale_limit.value as number}
          />

          <InputDefault
            label={formElements.discount_message.label}
            type="text"
            helperText={
              errors.discount_message
                ? errors.discount_message
                : formElements.discount_message.helper
            }
            error={!!errors.discount_message}
            onChange={handleChange}
            name="discount_message"
            value={formElements.discount_message.value as string}
          />

          <Box component="div" className={styles.buttonContainer}>
            <ButtonDefault variant="contained" size="large" type="submit">
              {lang?.lingo.app_prmo_create_button}
            </ButtonDefault>
            <ButtonDefault
              variant="contained"
              size="large"
              color="salad"
              onClick={() => {
                closeModal();
              }}
            >
              {lang?.lingo.app_button_cancel}
            </ButtonDefault>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};
