import React, { useEffect, useRef } from 'react';
import { BaseEmoji, Picker } from 'emoji-mart';
import data from '@emoji-mart/data';

interface EmojiPickerProps {
  onEmojiSelect: (emoji: BaseEmoji) => void;
}

export const EmojiPicker = (props: EmojiPickerProps) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const executedRef = useRef(false);

  useEffect(() => {
    if (executedRef.current) return;
    // @ts-ignore Waiting types for v5 of library
    new Picker({ ...props, data, ref, perLine: 8 });
    executedRef.current = true;
  }, []);

  return <div ref={ref} />;
};
