import React from 'react';
import { Box } from '@mui/material';

import { IClientBalanceProps } from './ClientBalance.types';

import styles from './ClientBalanse.module.scss';

export const ClientBalance = ({
  lang,
  shopInfo
}: IClientBalanceProps): JSX.Element => {
  return (
    <Box
      component="div"
      className={styles.balanceContainer}
      style={{
        backgroundImage: 'url("/images/backgroundScan.svg")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <Box component="div" className={styles.balanceTitle}>
        {lang?.lingo.app_qrscan_balance}
      </Box>
      <Box component="div" className={styles.balancePoints}>
        <img
          src="/images/icon_coin.svg"
          alt="icon_coin"
          className={styles.iconCoin}
        />
        <Box component="div" className={styles.textPoints}>
          {shopInfo?.b2c?.balance} {lang?.lingo.app_pnts_points1}
        </Box>
      </Box>
    </Box>
  );
};
