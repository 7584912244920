import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, Card } from '@mui/material';

import { AuthContext } from 'contexts/auth';
import { ButtonDefault } from 'components/ButtonDefault';

import { ISaleInfoProps } from './SaleInfo.types';

import { AddPlus } from '../../../../../public/images/newIcon/AddPlus';

import styles from './SaleInfo.module.scss';

export const SaleInfo = ({ lang, shop, setOpenModalSale }: ISaleInfoProps) => {
  const { user } = useContext(AuthContext);

  const disableButton =
    shop.sale_meta &&
    user &&
    Object.keys(shop.sale_meta).length === user?.b2b?.limits?.sales_in_shop;

  return (
    <>
      <Box component="div" className={styles.salesContainer}>
        <Box
          component="div"
          className={
            shop.sale_meta && Object.keys(shop.sale_meta).length
              ? styles.sales
              : ''
          }
        >
          {shop.sale_meta && Object.keys(shop.sale_meta).length ? (
            Object.entries(shop?.sale_meta).map(([key, value]) => (
              <Link key={key} to={`sales/${key}`} className={styles.saleLink}>
                <Card className={styles.saleCard}>
                  <Box component="div" className={styles.saleContainer}>
                    <Box component="div" className={styles.saleNameContainer}>
                      <Box component="p" className={styles.saleName}>
                        {value.discount_message}
                      </Box>
                      <Box component="div" className={styles.saleMetaContainer}>
                        <img
                          src="/images/newIcon/promotionShop.svg"
                          alt="promotion_shop"
                        />
                        <Box component="div" className={styles.saleMeta}>
                          <Box component="span" className={styles.saleCount}>
                            {value.sale_limit}
                          </Box>
                          {lang?.lingo.app_prmo_points_sale1}
                        </Box>
                      </Box>
                    </Box>
                    <img
                      src="/images/newIcon/ArrowRight.svg"
                      alt="arrow_right"
                      className={styles.arrowRight}
                    />
                  </Box>
                </Card>
              </Link>
            ))
          ) : (
            <Box component="div" className={styles.emptyStateBox}>
              <img
                src="/images/noSales.svg"
                alt="no_shops"
                className={styles.emptyStateImage}
              />
              <Box component="div" className={styles.emptyStateTitle}>
                {lang?.lingo.app_prmo_empty_state}
              </Box>
            </Box>
          )}
        </Box>
        <Box component="div" className={styles.addSaleContainer}>
          <ButtonDefault
            color="green"
            type="button"
            variant="contained"
            disabled={disableButton}
            onClick={() => setOpenModalSale(true)}
            className={styles.addSaleBtn}
          >
            <Box component="div" className={styles.addSale}>
              <AddPlus fill={disableButton ? '#B8B8B8' : '#fff'} />
              <Box component="span">{lang?.lingo.app_prmo_create_button}</Box>
            </Box>
          </ButtonDefault>
        </Box>

        {disableButton ? (
          <Box component="div" className={styles.toCreateMoreSales}>
            {lang?.lingo.app_prmo_contact_us1}
            <>
              {' '}
              <a href="mailto:contact@qcup.me">
                {lang?.lingo.app_prmo_contact_us2}
              </a>
            </>
          </Box>
        ) : null}
      </Box>
    </>
  );
};
