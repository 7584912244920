import React from 'react';

export const Telegram = ({ fill }: { fill: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 7C0.25 6.11358 0.424594 5.23583 0.763813 4.41689C1.10303 3.59794 1.60023 2.85382 2.22703 2.22703C2.85382 1.60023 3.59794 1.10303 4.41689 0.763813C5.23583 0.424594 6.11358 0.25 7 0.25C7.88642 0.25 8.76416 0.424594 9.58311 0.763813C10.4021 1.10303 11.1462 1.60023 11.773 2.22703C12.3998 2.85382 12.897 3.59794 13.2362 4.41689C13.5754 5.23583 13.75 6.11358 13.75 7C13.75 8.79021 13.0388 10.5071 11.773 11.773C10.5071 13.0388 8.79021 13.75 7 13.75C5.20979 13.75 3.4929 13.0388 2.22703 11.773C0.961159 10.5071 0.25 8.79021 0.25 7ZM9.252 11.064L10.297 3.132L2.132 7.067L4.549 7.943L7.235 5.867C7.287 5.82688 7.3464 5.7974 7.4098 5.78023C7.4732 5.76307 7.53935 5.75856 7.60449 5.76696C7.66963 5.77537 7.73248 5.79652 7.78945 5.82921C7.84641 5.8619 7.89638 5.9055 7.9365 5.9575C7.97662 6.0095 8.0061 6.0689 8.02327 6.1323C8.04043 6.1957 8.04494 6.26185 8.03654 6.32699C8.02813 6.39213 8.00698 6.45498 7.97429 6.51195C7.9416 6.56891 7.898 6.61888 7.846 6.659L5.618 8.38V11.106L7.303 9.502L9.252 11.063V11.064Z"
      fill={fill}
    />
  </svg>
);
