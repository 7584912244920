import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Modal } from '@mui/material';

import { ButtonDefault } from 'components/ButtonDefault';

import { IModalDeleteCampaignProps } from './ModalDeleteCampaign.types';

import styles from './ModalDeleteCampaign.module.scss';

export const ModalDeleteCampaign = ({
  lang,
  currentItem,
  handleDeleteCamapigh,
  setOpenModalDelete
}: IModalDeleteCampaignProps): JSX.Element => {
  const closeModal = () => {
    setOpenModalDelete(false);
  };

  const navigate = useNavigate();

  return currentItem ? (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="div" className={styles.modalContainer}>
        <Box component="div" className={styles.title}>
          {lang?.lingo.app_cmpgn_delete_qst}
        </Box>

        <Box component="div" className={styles.textContainer}>
          <Box component="div" className={styles.text}>
            {lang?.lingo.app_cmpgn_delete} «{currentItem.name}»?
          </Box>
          <Box component="div" className={styles.text}>
            {lang?.lingo.app_cmpgn_delete_wrn}
          </Box>
        </Box>
        <Box component="div" className={styles.buttonContainer}>
          <ButtonDefault
            color="green"
            type="button"
            variant="contained"
            onClick={() => {
              closeModal();
            }}
          >
            {lang?.lingo.app_prmo_delete_not_agree}
          </ButtonDefault>
          <ButtonDefault
            color="red"
            type="button"
            variant="contained"
            onClick={() => {
              handleDeleteCamapigh(currentItem.id as string).then(() => {
                navigate(`/campaigns`);
              });
            }}
          >
            {lang?.lingo.app_prmo_delete_agree}
          </ButtonDefault>
        </Box>
      </Box>
    </Modal>
  ) : (
    <></>
  );
};
