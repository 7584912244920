import React, { FormEvent, useState, useContext, useEffect } from 'react';
import { Box, Modal, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { BaseEmoji } from 'emoji-mart';

import { InputEmoji } from 'components/input-emoji';
import { AuthContext } from 'contexts/auth';

import {
  IModalEditCategoryProps,
  SettingsCategoryEditForm,
  SettingsCategoryEditFormErrors
} from './ModalEditCategory.types';
import { initialFormEditErrorsState } from './constants';
import { mapEditFormCategory } from './utils';

import { InputDefault } from 'components/input-default';
import { ButtonDefault } from 'components/ButtonDefault';

import styles from './ModalEditCategory.module.scss';

export const ModalEditCategory = ({
  category,
  lang,
  openModalEdit,
  formEditElements,
  closeModalEdit,
  setFormEditElements
}: IModalEditCategoryProps): JSX.Element => {
  const { editCategory } = useContext(AuthContext);

  const [errors, setErrors] = useState<SettingsCategoryEditFormErrors>(
    initialFormEditErrorsState
  );

  let { category_id } = useParams();

  useEffect(() => {
    if (category) {
      setFormEditElements(mapEditFormCategory(category, lang));
    }
  }, [category, lang]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [event.target.name]: '' });
    setFormEditElements({
      ...formEditElements,
      [event.target.name]: {
        ...formEditElements[
          event.target.name as keyof SettingsCategoryEditForm
        ],
        value: event.target.value
      }
    });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    let err = { ...initialFormEditErrorsState };
    const { description, ...requiredFormElements } = formEditElements;

    Object.entries(requiredFormElements).map(([fieldKey, fieldValue]) => {
      if (!fieldValue.value) {
        err = {
          ...err,
          [fieldKey]: `${fieldValue.label} ${lang?.lingo.app_required_field}`
        };
      }
    });

    const hasErrors = !Object.values(err).every(error => !error);

    if (hasErrors) {
      setErrors({
        ...errors,
        ...err
      });
    } else {
      editCategory(category_id as string, {
        name: formEditElements.name.value as string,
        emodji: formEditElements.emodji.value as string,
        description: formEditElements.description.value as string
      }).then(() => {
        closeModalEdit();
        setErrors(initialFormEditErrorsState);
      });
    }
  };

  const onEmojiSelect = (emodji: BaseEmoji) => {
    setErrors({ ...errors, emodji: '' });
    setFormEditElements({
      ...formEditElements,
      emodji: { ...formEditElements['emodji'], value: emodji.native }
    });
  };

  return (
    <Modal
      open={!!openModalEdit}
      onClose={closeModalEdit}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="div" className={styles.modalContainer}>
        <Box component="div" className={styles.createCategoryTitle}>
          {lang?.lingo.app_ctgr_title_edit}
        </Box>
        <Stack
          spacing={3}
          component="form"
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <InputDefault
            label={formEditElements.name.label}
            type="text"
            helperText={
              errors.name ? errors.name : formEditElements.name.helper
            }
            error={!!errors.name}
            onChange={handleChange}
            name="name"
            value={formEditElements.name.value}
          />

          <InputEmoji
            label={formEditElements.emodji.label}
            helperText={
              errors.emodji ? errors.emodji : formEditElements.emodji.helper
            }
            required
            fullWidth
            error={!!errors.emodji}
            value={formEditElements.emodji.value}
            emoji={formEditElements.emodji.value}
            onChange={handleChange}
            onEmojiSelect={onEmojiSelect}
          />

          <InputDefault
            label={formEditElements.description.label}
            type="text"
            helperText={errors.description ? errors.description : ''}
            error={!!errors.description}
            onChange={handleChange}
            name="description"
            value={formEditElements.description.value}
          />

          <Box component="div" className={styles.buttonContainer}>
            <ButtonDefault variant="contained" size="large" type="submit">
              {lang?.lingo.app_prmo_accept_button}
            </ButtonDefault>
            <ButtonDefault
              variant="contained"
              size="large"
              color="salad"
              onClick={() => {
                setErrors(initialFormEditErrorsState);
                closeModalEdit();
              }}
            >
              {lang?.lingo.app_button_cancel}
            </ButtonDefault>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};
