import React from 'react';
import styles from './page-layout.module.scss';

interface PageLayoutProps {
  heading: React.ReactNode;
  children: React.ReactNode;
  subHeading?: string;
  text?: string;
  login?: boolean;
}

export const PageLayout = ({
  heading,
  subHeading,
  text,
  children,
  login
}: PageLayoutProps): JSX.Element => {
  return (
    <>
      <div className={login ? styles.pageLayout : null}>
        <h1 className={login ? styles.heading : styles.headingDefault}>
          {heading}
        </h1>
        {subHeading && (
          <h2 className={login ? styles.subheading : styles.subheadingDefault}>
            {subHeading}
          </h2>
        )}
        {text && (
          <p className={login ? styles.text : styles.textDefault}>{text}</p>
        )}
        {children}
      </div>
    </>
  );
};
