import React, { useState } from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import styles from './indexPassword.module.scss';

interface InputPasswordProps {
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  helperText?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
}

export const InputPassword = ({
  label,
  helperText,
  required = false,
  fullWidth = false,
  error = false,
  onChange
}: InputPasswordProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <Box component="div" className={styles.inputPassword}>
      <TextField
        variant="standard"
        required={required}
        fullWidth={fullWidth}
        error={error}
        label={label}
        type={isVisible ? 'text' : 'password'}
        helperText={helperText ? helperText : null}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" style={{ marginLeft: '0px' }}>
              <IconButton
                style={{ padding: '0px', color: '#b8b8b8', margin: '12px' }}
                aria-label="toggle password visibility"
                onClick={() => setIsVisible(!isVisible)}
                edge="end"
              >
                {isVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};
