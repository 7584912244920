import React from 'react';

export const Logout = ({
  fill,
  width,
  height
}: {
  fill: string;
  width: string;
  height: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_845_3463)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0L8.5 0C8.89782 0 9.27936 0.158035 9.56066 0.43934C9.84196 0.720644 10 1.10218 10 1.5V3.439C9.59425 3.764 9.33098 4.23421 9.266 4.75H5.75C5.45453 4.75 5.16194 4.8082 4.88896 4.92127C4.61598 5.03434 4.36794 5.20008 4.15901 5.40901C3.95008 5.61794 3.78434 5.86598 3.67127 6.13896C3.5582 6.41194 3.5 6.70453 3.5 7C3.5 7.29547 3.5582 7.58806 3.67127 7.86104C3.78434 8.13402 3.95008 8.38206 4.15901 8.59099C4.36794 8.79992 4.61598 8.96566 4.88896 9.07873C5.16194 9.1918 5.45453 9.25 5.75 9.25H9.266C9.33098 9.76579 9.59425 10.236 10 10.561V12.5C10 12.8978 9.84196 13.2794 9.56066 13.5607C9.27936 13.842 8.89782 14 8.5 14H1.5C1.10218 14 0.720644 13.842 0.43934 13.5607C0.158035 13.2794 0 12.8978 0 12.5L0 1.5ZM10.963 4.307C10.8259 4.36377 10.7088 4.45991 10.6264 4.58327C10.544 4.70662 10.5 4.85165 10.5 5V6H5.75C5.48478 6 5.23043 6.10536 5.04289 6.29289C4.85536 6.48043 4.75 6.73478 4.75 7C4.75 7.26522 4.85536 7.51957 5.04289 7.70711C5.23043 7.89464 5.48478 8 5.75 8H10.5V9C10.5001 9.14824 10.5442 9.29312 10.6266 9.41633C10.709 9.53955 10.8261 9.63558 10.9631 9.69229C11.1 9.749 11.2507 9.76385 11.3961 9.73497C11.5415 9.70608 11.6751 9.63476 11.78 9.53L13.78 7.53C13.9205 7.38937 13.9993 7.19875 13.9993 7C13.9993 6.80125 13.9205 6.61063 13.78 6.47L11.78 4.47C11.6752 4.36511 11.5416 4.29364 11.3962 4.26463C11.2508 4.23562 11.1 4.25036 10.963 4.307Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_845_3463">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
