import React from 'react';

import { ILanguageProfile } from 'contexts/auth/auth.types';

import { QrCodeIcon } from '../../../public/images/newIcon/QrCodeIcon';
import { AnalyticsIcon } from '../../../public/images/newIcon/AnalyticsIcon';
import { PaperPlaneIcon } from '../../../public/images/newIcon/PaperPlaneIcon';
import { Profile } from '../../../public/images/newIcon/Profile';
import {TransactionsList} from "../../../public/images/newIcon/TransactionsList";

export const getNavigationUrls = (
  paid: boolean | null,
  lang: ILanguageProfile | null,
  value: number,
  daysLeft: number | null
) => {
  // const index = paid ? 1 : 0; - при наличии рассылок (при оплате платного тарифа)

  const navButtons = [
    {
      id: 0,
      url: '',
      icon: (
        <QrCodeIcon
          fill={value === 0 ? '#054B54' : '#789194'}
          width="25px"
          height="25px"
        />
      ),
      text: `${lang?.lingo.app_scan}`
    },
    {
      id: 7,
      url: 'transactions',
      icon: (
        <TransactionsList
          fill={value === 7 ? '#054B54' : '#789194'}
          width="25px"
          height="25px"
        />
      ),
      text: `Транзакции`
    },
    {
      id: 2,
      url: 'analytics',
      icon: (
        <AnalyticsIcon
          fill={value === 2 ? '#054B54' : '#789194'}
          width="25px"
          height="25px"
        />
      ),
      text: `${lang?.lingo.app_analytics}`
    },
    {
      id: 1,
      url: 'campaigns',
      icon: (
        <PaperPlaneIcon
          fill={value === 1 ? '#054B54' : '#789194'}
          width="25px"
          height="25px"
        />
      ),
      text: `${lang?.lingo.app_cmpgn_title}`
    },
    {
      id: 3,
      // url: daysLeft && daysLeft < 40 ? 'expired-trial-period' : 'profile',
      url: 'profile',
      icon: (
        <Profile
          width="25px"
          height="25px"
          fill={value === 3 ? '#054B54' : '#789194'}
        />
      ),
      text: `${lang?.lingo.app_prfl_title}`
    }
  ];
  return navButtons;

};
