import React from 'react';

export const ArrowButton = ({
  fill,
  width,
  height,
  className
}: {
  fill: string;
  width: string;
  height: string;
  className?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.05989 6.93991C9.34079 7.22116 9.49857 7.60241 9.49857 7.99991C9.49857 8.39741 9.34079 8.77866 9.05989 9.05991L3.40389 14.7179C3.12249 14.9992 2.74089 15.1571 2.34304 15.157C1.94518 15.1569 1.56365 14.9988 1.28239 14.7174C1.00113 14.436 0.843168 14.0544 0.843262 13.6566C0.843356 13.2587 1.00149 12.8772 1.28289 12.5959L5.87889 7.99991L1.28289 3.40391C1.00952 3.12114 0.858143 2.7423 0.861374 2.34901C0.864605 1.95571 1.02218 1.57941 1.30016 1.30117C1.57815 1.02292 1.95429 0.864993 2.34759 0.861391C2.74088 0.857789 3.11986 1.0088 3.40289 1.28191L9.06089 6.93891L9.05989 6.93991Z"
      fill={fill}
    />
  </svg>
);
