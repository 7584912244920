import React from 'react';
import { TextField } from '@mui/material';

interface InputDefaultProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  label?: string;
  rows?: number;
  helperText?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  multiline?: boolean;
  value?: string | number | null;
  name?: string;
  disabled?: boolean;
}

export const InputDefault = ({
  label,
  rows = 1,
  helperText,
  type,
  required = false,
  multiline = false,
  fullWidth = false,
  error = false,
  onChange,
  value,
  name,
  disabled
}: InputDefaultProps): JSX.Element => {
  return (
    <TextField
      variant="standard"
      required={required}
      fullWidth={fullWidth}
      error={error}
      label={label}
      type={type}
      helperText={helperText ? helperText : null}
      onChange={onChange}
      value={value}
      name={name}
      disabled={disabled}
      minRows={rows}
      multiline={multiline}
    />
  );
};
