import React, {JSXElementConstructor, ReactElement} from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer, LineChart, Line, CartesianGrid
} from 'recharts';

import { handleData } from './constants';
import {IAnalytics} from "../../utils/auth-service.types";
import {Box, Grid, Typography} from "@mui/material";
import styles from "./Charts.module.scss"

interface ChartProps {
  data: any[];
}

const CustomTooltip3 = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box className={styles.tooltip}>
        <Box className={styles.title}>{label}</Box>
        <Box sx={{color: payload[0].color}} className={styles.tooltipTitle}>{`Начислено баллов ${payload[0].value}`}</Box>
        <Box sx={{color: payload[1].color}} className={styles.tooltipTitle}>{`Списано баллов ${payload[1].value}`}</Box>
      </Box>

    );
  }

  return null;
};

const CustomTooltip2 = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box className={styles.tooltip}>
        <Box className={styles.title}>{label}</Box>
        <Box sx={{color: payload[0].color}} className={styles.tooltipTitle}>{`Новых подписчиков ${payload[0].value}`}</Box>
      </Box>

    );
  }

  return null;
};

const CustomTooltip1 = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box className={styles.tooltip}>
        <Box className={styles.title}>{label}</Box>
        <Box sx={{color: payload[0].color}} className={styles.tooltipTitle}>{`Транзакций ${payload[0].value}`}</Box>
      </Box>

    );
  }

  return null;
};


const ChartCard = (props: { title: string, chart: ReactElement<any, string | JSXElementConstructor<any>> }) => {
    // @ts-ignore
  return (
     <Box className={styles.card}>
       <Box className={styles.title}>{props.title}</Box>
       <ResponsiveContainer width="100%" height={350}>
        {props.chart}
        </ResponsiveContainer>
     </Box>
    );
}

export const Charts = ({
  data
}: ChartProps): JSX.Element => {
  // @ts-ignore
  return (
    <Box sx={{mb: 5}}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <ChartCard title={"Количество транзакций"} chart={<BarChart
            width={500}
            height={150}
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: -30,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" orientation="bottom" />
            <YAxis orientation="left" />
            <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#2E9FAE" stopOpacity={0.4} />
          <stop offset="95%" stopColor="#054B54" stopOpacity={1} />
        </linearGradient>
      </defs>
            <Tooltip content={<CustomTooltip1 />} />
            <Bar dataKey='transactions' fill="url(#colorUv)"/>
          </BarChart>}/>
        </Grid>
        <Grid item md={6} xs={12}>
          <ChartCard title={"Новые подписчики"} chart={<LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: -30,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="day" orientation="bottom" />
        <YAxis orientation="left" />
        <Tooltip content={<CustomTooltip2 />} />
        <Line dataKey='users' stroke="#00BED6" strokeWidth={4} />
      </LineChart>}/>
        </Grid>
        <Grid item md={6} xs={12}>
          <ChartCard title={"Начисленные и списанные баллы"} chart={<LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: -30,
          bottom: 0
        }}
      >
            <CartesianGrid strokeDasharray="3 3" vertical={false}/>
        <XAxis dataKey="day" orientation="bottom" />
        <YAxis orientation="left" />
        <Tooltip content={<CustomTooltip3 />} />
        <Line dataKey='points_added' stroke="#E50045" strokeWidth={4} />
        <Line dataKey='returned_points' stroke="#6750A3" strokeWidth={4} />
      </LineChart>}/>
        </Grid>
      </Grid>
    </Box>


  );
};
