import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, AppBar, Toolbar, Container } from '@mui/material';

import styles from '../header.module.scss';

export const HeaderDefault = (): JSX.Element => {
  return (
    <AppBar position="static" className={styles.appBar}>
      <Container maxWidth="lg">
        <Toolbar className={styles.logoContainer}>
          <Link to="/" className={styles.logo}>
            <img
              src="/images/newLogo.svg"
              alt={'QCup Logo'}
              className={styles.logo__image}
            />
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
