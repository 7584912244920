import React, { useEffect } from 'react';

export type TSEO = {
  title: string;
};

export const SeoMetaTitles = ({ title }: TSEO) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <head>
      <title>{title}</title>
    </head>
  );
};
