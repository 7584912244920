import React from 'react';
import { Box } from '@mui/material';

import styles from './ProfilePage.module.scss';

export const languageOption = (lang: string) => {
  if (lang === 'ru') {
    return (
      <Box component="div" className={styles.languageOption}>
        <Box component="div">&#127479;&#127482;</Box>
        Русский
      </Box>
    );
  }
  if (lang === 'am') {
    return (
      <Box component="div" className={styles.languageOption}>
        <Box component="div">&#127462;&#127474;</Box>
        Հայկական
      </Box>
    );
  }
  return (
    <Box component="div" className={styles.languageOption}>
      <Box component="div">&#127468;&#127463;</Box>
      English
    </Box>
  );
};
