import { createContext } from 'react';
import { IAuthContext } from './auth.types';

const contextDefaultValue: IAuthContext = {
  token: null,
  user: null,
  lang: null,
  isLoading: false,
  isAuthenticated: false,
  hasError: false,
  sales: null,
  onLogin: () => {},
  changePassword: () => {},
  onLogout: () => {},
  onSignUp: () => {},
  getUser: () => {},
  getLanguage: () => {},
  editProfile: () => new Promise(() => {}),
  fetchTransactions: () => new Promise(() => {}),
  fetchAnalytics: () => new Promise(() => {}),
  fetchPreTransaction: () => new Promise(() => {}),
  createTransactions: () => new Promise(() => {}),
  updateSettings: () => {},
  getShops: () => new Promise(() => {}),
  getOneShop: () => new Promise(() => {}),
  createShop: () => new Promise(() => {}),
  editShop: () => new Promise(() => {}),
  getQRCode: () => new Promise(() => {}),
  getCategories: () => new Promise(() => {}),
  getOneCategory: () => new Promise(() => {}),
  createCategory: () => new Promise(() => {}),
  editCategory: () => new Promise(() => {}),
  deleteCategory: () => new Promise(() => {}),
  getEmployees: () => new Promise(() => {}),
  createEmployee: () => new Promise(() => {}),
  deleteEmployee: () => new Promise(() => {}),
  createSale: () => new Promise(() => {}),
  deleteSale: () => new Promise(() => {}),
  getCampaigns: () => new Promise(() => {}),
  getOneCampaign: () => new Promise(() => {}),
  createCampaign: () => new Promise(() => {}),
  editCampaign: () => new Promise(() => {}),
  useAIAssistant: () => new Promise(() => {}),
  deleteCampaign: () => new Promise(() => {})
};
export const AuthContext = createContext(contextDefaultValue);
