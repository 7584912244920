import React, { useContext, useEffect, useState } from 'react';
import QrScanner from 'qr-scanner';
import { Button, Card, Stack } from '@mui/material';
import { Box } from '@mui/material';

import { PageLayout } from 'components/page-layout';
import { ButtonDefault } from 'components/ButtonDefault';
import { AlertContext } from 'contexts/alert';
import { AuthContext } from 'contexts/auth';
import { ICategory, IShopInfo } from 'utils/auth-service.types';
import { SeoMetaTitles } from 'utils/SeoMetaTitles';
import { HeaderWithArrow } from 'components/HeaderWithArrow';
import { ModalTransaction } from 'pages/transactions/components/ModalTransaction';

import { StartPageQRScan } from './components/StartPageQRScan';
import { ClientBalance } from './components/ClientBalance';
import { OrLine } from './components/OrLine';

import styles from './QRScanB2B.module.scss';

export const QRScanB2B = (): JSX.Element => {
  const { setMessage, setType } = useContext(AlertContext);
  const { lang, user, fetchPreTransaction, createTransactions, getCategories } =
    useContext(AuthContext);

  const [b2cId, setB2cId] = useState<string>('');
  const [shopInfo, setShopInfo] = useState<IShopInfo | null>(null);
  const [isLimit, setIsLimit] = useState<number | undefined | null>(null);
  const [isCreateTransactions, setIsCreateTransactions] = useState<
    number | undefined | null
  >(null);
  const [selectedTransaction, setSelectedTransaction] =
    useState<boolean>(false);
  const [categories, setCategories] = useState<ICategory[] | undefined>([]);

  const getAllCategories = async () => {
    const data = await getCategories();
    setCategories(data);
  };

  useEffect(() => {
    b2cId && getClientInfo(b2cId);
    getAllCategories();
  }, [isCreateTransactions]);

  const shopId = user?.shop_id;

  const qrCodeScanningResult = async (result: QrScanner.ScanResult) => {
    const b2cId = result.data;
    setB2cId(b2cId);
    if (!b2cId) return handleError(`${lang?.lingo.app_qrscan_invld_code}`);
    await getClientInfo(b2cId);
  };

  const getClientInfo = async (b2cId: string) => {
    try {
      const response = await fetchPreTransaction({
        b2c_id: b2cId,
        shop_id: shopId as string
      });

      setShopInfo(response);
      setIsCreateTransactions(shopInfo?.b2c.balance);
    } catch (err) {
      handleError(`${lang?.lingo.app_qrscan_invld_code}`);
    }
  };

  const askDiscount = async (saleCode: string) => {
    try {
      await createTransactions({
        shop_id: shopId as string,
        b2c_id: b2cId,
        sale_code: saleCode
      });
      setIsCreateTransactions(prevState =>
        prevState && isLimit ? Number(prevState) - isLimit : null
      );
    } catch (error) {
      setType('error');
      setMessage(`${lang?.lingo.app_qrscan_invld_trsc}`);
    }
  };

  const handleError = (error: string) => {
    setType('error');
    setMessage(error);
  };

  const closeModal = () => {
    setSelectedTransaction(false);
  };

  return shopId ? (
    <>
      <SeoMetaTitles title="QCUP.ME" />
      <PageLayout
        heading={
          shopInfo && shopId ? (
            <HeaderWithArrow
              name={`${lang?.lingo.app_qrscan}`}
              click={() => setShopInfo(null)}
            />
          ) : (
            ''
          )
        }
      >
        {shopId && !shopInfo && (
          <StartPageQRScan
            lang={lang}
            qrCodeScanningResult={qrCodeScanningResult}
            handleError={handleError}
          />
        )}

        <>
          {shopInfo && shopId && (
            <Stack
              direction="column"
              spacing={2}
              component="form"
              autoComplete="off"
              alignItems="center"
              className={styles.mainContainer}
            >
              <ClientBalance lang={lang} shopInfo={shopInfo} />

              <Box component="div" className={styles.nameStore}>
                {lang?.lingo.app_prmo_title} «{shopInfo?.shop?.name}»
              </Box>
              {Object.entries(shopInfo?.shop?.sale_meta).length ? (
                <>
                  {Object.entries(shopInfo?.shop?.sale_meta).map(
                    ([key, sale], index) => (
                      <Card key={index} className={styles.saleContainer}>
                        <Box component="div" className={styles.saleTitle}>
                          {sale.discount_message}
                        </Box>

                        <Button
                          variant="contained"
                          disabled={!sale.is_valid_for_b2c}
                          onClick={() => {
                            askDiscount(key);
                            setIsLimit(sale.sale_limit);
                          }}
                          className={
                            !sale.is_valid_for_b2c
                              ? styles.saleButtonDisabled
                              : styles.saleButton
                          }
                        >
                          <img
                            src={
                              !sale.is_valid_for_b2c
                                ? '/images/icon_coin_disabled.svg'
                                : '/images/icon_coin.svg'
                            }
                            alt="icon_coin"
                            className={styles.iconCoinButton}
                          />
                          <Box
                            component="p"
                            className={
                              !sale.is_valid_for_b2c
                                ? styles.saleLimitDisabled
                                : styles.saleLimit
                            }
                          >
                            {sale.sale_limit}
                          </Box>
                        </Button>
                      </Card>
                    )
                  )}
                  <OrLine lang={lang} />
                </>
              ) : (
                <Box component="div" className={styles.emptySale}>
                  {lang?.lingo.app_qrscan_no_sales}
                </Box>
              )}

              {shopInfo && shopId && (
                <Box component="div" className={styles.buttonGiveContainer}>
                  <ButtonDefault
                    variant="contained"
                    size="large"
                    type="button"
                    qrScan
                    onClick={() => setSelectedTransaction(true)}
                  >
                    {lang?.lingo.app_pnts_add_points}
                  </ButtonDefault>
                </Box>
              )}
            </Stack>
          )}
          <ModalTransaction
            lang={lang}
            shopId={shopId}
            b2cId={b2cId}
            shopInfo={shopInfo}
            categories={categories}
            selectedTransaction={selectedTransaction}
            isCreateTransactions={isCreateTransactions}
            closeModal={closeModal}
            setShopInfo={setShopInfo}
            setIsCreateTransactions={setIsCreateTransactions}
          />
        </>
      </PageLayout>
    </>
  ) : (
    <></>
  );
};
