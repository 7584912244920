import React from 'react';
import { Box } from '@mui/material';

import { IOrLineProps } from './OrLine.types';

import styles from './OrLine.module.scss';

export const OrLine = ({ lang }: IOrLineProps): JSX.Element => {
  return (
    <Box component="div" className={styles.lineContainer}>
      <Box component="div" className={styles.lineBox}></Box>
      <Box component="p" className={styles.lineText}>
        {lang?.lingo.app_or}
      </Box>
      <Box component="div" className={styles.lineBox}></Box>
    </Box>
  );
};
