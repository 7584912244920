import React, { useContext, useEffect, useMemo, useState } from 'react';

import { ChangePasswordFormData, LoginFormData } from 'pages/login/login.types';
import { SignUpFormData } from 'pages/sign-up/sign-type.types';
import { SettingsFormData } from 'pages/settings/settings';
import * as AuthService from 'utils/auth-service';
import {
  IShopInfo,
  ICreateCampaign,
  ICreateSale,
  ICreateShop,
  ITransaction,
  IShop,
  IEditProfile,
  ICreateEmployee,
  IAnalytics,
  ICreateCategory
} from 'utils/auth-service.types';

import { AuthContext } from './auth-context';
import { AlertContext } from '../alert';
import { AlertType } from '../alert/alert-provider';
import { ILanguageProfile, IUserState } from './auth.types';
import { handleChangePassword } from 'utils/auth-service';

export const AuthProvider = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  // const [token, setToken] = useState<string | null>(
  //   localStorage.getItem('token')
  // );
  const [user, setUser] = useState<IUserState | null>(null);
  const [sales, setSales] = useState<IShop['sale_meta'] | null>(null);
  const [lang, setLang] = useState<ILanguageProfile | null>(null);

  const { setType, setMessage } = useContext(AlertContext);

  // useEffect(() => {
  //   if (token) {
  //     localStorage.setItem('token', token);
  //   } else {
  //     localStorage.removeItem('token');
  //   }
  // }, [token]);

  const authHeader = {
    Authorization: 'Bearer ' + localStorage.getItem('token')
  };

  const catchErrorHandler = (err: Error) => {
    setHasError(true);
    if (err instanceof Error) {
      setAlert(err.message);
    } else {
      setAlert('Unknown error');
    }
  };

  const setAlert = (message: string, type: AlertType = 'error') => {
    setMessage(message);
    setType(type);
  };

  const fetchTransactions = async (query: Record<string, string>) => {
    return new Promise<ITransaction>(async (resolve, reject) => {
      setIsLoading(true);
      try {
        const { data, pagination } = await AuthService.fetchTransactions(
          authHeader,
          query
        );
        resolve({
          transactions: data.transactions,
          pagination
        });
      } catch (err) {
        catchErrorHandler(err as Error);
        reject(err);
      } finally {
        setIsLoading(false);
      }
    });
  };

  const fetchAnalytics = async (query: Record<string, string>) => {
    return new Promise<IAnalytics>(async (resolve, reject) => {
      setIsLoading(true);
      try {
        const { data, pagination } = await AuthService.fetchAnalytics(
          authHeader,
          query
        );
        resolve(data);
      } catch (err) {
        catchErrorHandler(err as Error);
        reject(err);
      } finally {
        setIsLoading(false);
      }
    });
  };

  const getShops = async () => {
    setIsLoading(true);
    try {
      const response = await AuthService.getShops(authHeader);
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOneShop = async (shop_id: string) => {
    setIsLoading(true);
    try {
      const response = await AuthService.getOneShop(shop_id, authHeader);
      setHasError(false);
      setSales(response?.data?.sale_meta || null);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCategories = async () => {
    setIsLoading(true);
    try {
      const response = await AuthService.getCategories(authHeader);
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOneCategory = async (category_id: string) => {
    setIsLoading(true);
    try {
      const response = await AuthService.getOneCategory(
        category_id,
        authHeader
      );
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const createCategory = async (data: ICreateCategory) => {
    setIsLoading(true);
    try {
      const response = await AuthService.createCategory(data, authHeader);
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const editCategory = async (category_id: string, data: ICreateCategory) => {
    setIsLoading(true);
    try {
      const response = await AuthService.editCategory(
        category_id,
        data,
        authHeader
      );
      setHasError(false);
      setType('success');
      setMessage(`${lang?.lingo.app_ctgr_edited_ntfc}`);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteCategory = async (category_id: string) => {
    setIsLoading(true);
    try {
      const response = await AuthService.deleteCategory(
        category_id,
        authHeader
      );
      setHasError(false);
      setType('success');
      setMessage(`${lang?.lingo.app_ctgr_deleted_ntfc}`);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const getQRCode = async (shop_id: string) => {
    setIsLoading(true);
    try {
      const response = await AuthService.getQRCode(shop_id, authHeader);

      const blob = await response.blob();

      const image: HTMLImageElement | null = document.createElement(
        'img'
      ) as HTMLImageElement;

      image.src = URL.createObjectURL(blob);

      const qrImageDiv = document.getElementById('qrImage') as HTMLDivElement;
      qrImageDiv?.appendChild(image);

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `QRCode.png`);
      const qrDownload = document.getElementById(
        'download'
      ) as HTMLButtonElement;

      qrDownload?.appendChild(link);
      setHasError(false);
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const getEmployees = async () => {
    setIsLoading(true);
    try {
      const response = await AuthService.getEmployees(authHeader);
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const createEmployee = async (data: ICreateEmployee) => {
    setIsLoading(true);
    try {
      const response = await AuthService.createEmployee(data, authHeader);
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteEmployee = async (user_id: string) => {
    setIsLoading(true);
    try {
      const response = await AuthService.deleteEmployee(user_id, authHeader);
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const createShop = async (data: ICreateShop) => {
    setIsLoading(true);
    try {
      const response = await AuthService.createShop(data, authHeader);
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const editShop = async (shop_id: string, data: ICreateShop) => {
    setIsLoading(true);
    try {
      const response = await AuthService.editShop(shop_id, data, authHeader);
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const createSale = async (shop_id: string, data: ICreateSale) => {
    setIsLoading(true);
    try {
      const response = await AuthService.createSale(shop_id, data, authHeader);
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSale = async (shop_id: string, sale_code: string) => {
    setIsLoading(true);
    try {
      const response = await AuthService.deleteSale(
        shop_id,
        sale_code,
        authHeader
      );
      setHasError(false);
      setType('success');
      setMessage(`${lang?.lingo.app_prmo_deleted_ntfc}`);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const createTransactions = async (query: Record<string, string>) => {
    setIsLoading(true);
    try {
      const response = await AuthService.createTransactions(authHeader, query);
      setHasError(false);
      setType('success');
      setMessage(`${lang?.lingo.app_trns_approved_ntfc}`);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  // const acceptTransaction = async (query: Record<string, string>) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await AuthService.acceptTransaction(authHeader, query);
  //     setHasError(false);
  //     setType('success');
  //     setMessage(`${lang?.lingo.app_trns_approved_ntfc}`);
  //     return response.data;
  //   } catch (err) {
  //     catchErrorHandler(err as Error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const fetchPreTransaction = async (query: Record<string, string>) => {
    return new Promise<IShopInfo>(async (resolve, reject) => {
      setIsLoading(true);
      try {
        const response = await AuthService.fetchPreTransaction(
          authHeader,
          query
        );
        setHasError(false);
        resolve(response.data);
      } catch (err) {
        catchErrorHandler(err as Error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  const getUser = async () => {
    setIsLoading(true);
    try {
      const response = await AuthService.getUser(authHeader);
      if (response.data) {
        setUser(response.data);
      }
    } catch (err) {
      catchErrorHandler(err as Error);
      setUser(null);
      localStorage.removeItem('token');
    } finally {
      setIsLoading(false);
    }
  };

  const getLanguage = async () => {
    setIsLoading(true);
    try {
      const response = await AuthService.getLanguage(authHeader);
      setLang(response.data);
    } catch (err) {
      catchErrorHandler(err as Error);
      setLang(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async (data: LoginFormData) => {
    setIsLoading(true);
    try {
      const response = await AuthService.handleLogin(data);
      if (response.data.auth) {
        localStorage.setItem('token', response.data.auth.access_token);
      }
      if (response.data.user) {
        setUser(response.data.user);
      }
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangePassword = async (data: ChangePasswordFormData) => {
    setIsLoading(true);
    try {
      const response = await AuthService.handleChangePassword(data);
      if (response.data.auth) {
        localStorage.setItem('token', response.data.auth.access_token);
      }
      if (response.data.user) {
        setUser(response.data.user);
      }
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUp = async (data: SignUpFormData) => {
    setIsLoading(true);
    try {
      const response = await AuthService.handleSignUp(data);
      if (response.data.auth) {
        localStorage.setItem('token', response.data.auth.access_token);
      }
      if (response.data.user) {
        setUser(response.data.user);
      }
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const editProfile = async (data: IEditProfile) => {
    setIsLoading(true);
    try {
      const response = await AuthService.editProfile(data, authHeader);
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateSettings = async (data: SettingsFormData) => {
    setIsLoading(true);
    try {
      const response = await AuthService.updateSettings(data, authHeader);
      setAlert(response.detail, 'success');
      if (user) {
        setUser({
          ...user,
          reset_points: data.reset_points,
          company_name: data.company_name,
          discount_message: data.discount_message,
          name: data.name
        });
      }
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCampaigns = async () => {
    setIsLoading(true);
    try {
      const response = await AuthService.getCampaigns(authHeader);
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOneCampaign = async (campaign_id: string) => {
    setIsLoading(true);
    try {
      const response = await AuthService.getOneCampaign(
        campaign_id,
        authHeader
      );
      setHasError(false);

      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const createCampaign = async (data: ICreateCampaign) => {
    setIsLoading(true);
    try {
      const response = await AuthService.createCampaign(data, authHeader);
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const useAIAssistant = async (query: string) => {
    setIsLoading(true);
    try {
      const response = await AuthService.useAIAssistant(query, authHeader);
      setHasError(false);
      return response;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const editCampaign = async (campaign_id: string, data: ICreateCampaign) => {
    setIsLoading(true);
    try {
      const response = await AuthService.editCampaign(
        campaign_id,
        data,
        authHeader
      );
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteCampaign = async (campaign_id: string) => {
    setIsLoading(true);
    try {
      const response = await AuthService.deleteCampaign(
        campaign_id,
        authHeader
      );
      setHasError(false);
      return response.data;
    } catch (err) {
      catchErrorHandler(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const onLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  const value = useMemo(
    () => ({
      // token,
      user,
      lang,
      sales,
      // isAuthenticated: !!token,
      isLoading,
      hasError,
      onLogin: handleLogin,
      changePassword: handleChangePassword,
      fetchTransactions,
      fetchAnalytics,
      fetchPreTransaction,
      createTransactions,
      onLogout,
      onSignUp: handleSignUp,
      getUser,
      getLanguage,
      updateSettings,
      getShops,
      createShop,
      getOneShop,
      editShop,
      getCategories,
      getOneCategory,
      createCategory,
      editCategory,
      deleteCategory,
      getQRCode,
      getEmployees,
      createEmployee,
      deleteEmployee,
      createSale,
      deleteSale,
      getCampaigns,
      getOneCampaign,
      createCampaign,
      editCampaign,
      useAIAssistant,
      deleteCampaign,
      editProfile
    }),
    [
      // token,
      user,
      lang,
      sales,
      isLoading,
      hasError,
      handleLogin,
      handleChangePassword,
      fetchTransactions,
      fetchAnalytics,
      fetchPreTransaction,
      createTransactions,
      handleSignUp,
      onLogout,
      getUser,
      getLanguage,
      updateSettings,
      getShops,
      createShop,
      getOneShop,
      editShop,
      getCategories,
      getOneCategory,
      createCategory,
      editCategory,
      deleteCategory,
      getQRCode,
      getEmployees,
      createEmployee,
      deleteEmployee,
      createSale,
      deleteSale,
      getCampaigns,
      createCampaign,
      editCampaign,
      useAIAssistant,
      deleteCampaign,
      editProfile
    ]
  );

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};
